import React from 'react';
import { ToastContainer } from 'react-toastify';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import AppNavbar from './AppNavbar';
import AppFooter from './AppFooter';
import Routes from '../routes';
import authService from '../common/services/authService';
import { useBeforeFirstRender } from '../common/utils/customHooks';

export interface AppProps extends RouteComponentProps<any> {}

const App = (props: AppProps) => {
  useBeforeFirstRender(() => {
    authService.loginUserLocal();
    //dispatch(fetchUserInbox(1, 10));
  });

  return (
    <>
      <ToastContainer />
      <AppNavbar {...props} />
      <main>
        <div className="body-bg pt-5 mt-5">
          <Routes />
        </div>
      </main>
      <AppFooter />
    </>
  );
};

export default withRouter(App);
