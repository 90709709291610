import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { PostDto } from 'common/interfaces/PostDto';
import FormAlertMessage from 'common/components/FormAlertMessage';
import { CircularProgress } from '@material-ui/core';
import postService from 'common/services/postService';
import { RouteComponentProps } from 'react-router-dom';
import queryString from 'query-string';
import Swal from 'sweetalert2';

import ClassicEditor from 'ckeditor5-custom-build/build/ckeditor';
import CKEditor from '@ckeditor/ckeditor5-react';

import baseApiUrl from 'config';

export interface CreatePostProp extends RouteComponentProps<any> {}

const CreatePost: React.FunctionComponent<CreatePostProp> = props => {
  const { register, handleSubmit, errors, setValue, getValues } = useForm<
    PostDto
  >();
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [categories, setCategories] = useState<Array<any>>([]);
  const [post, setPost] = useState<PostDto>();
  const [body, setPostBody] = useState('');

  useEffect(() => {
    register({ name: 'body', type: 'custom' }, { required: true, min: 100000 });
    const { id: paramId } = queryString.parse(props.location.search);
    const id = Number(paramId);
    if (id) {
      (async () => {
        const res: any = await postService.getPostDetail(id);
        if (res && res.data) {
          console.log(res);
          const { data } = res;
          setPost(data);
          setValue('title', data.title);
          setValue('body', data.body);
          setPostBody(data.body || '');
          // setPostBody(data.body || '');
          setValue('postCategoryId', data?.postCategoryId);
        }
      })();
    }

    (async () => {
      const { status, data } = await postService.getCategories();
      if (status === 200 && data) {
        setCategories(data);
        setValue('postCategoryId', post?.postCategoryId);
      }
    })();
  }, []);

  const handleTextEditor = (bodyContent: any) => {
    setValue('body', bodyContent);
    setPostBody(bodyContent);
  };

  const onSubmit = async (values: PostDto) => {
    try {
      setIsLoading(true);
      values.id = post?.id;
      const { status, data } = await postService.createPost(values);

      if (status === 200 && data) {
        setIsLoading(false);
        Swal.fire(
          'Success',
          'post has been submitted for approval',
          'success',
        ).then(() => {
          props.history.push(`/community/posts/${data.id}`);
        });
      } else {
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);

      const { response } = err;

      if (response && response.data && response.data.title) {
        let message = response.data.title;
        setErrorMessage(message);
      }
    }
  };

  return (
    <div className="container mb-5 pb-5 mt-3">
      <div className="text-center mb-3">
        <h3 className="d-inline-block border-bottom">Create a Post</h3>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} method="POST" noValidate>
        <div className="form-row">
          <div className="form-group col-6 px-md-3 py-2">
            <label htmlFor="title">
              Title <span className="text-danger text-small">*</span>
            </label>
            <input
              type="text"
              name="title"
              ref={register({ required: true, max: 100 })}
              className={
                errors.title ? 'form-control is-invalid' : 'form-control'
              }
            />
            <div className="invalid-feedback">
              {errors.title && 'A post title is required'}
            </div>
          </div>

          <div className="form-group col-6 px-md-3 py-2">
            <label htmlFor="category">
              Category <span className="text-danger text-small">*</span>{' '}
            </label>
            <select
              name="postCategoryId"
              ref={register({ required: true })}
              className={
                errors.postCategoryId
                  ? 'form-control is-invalid'
                  : 'form-control'
              }
            >
              <option value="">kindly select</option>
              {categories.map((category, index) => (
                <option value={category.id} key={index}>
                  {category.name}
                </option>
              ))}
            </select>
            <div className="invalid-feedback">
              {errors.postCategoryId && 'Please select a category'}
            </div>
          </div>

          <div className="form-group col-12 px-md-3 py-2">
            <label htmlFor="body">
              Body <span className="text-danger text-small">*</span>
            </label>
            <CKEditor
              className={errors['body'] ? 'is-invalid' : ' '}
              editor={ClassicEditor}
              data={body}
              config={{
                simpleUpload: { uploadUrl: `${baseApiUrl}upload` },
                toolbar: {
                  items: [
                    'heading',
                    '|',
                    'bold',
                    'italic',
                    'link',
                    'bulletedList',
                    'numberedList',
                    '|',
                    'indent',
                    'outdent',
                    '|',
                    'alignment',
                    'underline',
                    'fontFamily',
                    'fontColor',
                    'fontSize',
                    'imageUpload',
                    'blockQuote',
                    'insertTable',
                    'mediaEmbed',
                    'undo',
                    'redo',
                  ],
                },
                language: 'en',
                image: {
                  toolbar: [
                    'imageTextAlternative',
                    'imageStyle:full',
                    'imageStyle:side',
                  ],
                },
                table: {
                  contentToolbar: [
                    'tableColumn',
                    'tableRow',
                    'mergeTableCells',
                  ],
                },
                licenseKey: '',
              }}
              onBlur={(event: any, editor: any) => {
                // console.log('Blur.', editor.getData());
                // setPostBody(editor.getData());
                // setValue('body', 'body');
                handleTextEditor(editor.getData());
              }}
            />

            <div className="invalid-feedback">
              {errors.body && 'Body is required'}
            </div>
          </div>

          {errorMessage && (
            <div className="form-group col-12">
              <FormAlertMessage message={errorMessage} />
            </div>
          )}

          <div className="form-group col-12 px-md-3 py-2">
            <div className="text-right">
              <button type="submit" className="btn btn-lg btn-primary">
                {isLoading ? (
                  <span className="px-3 m-w-90 d-block">
                    <CircularProgress color="inherit" size={'1rem'} />
                  </span>
                ) : (
                  <span>Submit</span>
                )}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CreatePost;
