import React from 'react';
import notificationIcon from '../../assets/images/bell-regular.svg';
import Group1 from '../../assets/images/Group5.png';
import { useSelector, useDispatch } from 'react-redux';
import Notification from 'common/components/Notification';
import commonService from 'common/services/commonService';
import { fetchUserNotifications } from 'state/actions/user';
import { ListingType } from 'common/utils/listingTypes';

const NotificationPage = (props: any) => {
  const notifications = useSelector(
    (state: any) => state.user.userNotifications,
  );

  let unreadCount = 0;
  let notificationViews: any[] = [];

  if (notifications && notifications.length > 0) {
    unreadCount = notifications.filter((x: any) => !x.isRead).length;

    notifications.forEach((notification: any) => {
      const data = JSON.parse(notification.data);
      const link =
        data.profileType === ListingType.Business
          ? `/businesses/${data.profileId}`
          : data.profileType === ListingType.Investor
          ? `/investors/${data.profileId}`
          : data.profileType === ListingType.Advisor
          ? `/advisors/${data.profileId}`
          : data.profileType === ListingType.Startup
          ? `/startups/${data.profileId}`
          : `/franchises/${data.profileId}`;

      let notificationView = {
        link,
        date: new Date(notification.createdAt).toDateString(),
        id: notification.id,
        listingId: data.profileId,
        listingType: data.profileType,
        isRead: notification.isRead,
        display: <></>,
      };

      if (notification.eventType === 'ProfileContacted') {
        notificationView.display = (
          <span>
            You can view a new {data.profileType} listing:
            <span className="text-primary">{data.profileTitle}</span>. You have
            <strong> {data.contactsRemaining} </strong> contacts unit left
          </span>
        );
      } else if (notification.eventType === 'NewProfileContactView') {
        notificationView.display = (
          <span>
            You have a new view on your {data.profileType} listing:{' '}
            <span className="text-primary">{data.profileTitle}</span>
          </span>
        );
      } else if (notification.eventType === 'ProposalSent') {
        notificationView.display = (
          <span>
            You sucessfully sent proposal to{' '}
            {data.profileType === 'Investor' || data.profileType === 'Advisor'
              ? 'an '
              : 'a '}{' '}
            {data.profileType} listing:{' '}
            <span className="text-primary">{data.profileTitle}</span>. You have{' '}
            <strong> {data.contactsRemaining} </strong> units left
          </span>
        );
      } else if (notification.eventType === 'StartupMessageSent') {
        notificationView.display = (
          <span>
            You sucessfully sent a message to a {data.profileType} listing:{' '}
            <span className="text-primary">{data.profileTitle}</span>. You have{' '}
            <strong> {data.contactsRemaining} </strong> units left
          </span>
        );
      }

      notificationViews.push(notificationView);
    });

    const notificationsClone = [...notificationViews];
    notificationViews.length = 0;
    notificationViews = notificationsClone.slice(0, 6);
  }

  const dispatch = useDispatch();

  const viewNotification = (link: any, id: number) => {
    commonService.markNotificationRead(id).then((res: any) => {
      dispatch(fetchUserNotifications());
      props.history.push(link);
    });
  };

  const markAllRead = () => {
    commonService.markAllNotificationsRead().then(res => {
      dispatch(fetchUserNotifications());
    });
  };

  const isReadClass = (classes: any, status: any) => {
    return status ? classes + ' bg-white' : classes + ' bg-unread';
  };
  return (
    <div>
      <div>
        <h2
          className="display-4 mt-5 ml-5"
          style={{
            fontFamily: 'Metropolis-SemiBold',
            fontSize: 'xx-large',
          }}
        >
          Notifications
        </h2>
        <hr className="mb-5 ml-5 w-25 " style={{ background: '#C8C8C8' }} />
      </div>
      <div className="container-fluid shadow-lg rounded-lg bg-white w-80 mb-3">
        <p
          className="p-3"
          style={{
            fontFamily: 'Metropolis-SemiBold',
            color: '#F60',
          }}
        >
          All Notifications
        </p>

        <div className="row mt-3">
          <div className="">
            {notificationViews.length > 0 &&
              notificationViews.map((view: any) => (
                <div
                  onClick={() => viewNotification(view.link, view.id)}
                  className={isReadClass(
                    'row mx-2 mb-2  p-2 cursor-pointer notification ',
                    view.isRead,
                  )}
                  key={view.id}
                >
                  <div className="col-2 col-md-1 text-break">
                    <img
                      className="ml-3 w-100"
                      src={notificationIcon}
                      alt="Notification"
                    />
                  </div>
                  <div className="col-sm-11 text-break">
                    <div>{view.display}</div>
                    <div className="text-left">
                      <em
                        className="text-sm-1 text-muted"
                        style={{ fontSize: '.6rem' }}
                      >
                        {view.date}
                      </em>
                    </div>
                  </div>
                </div>
              ))}
            {notificationViews.length === 0 && (
              <div className="mx-2 mb-2  p-2 text-danger">No notifications</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationPage;
