import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import eye from '../../assets/images/eye.png';
import approve from '../../assets/images/approved.png';

const UserDashboard = () => {
  const currentUser = useSelector((state: any) => state.user.currentUser);
  const userStartupId = useSelector((state: any) => state.user.userStartupId);

  return (
    <section className="row container-lg mx-auto">
      <div>
        <div
          style={{
            backgroundColor: 'white',
            borderRadius: '10px',
          }}
          className="wrapper mt-5 pt-5 shadow ml-4 p-5 mr-3"
        >
          <p
            style={{
              color: '#000',
              fontSize: '30px',
              letterSpacing: '0.06px',
              lineHeight: '30px',
            }}
          >
            Hi{' '}
            {currentUser && currentUser.displayName
              ? currentUser.displayName
              : ''}
          </p>
          <p
            style={{
              color: '#000',
              fontFamily: 'metropolis-light',
              fontSize: '20px',
              letterSpacing: '0.04px',
              lineHeight: '24px',
            }}
            className="mb-5"
          >
            Welcome to Startup Zone by Compex Africa. Register your startup{' '}
            <br></br> and have access to investors and more.
          </p>
          <div>
            <Link
              className="btn btn-primary border-thick-primary font-weight-bold px-3 metroBold"
              to={userStartupId ? `/user/startup` : '/pricing'}
            >
              {userStartupId ? 'View your startup' : 'Register your startup'}
            </Link>
          </div>
        </div>
        <div className="row d-none">
          <div
            style={{
              backgroundColor: 'white',
              height: '130px',
              borderRadius: '10px',
            }}
            className="wrapper mt-5 shadow ml-5 mb-5"
          >
            <div className="d-flex p-4">
              <img
                style={{ height: '80px', width: '80px' }}
                className="mr-3"
                src={eye}
                alt="views"
              />
              <div className="mr-3 ml-3">
                <p
                  style={{
                    color: '#000',
                    fontFamily: 'Metropolis-ExtraBold',
                    fontSize: '16px',
                    letterSpacing: '0.03px',
                    lineHeight: '16px',
                  }}
                  className="mt-n2 font-weight-bold"
                >
                  Views
                </p>
                <p
                  style={{
                    color: '#024686',
                    fontFamily: 'Metropolis-ExtraBold',
                    fontSize: '36px',
                    letterSpacing: '0.07px',
                    lineHeight: '36px',
                    fontWeight: 'bold',
                  }}
                >
                  {' '}
                  781
                </p>
                <p className="mt-n3">Total</p>
              </div>
              <div className="ml-3">
                {''}
                <p
                  style={{
                    color: '#024686',
                    fontFamily: 'Metropolis Extra Bold',
                    fontSize: '36px',
                    letterSpacing: '0.07px',
                    lineHeight: '36px',
                    fontWeight: 'bold',
                  }}
                  className="mt-4 ml-4"
                >
                  {' '}
                  81
                </p>
                <p className="mt-n3">last 7 days</p>
              </div>
            </div>
          </div>
          <div
            style={{
              backgroundColor: 'white',
              height: '130px',
              borderRadius: '10px',
            }}
            className="wrapper mt-5 shadow ml-5"
          >
            <p
              style={{
                color: '#000',
                fontFamily: 'Metropolis Extra Bold',
                fontSize: '16px',
                letterSpacing: '0.03px',
                lineHeight: '16px',
                marginLeft: '140px',
              }}
              className="mt-3 font-weight-bold"
            >
              Interested Investors
            </p>
            <div className="d-flex p-4">
              <img
                style={{ height: '70px', width: '80px' }}
                className="mr-3 mt-n5"
                src={approve}
                alt="views"
              />
              <div className="mr-3 ml-3 mt-n4">
                <p
                  style={{
                    color: '#024686',
                    fontFamily: 'Metropolis Extra Bold',
                    fontSize: '36px',
                    letterSpacing: '0.07px',
                    lineHeight: '36px',
                    fontWeight: 'bold',
                  }}
                >
                  {' '}
                  26
                </p>
                <p className="mt-n3">Total</p>
              </div>
              <div className="ml-3">
                {''}
                <p
                  style={{
                    color: '#024686',
                    fontFamily: 'Metropolis Extra Bold',
                    fontSize: '36px',
                    letterSpacing: '0.07px',
                    lineHeight: '36px',
                    fontWeight: 'bold',
                  }}
                  className="mt-n4"
                >
                  {' '}
                  0
                </p>
                <p className="mt-n3">last 7 days</p>
              </div>
            </div>
          </div>
        </div>
        <div className="">
          <div
            style={{
              backgroundColor: 'white',
              borderRadius: '10px',
            }}
            className="wrapper mt-5 mb-5 pt-5 shadow ml-4 p-5 row mr-2"
          >
            <p
              style={{
                color: '#000',
                fontFamily: 'metropolis-light',
                fontSize: '20px',
                letterSpacing: '0.04px',
                lineHeight: '24px',
              }}
              className="mr-5"
            >
              Get more introductions, professionally crafted <br></br> business
              plan, event discounts and more when you <br /> upgrade
            </p>
            <div>
              <Link
                className="btn btn-primary border-thick-primary font-weight-bold px-5 ml-5 metroBold"
                to="/register-startup"
              >
                Upgrade now
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default UserDashboard;
