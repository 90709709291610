import React from 'react';
import { Link, NavLink, useHistory, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import authService from 'common/services/authService';
import { motion } from 'framer-motion';
import { compexDomain } from 'config';

const AppNavBarMobile = () => {
  const isLoggedIn = useSelector((state: any) => state.user.isLoggedIn);
  const currentUser = useSelector((state: any) => state.user.currentUser);
  const history = useHistory();
  const signOut = () => {
    authService.logOutUser();
    history.replace('/');
  };

  const isSignin = useRouteMatch('/signin');
  const isSignup = useRouteMatch('/signup');
  const isForgotPassword = useRouteMatch('/forgot-password');

  const match =
    isSignin?.isExact || isSignup?.isExact || isForgotPassword?.isExact;
  return (
    <header
      className={`container-fluid d-md-none ${
        match ? 'bg-light-grey' : 'bg-white'
      }`}
    >
      <nav className="navbar fixed-top navbar-expand-lg navbar-light bg-white pt-4 mh-100 overflow-auto">
        <Link
          className="navbar-brand font-weight-bold text-black d-flex align-items-center position-relative mb-3"
          to="/"
        >
          <span className="bg-logo logo-small mr-3 position-absolute"></span>
          <span className="d-none d-lg-block d-xl-block pl-5">
            Startup Zone
          </span>
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarMobileMenu"
          aria-controls="navbarMobileMenu"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse p-4" id="navbarMobileMenu">
          <ul className="navbar-nav ">
            {isLoggedIn ? (
              <div className="w-90 d-flex justify-content-between align-items-center mt-3 mb-4">
                {currentUser.photoUrl ? (
                  <img
                    src={currentUser.photoUrl}
                    alt="..."
                    style={{
                      minHeight: '3.2rem',
                      minWidth: '3.2rem',
                      maxHeight: '3.2rem',
                      maxWidth: '3.2rem',
                    }}
                    className="img-fluid rounded-circle p-1"
                  ></img>
                ) : (
                  <div
                    className="avaterImageDiv bg-primary"
                    style={{
                      height: '3.2rem',
                      width: '3.2rem',
                    }}
                  >
                    <h1 className="text-center text-white align-middle">
                      {currentUser.firstname.substr(0, 1).toUpperCase()}
                    </h1>
                  </div>
                )}
                <div className="pl-3 d-flex flex-column">
                  <p className="m- text-primary font-weight-bold m-0">
                    {currentUser.displayName}
                  </p>
                  <p className="m-0">{currentUser.email}</p>
                  <div data-toggle="collapse" data-target="#navbarMobileMenu">
                    <Link
                      to="/user/dashboard"
                      className="text-primary font-weight-bold"
                    >
                      My Dashboard
                    </Link>
                  </div>
                </div>
              </div>
            ) : (
              <div className="">
                <li
                  className="nav-item my-lg-auto mx-2 mb-2"
                  data-toggle="collapse"
                  data-target="#navbarMobileMenu"
                >
                  <Link
                    className="btn nav-link btn-thick-outline-primary font-weight-bold px-5"
                    to="/signin"
                  >
                    Sign in
                  </Link>
                </li>
                <li
                  className="nav-item my-lg-auto mx-2 mb-2"
                  data-toggle="collapse"
                  data-target="#navbarMobileMenu"
                >
                  <Link
                    className="nav-link btn btn-primary border-thick-primary font-weight-bold px-5"
                    to="/signup"
                  >
                    Sign up
                  </Link>
                </li>
              </div>
            )}

            {isLoggedIn && (
              <>
                <div className="dropdown-divider w-100 border-primary my-3"></div>
                <div className="d-flex justify-content-between flex-wrap">
                  <li
                    className="nav-item p-0 d-lg-none d-xl-none w-50"
                    data-toggle="collapse"
                    data-target="#navbarMobileMenu"
                  >
                    <NavLink
                      to="/user/dashboard"
                      className="nav-link text-primary"
                    >
                      DashBoard
                    </NavLink>
                  </li>
                  <li
                    className="nav-item p-0 d-lg-none d-xl-none w-50"
                    data-toggle="collapse"
                    data-target="#navbarMobileMenu"
                  >
                    <NavLink
                      to="/user/startup"
                      className="nav-link text-primary"
                    >
                      Your Startup
                    </NavLink>
                  </li>
                  <li
                    className="nav-item p-0 d-lg-none d-xl-none w-50"
                    data-toggle="collapse"
                    data-target="#navbarMobileMenu"
                  >
                    <NavLink
                      to="/user/investors"
                      className="nav-link text-primary"
                    >
                      Interested Investors
                    </NavLink>
                  </li>
                  <li
                    className="nav-item p-0 d-lg-none d-xl-none w-50"
                    data-toggle="collapse"
                    data-target="#navbarMobileMenu"
                  >
                    <NavLink
                      to="/user/subscriptions"
                      className="nav-link text-primary"
                    >
                      Invoice
                    </NavLink>
                  </li>
                  <li
                    className="nav-item p-0 d-lg-none d-xl-none w-50"
                    data-toggle="collapse"
                    data-target="#navbarMobileMenu"
                  >
                    <NavLink
                      to="/user/messages/inbox"
                      className="nav-link text-primary"
                    >
                      Inbox
                    </NavLink>
                  </li>
                  <li
                    className="nav-item p-0 d-lg-none d-xl-none w-50"
                    data-toggle="collapse"
                    data-target="#navbarMobileMenu"
                  >
                    <NavLink
                      to="/user/notification"
                      className="nav-link text-primary"
                    >
                      Notifications
                    </NavLink>
                  </li>
                  <li
                    className="nav-item p-0 d-lg-none d-xl-none w-50"
                    data-toggle="collapse"
                    data-target="#navbarMobileMenu"
                  >
                    <NavLink
                      to="/user/community"
                      className="nav-link text-primary"
                    >
                      community
                    </NavLink>
                  </li>
                  <li
                    className="nav-item p-0 d-lg-none d-xl-none w-50"
                    data-toggle="collapse"
                    data-target="#navbarMobileMenu"
                  >
                    <NavLink
                      to="/user/settings"
                      className="nav-link text-primary"
                    >
                      Settings
                    </NavLink>
                  </li>
                </div>
              </>
            )}

            <div className="dropdown-divider w-100 border-primary my-3"></div>
            <div className="d-flex justify-content-between flex-wrap">
              <li
                className="nav-item p-0 d-lg-none d-xl-none w-50"
                data-toggle="collapse"
                data-target="#navbarMobileMenu"
              >
                <a
                  className="nav-link text-primary font-weight-bold"
                  href={compexDomain}
                >
                  CompexAfrica
                </a>
              </li>
              <li
                className="nav-item p-0 d-lg-none d-xl-none w-50"
                data-toggle="collapse"
                data-target="#navbarMobileMenu"
              >
                <NavLink
                  to="/startups"
                  className="nav-link text-primary font-weight-bold"
                >
                  View Startups
                </NavLink>
              </li>
              <li
                className="nav-item p-0 d-lg-none d-xl-none w-50"
                data-toggle="collapse"
                data-target="#navbarMobileMenu"
              >
                <NavLink
                  to="/investors"
                  className="nav-link text-primary font-weight-bold"
                >
                  Investors
                </NavLink>
              </li>
              <li
                className="nav-item p-0 d-lg-none d-xl-none w-50"
                data-toggle="collapse"
                data-target="#navbarMobileMenu"
              >
                <NavLink
                  to="/community"
                  className="nav-link text-primary font-weight-bold"
                >
                  Community
                </NavLink>
              </li>
              <li
                className="nav-item p-0 d-lg-none d-xl-none w-50"
                data-toggle="collapse"
                data-target="#navbarMobileMenu"
              >
                <NavLink
                  to="/pricing"
                  className="nav-link text-primary font-weight-bold"
                >
                  Pricing
                </NavLink>
              </li>
            </div>
            {isLoggedIn && (
              <div>
                <div className="dropdown-divider w-100 border-primary my-3"></div>
                <li
                  className="nav-item p-0 d-lg-none d-xl-none w-50"
                  data-toggle="collapse"
                  data-target="#navbarMobileMenu"
                >
                  <button
                    className="button-as-link nav-link text-primary"
                    onClick={signOut}
                  >
                    Sign out
                  </button>
                </li>
              </div>
            )}
          </ul>
        </div>
      </nav>
    </header>
  );
};

export default AppNavBarMobile;
