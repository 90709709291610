import React, { useEffect, useState } from 'react';
import StartupPlans from 'pages/startup/StartupPlans';
import { useDispatch, useSelector } from 'react-redux';
import { fetchStartupDetails } from 'state/actions/startup';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import PaymentModal from 'common/components/PaymentModal';
import subscriptionService from 'common/services/subscriptionService';
import { spacePascalWord } from 'common/utils/helpers';
import useScript from 'common/utils/useScript';

const UpgradeStartup = (props: any) => {
  useScript('https://api.ravepay.co/flwv3-pug/getpaidx/api/flwpbf-inline.js');
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);
  const startupDetails = useSelector(
    (state: any) => state.startup.startupDetails,
  );
  const user = useSelector((state: any) => state.user.currentUser);
  const dispatch = useDispatch();

  useEffect(() => {
    const { id } = props?.match?.params;
    const { business } = props;
    if (!business || !business?.id) {
      dispatch(fetchStartupDetails(id));
    }

    (async () => {
      const res = await subscriptionService.getSubscriptionPlans();
      if (res) {
        setSubscriptionPlans(res);
      }
    })();
  }, []);

  const planChange = (plan: string) => {
    const { id, isOwner } = startupDetails;
    if (!isOwner) props.history.push(`/businesses/${id}`);

    promptPayment(plan);
  };

  const MySwal: any = withReactContent(Swal);

  const promptPayment = (selectedPlan: string) => {
    const plans: Array<any> = [...subscriptionPlans];
    const plan = plans.find(x => x.planType === selectedPlan);

    const { email, phone } = user;
    const { id: startupId } = startupDetails;
    const successMessage = `Subscription to ${spacePascalWord(
      plan,
      true,
    )} plan was successful`;

    if (email && plan && startupId) {
      MySwal.fire({
        customClass: 'bg-transparent w-lg-30 w-md-30 w-90',
        showConfirmButton: false,
        html: (
          <PaymentModal
            planId={plan.planId}
            amount={plan.amount}
            userEmail={email}
            userPhone={phone}
            listingId={startupId}
            listingType="Startup"
            redirectTo={`startups/${startupId}`}
            successMessage={successMessage}
          ></PaymentModal>
        ),
      });
    }
  };

  const currentPlan = startupDetails?.subscription?.planType;
  return (
    <div className="mt-md-5 p-3">
      <h3 className="text-center">Upgrade/change Business plan</h3>
      <StartupPlans
        currentPlan={currentPlan}
        planChange={planChange}
        isUpgrading={true}
        {...props}
      />
    </div>
  );
};

export default UpgradeStartup;
