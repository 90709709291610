import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import userService from 'common/services/userService';
import { compexDomain } from 'config';
import { ListingType } from 'common/utils/listingTypes';
import queryString from 'query-string';

const MessageContainer = ({
  userMessages,
  hideShowBody,
  showList,
  showBody,
  location,
}: any) => {
  let loadingInbox = useSelector((state: any) => state.user.loadingInbox);
  let loadingSent = useSelector((state: any) => state.user.loadingSent);
  let isLoading = loadingInbox || loadingSent;

  const [activeFilter, setActiveFilter] = useState(-1);
  const [messageBody, setMessageBody] = useState('');
  const [messageTitle, setMessageTitle] = useState('');
  const [messageSender, setMessageSender] = useState('');
  const [messageId, setMessageId] = useState(0);
  const [senderType, setSenderType] = useState('');
  const [senderId, setSenderId] = useState(0);
  const history = useHistory();
  const [listingType, setListingType] = useState('');

  const loadMessageDetail = useCallback(
    (mesgId: number) => {
      let readMessage =
        userMessages &&
        userMessages.filter((message: any) => message.id === mesgId);
      if (readMessage) setDetails(readMessage[0]);
    },
    [userMessages],
  );

  const setDetails = (message: any) => {
    let senderDes = '';
    let senderType = '';
    let senderId = 0;
    let senderAdvisor = message['proposals'][0].advisor;
    let senderInvestor = message['proposals'][0].investor;
    let senderBusiness = message['proposals'][0].business;
    let senderFranchise = message['proposals'][0].franchise;
    let senderStartup = message['proposals'][0].startup;
    if (senderAdvisor) {
      senderDes = senderAdvisor.whoYouAre;
      senderType = ListingType.AdvisorDomain;
      senderId = senderAdvisor.id;
      setListingType(ListingType.Advisor);
    }
    if (senderInvestor) {
      senderDes = senderInvestor.whoYouAre;
      senderType = ListingType.InvestorDomain;
      senderId = senderInvestor.id;
      setListingType(ListingType.Investor);
    }
    if (senderBusiness) {
      senderDes = senderBusiness.businessName;
      senderType = ListingType.BusinessDomain;
      senderId = senderBusiness.id;
      setListingType(ListingType.Business);
    }
    if (senderStartup) {
      senderDes = senderStartup.startupName;
      senderType = ListingType.StartupDomain;
      senderId = senderStartup.id;
      setListingType(ListingType.Startup);
    }
    if (senderFranchise) {
      senderDes = senderFranchise.brandName;
      senderType = ListingType.FranchiseDomain;
      senderId = senderFranchise.id;
      setListingType(ListingType.Franchise);
    }

    setMessageBody(message.proposals[0].body);
    setMessageTitle(message.subject);
    setMessageSender(senderDes);
    setMessageId(message.id);
    setSenderType(senderType);
    setSenderId(senderId);
  };

  const messageDetail = (
    body: any,
    title: any,
    sender: any,
    messageId: number,
    senderType: any,
    senderId: number,
    listingType: any,
    e: any,
  ) => {
    hideShowBody();
    setActiveFilter(parseInt(e.currentTarget.getAttribute('data-key')));
    setMessageBody(body);
    setMessageTitle(title);
    setMessageSender(sender);
    setMessageId(messageId);
    setSenderType(senderType);
    setSenderId(senderId);
    setListingType(listingType);
  };

  const viewListing = (e: any) => {
    e.preventDefault();
    userService.viewMessageListing(messageId);
    if (senderType === ListingType.StartupDomain) {
      history.push(`/${senderType}/${senderId}`);
    } else window.location.href = `${compexDomain}/${senderType}/${senderId}`;
  };

  useEffect(() => {
    const urlQuery = queryString.parse(location.search);
    let mesgId = urlQuery.mesgId?.toString();
    if (mesgId && userMessages.length) loadMessageDetail(parseInt(mesgId));
  }, [loadMessageDetail, location, userMessages]);

  let messageList =
    userMessages &&
    userMessages.map((message: any, index: any) => {
      let senderDes = '';
      let senderType = '';
      let senderId = 0;
      let listingType = '';
      let senderAdvisor = message['proposals'][0].advisor;
      let senderInvestor = message['proposals'][0].investor;
      let senderBusiness = message['proposals'][0].business;
      let senderFranchise = message['proposals'][0].franchise;
      let senderStartup = message['proposals'][0].startup;
      if (senderAdvisor) {
        senderDes = senderAdvisor.whoYouAre;
        senderType = ListingType.AdvisorDomain;
        senderId = senderAdvisor.id;
        listingType = ListingType.Advisor;
      }
      if (senderInvestor) {
        senderDes = senderInvestor.whoYouAre;
        senderType = ListingType.InvestorDomain;
        senderId = senderInvestor.id;
        listingType = ListingType.Investor;
      }
      if (senderBusiness) {
        senderDes = senderBusiness.businessName;
        senderType = ListingType.BusinessDomain;
        senderId = senderBusiness.id;
        listingType = ListingType.Business;
      }
      if (senderStartup) {
        senderDes = senderStartup.startupName;
        senderType = ListingType.StartupDomain;
        senderId = senderStartup.id;
        listingType = ListingType.Startup;
      }
      if (senderFranchise) {
        senderDes = senderFranchise.brandName;
        senderType = ListingType.FranchiseDomain;
        senderId = senderFranchise.id;
        listingType = ListingType.Franchise;
      }

      return (
        <div
          className={`messageItem border-bottom border-dark pt-1 d-flex justify-content-between cursor-pointer ${
            activeFilter === index ? 'active' : ''
          }`}
          data-key={index}
          key={index}
          onClick={(e: any) => {
            messageDetail(
              message.proposals[0].body,
              message.subject,
              senderDes,
              message.id,
              senderType,
              senderId,
              listingType,
              e,
            );
          }}
        >
          <div className="m-1">
            <p className="font-weight-bold text-sm-1">{senderDes}</p>
            <p className="mt-n3 text-sm-1">{message.subject}</p>
            <p className="mt-n3 text-sm-2 text-faded-black">
              {message.proposals[0].body}
            </p>
          </div>
          <div>
            <p className="m-1 text-sm-2 text-faded-black">
              {new Date(message.created).toDateString().split(' ')[2]}{' '}
              {new Date(message.created).toDateString().split(' ')[1]}
            </p>
          </div>
        </div>
      );
    });

  return (
    <div
      className="d-flex col"
      style={{
        minHeight: '580px',
      }}
    >
      <div
        className={`${showList} msg messagebox col-12 col-md-4 mt-5 shadow border-right border-dark rounded-left bg-white`}
        style={{
          minHeight: '350px',
          overflowY: 'auto',
        }}
      >
        <div className="border-bottom border-dark pt-5">
          <span className="fa fa-search form-control-feedback mr-2 ml-3 "></span>
          <input type="text" placeholder="Search" className="border-0 mb-2" />
        </div>
        {isLoading ? (
          <div className="ml-3">
            <CircularProgress />
          </div>
        ) : userMessages && userMessages.length ? (
          messageList
        ) : (
          <p className="p-4">You have no messages at the moment</p>
        )}
      </div>
      <div
        className={`${showBody} d-none d-md-inline-block col-md-8 col-12 shadow mt-5 rounded-right bg-white`}
      >
        {messageBody && (
          <div className="">
            <div className="border-bottom border-dark pt-4">
              <p className="ml-5 font-weight-bold text-sm-1">{messageTitle}</p>
              <p className="mt-n3 ml-5 text-sm-2">From: {messageSender}</p>
            </div>
            <div className="pt-5 messagebox">
              <p className="mt-n3 ml-5 mr-5 text-sm-2">{messageBody}</p>
            </div>{' '}
            <div className="border-top border-dark pt-5 pb-5 pl-5">
              <button
                className="btn btn-primary border-thick-primary px-3 pl-5 pr-5 rounded-30-px"
                onClick={e => {
                  viewListing(e);
                }}
              >
                {listingType === ListingType.Startup
                  ? 'View Startup Attached'
                  : `Send Proposal to ${listingType}`}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MessageContainer;
