import { useState, useEffect } from 'react';

export const useBeforeFirstRender = (func: () => void) => {
  const [hasRendered, setHasRendered] = useState(false);

  useEffect(() => {
    setHasRendered(true);
  }, [hasRendered]);

  if (!hasRendered) {
    func();
  }
};
