import baseApiUrl from '../../config';
import http from './httpService';
import { RegisterStartupDto } from 'common/interfaces/RegisterStartupDto';

const getStartups = (pageNumber = 1) => {
  return http.get(`${baseApiUrl}startups?pageNumber=${pageNumber}`);
};

const getStartupDetails = (id: number, edit = false) => {
  const queryString = edit ? '?edit=true' : '';
  return http.get(`${baseApiUrl}startups/${id}${queryString}`);
};

const registerStartup = async (dto: RegisterStartupDto) => {
  const { data } = await http.post(`${baseApiUrl}startups/register`, dto);

  return data;
};

const getUserStartups = async (userId: number, pageNumber = 1) => {
  return http.get(
    `${baseApiUrl}startups?pageNumber=${pageNumber}&userId=${userId}`,
  );
};

const deleteStartup = (id: number) => {
  return http.delete(`${baseApiUrl}startups/${id}`);
};

export default {
  getStartups,
  registerStartup,
  getStartupDetails,
  getUserStartups,
  deleteStartup,
};
