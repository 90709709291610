import React, { Component } from 'react';
import message from '../../assets/images/mail.svg';
import { connect } from 'react-redux';
import userService from '../services/userService';
import { fetchUserInbox, fetchUserSent } from '../../state/actions/user';
import { Link } from 'react-router-dom';

type Props = {
  userInbox: any;
  currentUser: any;
  fetchUserInbox: any;
  fetchUserSent: any;
  history: any;
};
type State = {
  inbox: Array<any>;
};

class MessageNotification extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      inbox: [],
    };
  }
  componentDidMount() {
    this.props.fetchUserInbox(1, 10);
    this.props.fetchUserSent(1, 10);
    let inbox = this.props.userInbox;
    this.setState({
      inbox: inbox,
    });
  }
  viewNotification = (link: any, id: number) => {
    userService.readMessage(id).then(() => {
      this.props.fetchUserInbox(1, 10);
      this.props.history.push(link);
    });
  };

  markAllRead = () => {
    userService.markAllMessagesRead().then(() => {
      this.props.fetchUserInbox();
    });
  };

  isReadClass = (classes: any, status: any) => {
    return status ? classes + ' bg-white' : classes + ' bg-unread';
  };

  render() {
    let userInbox = this.props.userInbox;
    let unreadCount = 0;
    let notificationViews: any[] = [];

    if (userInbox && userInbox.length > 0) {
      unreadCount = userInbox.filter((message: any) => !message.isRead).length;
      userInbox = userInbox.filter((message: any) => !message.isRead);

      userInbox.forEach((message: any) => {
        const link = `/user/messages/inbox?mesgId=${message.id}`;
        let senderDes = '';
        let senderAdvisor = message && message['proposals'][0].advisor;
        let senderInvestor = message && message['proposals'][0].investor;
        let senderBusiness = message && message['proposals'][0].business;
        let senderFranchise = message && message['proposals'][0].franchise;
        if (senderAdvisor) {
          senderDes = senderAdvisor.whoYouAre;
        }
        if (senderInvestor) {
          senderDes = senderInvestor.whoYouAre;
        }
        if (senderBusiness) {
          senderDes = senderBusiness.businessName;
        }
        if (senderFranchise) {
          senderDes = senderFranchise.brandName;
        }
        let notificationView = {
          link,
          date: new Date(message.created).toDateString(),
          id: message.id,
          listingId: message.id,
          listingType: 'inbox',
          isRead: message.isRead,
          display: <></>,
        };

        notificationView.display = (
          <span>
            Message From: {senderDes}
            {' - '}
            <strong> {message.subject} </strong>
          </span>
        );

        notificationViews.push(notificationView);
      });

      const userInboxClone = [...notificationViews];
      notificationViews.length = 0;
      notificationViews = userInboxClone.slice(0, 6);
    }

    return (
      <React.Fragment>
        <div
          className="cursor-pointer nav-link"
          id="navbarDropdown"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          role="button"
        >
          <div
            className="position-relative max-w-rem-2a"
            style={{ maxWidth: '2.2rem' }}
          >
            <img
              src={message}
              alt="..."
              className="img-fluid p-1"
              width="30"
            ></img>
            {unreadCount > 0 && (
              <div
                className="counter-wrapper position-absolute"
                style={{ top: '-2px', right: '2px' }}
              >
                <span
                  className="badge badge-danger counter"
                  style={{ fontSize: '.7rem' }}
                >
                  {unreadCount}
                </span>
              </div>
            )}
          </div>
        </div>
        <div
          className="dropdown-menu dropdown-menu-right bg-light notifications"
          style={{ minWidth: '18rem', maxHeight: '30rem', overflowY: 'auto' }}
          aria-labelledby="navbarDropdown"
        >
          <div className="d-flex justify-content-between bg-white p-2 border-bottom mb-2">
            <div>Message Inbox</div>
            <div onClick={this.markAllRead} className="btn btn-link p-0 m-0">
              Mark all as read
            </div>
          </div>
          {notificationViews.map(view => (
            <div
              onClick={() => this.viewNotification(view.link, view.id)}
              className={this.isReadClass(
                'mx-2 mb-2 border rounded p-2 cursor-pointer notification ',
                view.isRead,
              )}
              key={view.id}
            >
              <div>{view.display}</div>
              <div className="text-right">
                <em
                  className="text-sm-1 text-muted"
                  style={{ fontSize: '.6rem' }}
                >
                  {view.date}
                </em>
              </div>
            </div>
          ))}
          {notificationViews.length === 0 && (
            <div className="mx-2 mb-2 border rounded p-2 text-danger">
              No messages at the moment
            </div>
          )}
          <div className="d-flex justify-content-between bg-white p-2 border-top">
            <div className="btn btn-link p-0 m-0">
              <Link to="/user/messages/inbox">See all</Link>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    userInbox: state.user.userInbox,
    currentUser: state.user.currentUser,
  };
};

export default connect(mapStateToProps, { fetchUserInbox, fetchUserSent })(
  MessageNotification,
);
