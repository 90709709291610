import React from 'react';

const AppFooter = () => {
  return (
    <footer className="container-fluid bg-footer">
      <div
        className="container d-lg-flex justify-content-between p-5"
        id="footer"
      >
        <div className="text-footer-shade">
          <div className="bg-compex-logo"></div>
          <p className="   mb-4">Compex Africa Limited</p>
          <p className="font-size-almost">
            287 Ajose Adeogun Street,<br></br>
            Victoria Island,<br></br>
            Lagos, Nigeria.
          </p>
          <p>
            Compex Africa. All rights reserved ©{' '}
            {String(new Date().getFullYear())}
          </p>
        </div>
        <div className="d-flex col-lg-4 col-xl-4 col-12 p-0 justify-content-between">
          <div className="    text-sky-grey">
            <h5 className="text-footer-shade     mb-4">LEGAL</h5>
            <a
              href="https://compexafrica.com/privacy"
              target="_blank"
              rel="noopener noreferrer"
              className="text-decoratioin-none text-sky-grey"
            >
              <p>Privacy Policy</p>
            </a>
            <a
              href="https://compexafrica.com/refunds"
              target="_blank"
              rel="noopener noreferrer"
              className="text-decoratioin-none text-sky-grey"
            >
              <p>Refund Policy</p>
            </a>
            <a
              href="https://compexafrica.com/terms"
              target="_blank"
              rel="noopener noreferrer"
              className="text-decoratioin-none text-sky-grey"
            >
              <p>Terms and Conditions</p>
            </a>
          </div>
          <div className="    text-sky-grey">
            <h5 className="text-footer-shade     mb-4">COMPANY</h5>
            <a
              href="https://compexafrica.com/about"
              target="_blank"
              rel="noopener noreferrer"
              className="text-decoration-none text-sky-grey"
            >
              <p>About us</p>
            </a>
            <a
              href="https://compexafrica.com/contact"
              target="_blank"
              rel="noopener noreferrer"
              className="text-decoratioin-none text-sky-grey"
            >
              <p>Contact</p>
            </a>
            <a
              href="https://compexafrica.com/Careers"
              target="_blank"
              rel="noopener noreferrer"
              className="text-decoratioin-none text-sky-grey"
            >
              <p>Careers</p>
            </a>
            <a
              href="https://compexafrica.com/faq"
              target="_blank"
              rel="noopener noreferrer"
              className="text-decoratioin-none text-sky-grey"
            >
              <p>F.A.Q</p>
            </a>
          </div>
        </div>
        <div className="text-sky-grey">
          <div className="d-flex justify-content-between mb-3 w-40 w-lg-100">
            <h5>
              <a
                href="https://www.facebook.com/compexafrica/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-facebook-f text-footer-shade"></i>
              </a>
            </h5>
            <h5>
              <a
                href="https://twitter.com/compexafrica"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-twitter text-footer-shade"></i>
              </a>
            </h5>
            <h5>
              <a
                href="https://www.instagram.com/compexafrica/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-instagram text-footer-shade"></i>
              </a>
            </h5>
            <h5>
              <a
                href="https://www.linkedin.com/company/compexafrica"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-linkedin-in text-footer-shade"></i>
              </a>
            </h5>
          </div>
          <p className="mb-0">+234-817-999-9931</p>
          <p>+234-817-999-9930</p>
          <p>hello@compexafrica.com</p>
        </div>
      </div>
    </footer>
  );
};

export default AppFooter;
