const industryImages: any = {
  default: {
    value: 'Select',
    defaultImage:
      'https://res.cloudinary.com/dhnxoewjz/image/upload/v1585143948/abstract-art-artistic-background-1103970_m73mpd.jpg',
  },
  Agribusiness: {
    value: 'Agribusiness',
    defaultImage:
      'https://res.cloudinary.com/dhnxoewjz/image/upload/v1585145883/photo-1538140177897-d71d1643349e_zleixa.jpg',
  },
  Fintech: {
    value: 'Fintech',
    defaultImage:
      'https://res.cloudinary.com/dhnxoewjz/image/upload/v1585144739/photo-1501167786227-4cba60f6d58f_othtcc.jpg',
  },
  Ecommerce: {
    value: 'E-commerce',
    defaultImage:
      'https://res.cloudinary.com/dhnxoewjz/image/upload/v1585146917/photo-1565966245341-5a3f55bbf545_mclcwa.jpg',
  },
  Education: {
    value: 'Education',
    defaultImage:
      'https://res.cloudinary.com/dhnxoewjz/image/upload/v1585147364/photo-1503676260728-1c00da094a0b_stlnis.jpg',
  },
  FinancialServices: {
    value: 'Financial Services',
    defaultImage:
      'https://res.cloudinary.com/dhnxoewjz/image/upload/v1585147574/photo-1534469650761-fce6cc26ac0d_1_lbonqf.jpg',
  },
  FoodandBeverage: {
    value: 'Food and Beverage',
    defaultImage:
      'https://res.cloudinary.com/dhnxoewjz/image/upload/v1585147655/photo-1514995669114-6081e934b693_kuzxds.jpg',
  },
  FranchiseReleases: {
    value: 'Franchise Releases',
    defaultImage:
      'https://res.cloudinary.com/dhnxoewjz/image/upload/v1585147820/photo-1515511856280-7b23f68d2996_awckdf.jpg',
  },
  Health: {
    value: 'Health',
    defaultImage:
      'https://res.cloudinary.com/dhnxoewjz/image/upload/v1585147927/photo-1508387027939-27cccde53673_bz3jqp.jpg',
  },
  HospitalityHotel: {
    value: 'Hospitality - Hotel',
    defaultImage:
      'https://res.cloudinary.com/dhnxoewjz/image/upload/v1585147982/photo-1556438758-8d49568ce18e_mimwnm.jpg',
  },
  ImportandExport: {
    value: 'Import and Export',
    defaultImage:
      'https://res.cloudinary.com/dhnxoewjz/image/upload/v1585148016/photo-1564002449348-f859c3aecb8b_jxoybm.jpg',
  },
  Internet: {
    value: 'Internet',
    defaultImage:
      'https://res.cloudinary.com/dhnxoewjz/image/upload/v1585148073/photo-1519389950473-47ba0277781c_aperqx.jpg',
  },
  Leisure: {
    value: 'Leisure',
    defaultImage:
      'https://res.cloudinary.com/dhnxoewjz/image/upload/v1585148147/photo-1518296974463-45172891af06_zlam2f.jpg',
  },
  Manufacturing: {
    value: 'Manufacturing',
    defaultImage:
      'https://res.cloudinary.com/dhnxoewjz/image/upload/v1585148193/photo-1567789884554-0b844b597180_ftk1r4.jpg',
  },
  OilandGas: {
    value: 'Oil and Gas',
    defaultImage:
      'https://res.cloudinary.com/dhnxoewjz/image/upload/v1585148247/photo-1539186607619-df476afe6ff1_av69kt.jpg',
  },
  Pharmaceuticals: {
    value: 'Pharmaceuticals',
    defaultImage:
      'https://res.cloudinary.com/dhnxoewjz/image/upload/v1585148276/photo-1471864190281-a93a3070b6de_yt8yjd.jpg',
  },
  RealEstate: {
    value: 'Real Estate',
    defaultImage:
      'https://res.cloudinary.com/dhnxoewjz/image/upload/v1585148340/photo-1512699355324-f07e3106dae5_g2o7mv.jpg',
  },
  RestaurantandBar: {
    value: 'Restaurant and Bar',
    defaultImage:
      'https://res.cloudinary.com/dhnxoewjz/image/upload/v1585148463/photo-1491223343498-58796d6b503d_pwq9yw.jpg',
  },
  Retail: {
    value: 'Retail',
    defaultImage:
      'https://res.cloudinary.com/dhnxoewjz/image/upload/v1585148545/photo-1519567241046-7f570eee3ce6_ugmgb8.jpg',
  },
  Services: {
    value: 'Services',
    defaultImage:
      'https://res.cloudinary.com/dhnxoewjz/image/upload/v1585148648/photo-1534398079543-7ae6d016b86a_flemp7.jpg',
  },
  TechnologyandMedia: {
    value: 'Technology and Media',
    defaultImage:
      'https://res.cloudinary.com/dhnxoewjz/image/upload/v1585148765/photo-1473831818960-c89731aabc3e_y7e0aa.jpg',
  },
  WasteRecycling: {
    value: 'Waste Recycling',
    defaultImage:
      'https://res.cloudinary.com/dhnxoewjz/image/upload/v1585148889/photo-1577369083609-051feb777894_hw1ifo.jpg',
  },
  WholesaleandDistribution: {
    value: 'Wholesale and Distribution',
    defaultImage:
      'https://res.cloudinary.com/dhnxoewjz/image/upload/v1585149017/photo-1532635042-a6f6ad4745f9_lnajfc.jpg',
  },
};

Object.freeze(industryImages);

export default industryImages;
