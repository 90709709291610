import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import postService from 'common/services/postService';
import { useSelector } from 'react-redux';

const CommunityHeader: React.FunctionComponent = () => {
  const [categories, setCategories] = useState<Array<any>>([]);
  const isLoggedIn = useSelector((state: any) => state.user.isLoggedIn);
  const [currentPath, setCurrentPath] = useState('');
  const history = useHistory();

  useEffect(() => {
    const path = history.location.pathname;
    setCurrentPath(path);

    (async () => {
      const { status, data } = await postService.getCategories();
      if (status === 200 && data) setCategories(data);
    })();
  }, []);

  return (
    <div className="row mt-5 mb-5">
      <div className="form-group my-2 col-lg-2 mobile">
        <select
          name="categories"
          className="form-control border-0 shadow-sm"
          id="sectorSelectDesktop"
          onChange={(e: any) => {
            e.preventDefault();
            const index = e.nativeEvent.target.selectedIndex;
            const text = e.nativeEvent.target[index].text;
            history.push(`/community?category=${text}&id=${e.target.value}`);
          }}
        >
          <option>All Categories</option>
          {categories.map(category => (
            <option key={category.id} value={category.id}>
              {category.name}
            </option>
          ))}
        </select>
      </div>
      <Link to="/community">
        <button
          type="button"
          className={
            currentPath.endsWith('/community')
              ? 'btn btn-primary rounded-0 pl-4 pr-4 my-2'
              : 'btn rounded-0 pl-4 pr-4 my-2'
          }
        >
          Latest
        </button>
      </Link>
      <Link to="/community/categories">
        <button
          type="button"
          className={
            currentPath.endsWith('/categories') ||
            currentPath.includes('category')
              ? 'btn btn-primary rounded-0 pl-4 pr-4 my-2 mr-auto'
              : 'btn rounded-0 pl-4 pr-4 my-2 mr-auto'
          }
        >
          Categories
        </button>
      </Link>
      <Link to="/community/create-post" className="ml-auto">
        <button
          type="button"
          className="btn btn-primary rounded-0 pl-3 pr-3 my-2 mr-4"
        >
          Create a post
        </button>
      </Link>
    </div>
  );
};

export default CommunityHeader;
