import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { PostComment } from 'common/interfaces/PostComment';
import { useSelector } from 'react-redux';
import { useHistory, RouteComponentProps } from 'react-router-dom';
import profilePic from '../../assets/images/smallpic3.png';
import postService from 'common/services/postService';
import Swal from 'sweetalert2';
import { CircularProgress } from '@material-ui/core';
import EditCommentForm from './EditCommentForm';

export interface CommentItemProp extends RouteComponentProps<any> {
  comment: PostComment;
  deleteComment: (comment?: PostComment) => void;
}

const CommentItem: React.FunctionComponent<CommentItemProp> = props => {
  const isLoggedIn = useSelector((state: any) => state.user.isLoggedIn);
  const [hasLikedPost, setHasLikedPost] = useState(false);
  const [comment, setComment] = useState<PostComment>();
  const [commentLikes, setCommentLikes] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const history = useHistory();
  const colorArray = ['#CA1200', '#0051CA', '#FD7900', '#6501A2', '#F4007D'];

  useEffect(() => {
    const { comment } = props;
    setComment(comment);
    setHasLikedPost(comment?.userHasLiked);
    setCommentLikes(comment?.likes);
  }, [props.comment]);

  const toggleLike = async () => {
    if (!isLoggedIn) {
      // redirect after sign in
      const currentPath = history.location.pathname;
      history.replace({
        ...props.location,
        state: { from: { pathname: currentPath } },
        pathname: '/signin',
      });
      return;
    }

    const hasLiked = hasLikedPost;
    const likes = commentLikes;
    setCommentLikes(hasLiked ? likes - 1 : likes + 1);
    setHasLikedPost(!hasLikedPost);

    const res = await postService.toggleCommentLike(comment?.id || 0);
    if (!res) {
      setCommentLikes(likes);
      setHasLikedPost(hasLiked);
    }
  };

  const editComment = () => {
    setShowEditForm(true);
  };

  const updateComment = (text: string) => {
    const commentState: any = { ...comment };
    commentState.body = text;
    setComment(commentState);
    setShowEditForm(false);
  };

  const deleteComment = () => {
    Swal.fire(
      'Are you sure?',
      'Do you really want to delete comment?',
      'warning',
    ).then(async ({ value }) => {
      if (value) {
        try {
          setIsDeleting(true);
          const { status } = await postService.deleteComment(comment?.id || 0);
          if (status === 200) {
            setIsDeleting(false);
            props.deleteComment(comment);
          } else {
            setIsDeleting(false);
          }
        } catch (error) {
          setIsDeleting(false);
        }
      }
    });
  };

  return (
    <div>
      <div className="row mt-5 font-size-comment">
        <div className="col-sm-2 col-md-2 col-lg-2 col-xl-1">
          {comment?.userPhotoUrl ? (
            <img
              src={comment?.userPhotoUrl}
              alt="profile pic"
              className="rounded-circle"
              style={{
                height: '64px',
                width: '64px',
              }}
            />
          ) : (
            <div
              className="d-flex justify-content-center align-items-center avaterImageDiv"
              style={{
                height: '64px',
                width: '64px',
                backgroundColor:
                  colorArray[Math.floor(Math.random() * colorArray.length)],
              }}
            >
              <h1 className="mt-2 text-center text-white font-weight-bold">
                {comment?.userFirstname.substr(0, 1).toUpperCase()}
              </h1>
            </div>
          )}
        </div>
        <div className="col-sm-10 col-md-10 col-lg-10 col-xl-11">
          <div className="d-flex justify-content-between metroBold">
            <p>{`${comment?.userFirstname} ${comment?.userLastname}`}</p>
            <p>{moment(comment?.createdAt).fromNow()}</p>
          </div>
          <p>{comment?.body}</p>
          <div className="d-flex justify-content-end">
            {comment?.isOwner && (
              <>
                <div className="mx-3">
                  <i
                    className="fas fa-edit font-size-info text-warning cursor-pointer"
                    onClick={editComment}
                  ></i>
                </div>
                <div className="mr-5 text-danger">
                  <i
                    className="fas fa-trash-alt font-size-info text-danger cursor-pointer"
                    onClick={deleteComment}
                  ></i>
                  {isDeleting && (
                    <span className="px-3 m-w-90 d-block">
                      <CircularProgress color="inherit" size={'1rem'} />
                    </span>
                  )}
                </div>
              </>
            )}

            <div className="d-flex align-items-center metroBold">
              <span>{commentLikes}</span>
              <span onClick={toggleLike}>
                {hasLikedPost ? (
                  <i className="fas fa-heart font-size-info ml-3 text-primary cursor-pointer"></i>
                ) : (
                  <i className="far fa-heart font-size-info ml-3 cursor-pointer"></i>
                )}
              </span>
            </div>
          </div>
        </div>
        {showEditForm && (
          <div className="w-100 ml-3">
            <EditCommentForm
              comment={comment}
              cancelEdit={() => setShowEditForm(false)}
              updateComment={updateComment}
            />
          </div>
        )}
      </div>
      <hr className="mt-4" />
    </div>
  );
};

export default CommentItem;
