import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Startuplistingitems from './startuplistingitem';
import FadeIn from 'react-fade-in';
import randomstring from 'randomstring';
import StartuplistingPlaceholder from './startuplistingPlaceholder';
import { useDispatch, useSelector } from 'react-redux';
import { fetchStartups } from './../../state/actions/startup';
import { Startup } from 'common/interfaces/startup';

const Startuplisting = () => {
  const dispatch = useDispatch();
  const startups = useSelector((state: any) => state.startup.startups);
  const userStartupId = useSelector((state: any) => state.user.userStartupId);

  const startupLoading = useSelector(
    (state: any) => state.startup.startupLoading,
  );

  let placeholders = [];
  for (let i = 0; i < 9; i++) {
    placeholders.push(
      <FadeIn key={randomstring.generate()}>
        <div className="ml-4">
          <StartuplistingPlaceholder />
        </div>
      </FadeIn>,
    );
  }

  useEffect(() => {
    dispatch(fetchStartups());
  }, [dispatch]);

  return (
    <section style={{ overflow: 'hidden', backgroundColor: '#F4F4F4' }}>
      <div className="row mt-5 ml-4 mb-3 mr-5">
        <div className="col-md-10 ml-2">
          <p style={{ fontWeight: 'bold', fontSize: '30px' }}>Startups</p>
        </div>
        <div>
          {userStartupId ? (
            <Link
              className="btn btn-primary border-thick-primary font-weight-bold px-3 ml-4"
              to="/user/startup"
            >
              <i className="fas fa-eye mr-3"></i>
              View your startup
            </Link>
          ) : (
            <Link
              className="btn btn-primary border-thick-primary font-weight-bold px-3 ml-4"
              to="/pricing"
            >
              <i className="fas fa-plus mr-3"></i>
              Add your startup
            </Link>
          )}
        </div>
      </div>
      <div className="row ml-5 mr-4">
        <div className="row">
          {startupLoading
            ? placeholders
            : startups.map(
                (res: Startup, index: string | number | undefined) => (
                  <Startuplistingitems startup={res} key={index} />
                ),
              )}
        </div>
      </div>
    </section>
  );
};

export default Startuplisting;
