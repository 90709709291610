import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { CommentDto } from 'common/interfaces/CommentDto';
import { CircularProgress } from '@material-ui/core';
import postService from 'common/services/postService';
import { PostComment } from 'common/interfaces/PostComment';

export type CommentFormProp = {
  comment?: PostComment;
  cancelEdit: () => void;
  updateComment: (text: string) => void;
};

const EditCommentForm: React.FunctionComponent<CommentFormProp> = props => {
  const { register, handleSubmit, errors, reset, setValue } = useForm<
    CommentDto
  >();
  const [isLoading, setIsloading] = useState(false);

  useEffect(() => {
    const { comment } = props;
    if (comment) {
      setValue('id', comment.id);
      setValue('body', comment.body);
      setValue('postId', comment.postId);
    }
  }, [props.comment]);

  const onSubmit = async (values: CommentDto) => {
    const { comment } = props;
    values.id = comment?.id;
    values.postId = comment?.postId || 0;
    console.log(values);
    try {
      setIsloading(true);
      const { status, data } = await postService.addComment(values);
      if (status === 200 && data) {
        setIsloading(false);
        reset();
        props.updateComment(values.body);
      }
    } catch (error) {
      setIsloading(false);
    }
  };

  const cancelEdit = () => {
    reset();
    props.cancelEdit();
  };

  return (
    <div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        method="POST"
        id="commentform"
        noValidate
      >
        <div className="form-row my-4">
          <div className="form-group col-12">
            <textarea
              name="body"
              ref={register({ required: true, max: 2000 })}
              rows={2}
              placeholder="Add your comment"
              className={
                errors.body ? 'form-control is-invalid' : 'form-control'
              }
            />
            <div className="invalid-feedback">
              {errors.body && 'You have to say something'}
            </div>
          </div>

          <div className="form-group col-12 text-right">
            <button
              type="button"
              className="btn btn-danger rounded-0 mr-3"
              onClick={cancelEdit}
            >
              Cancel
            </button>
            <button type="submit" className="btn btn-primary rounded-0">
              {isLoading ? (
                <span className="px-3 m-w-90 d-block">
                  <CircularProgress color="inherit" size={'1rem'} />
                </span>
              ) : (
                <span
                  className="text-white min-w-90"
                  style={{ fontFamily: 'Roboto', fontWeight: 500 }}
                >
                  Edit
                </span>
              )}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditCommentForm;
