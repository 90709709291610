import {
  SET_CURRENT_USER,
  LOGOUT_USER,
  LOGIN_USER,
  ADD_MESSAGE_TO_STARTUP_LISTINGS,
  FETCH_USER_INBOX,
  FETCH_USER_SENT,
  FETCH_MORE_SENT,
  FETCH_MORE_INBOX,
  FETCH_INBOX_STARTED,
  FETCH_SENT_STARTED,
  FETCH_MORE_SENT_STARTED,
  FETCH_MORE_INBOX_STARTED,
  FETCH_USER_NOTIFICATIONS,
  SET_USER_STARTUPID,
} from './../actions/types';

export interface IReduxUserState {
  currentUser: object;
  isLoggedIn?: boolean;
  userStartupId?: number;
  messageToStartupListings: Array<string>;
  userInbox: Array<any>;
  userSent: any;
  hasMoreInbox: boolean;
  hasMoreSent: boolean;
  loadingSent: boolean;
  loadingInbox: boolean;
  loadingMoreSent: boolean;
  loadingMoreInbox: boolean;
  userNotifications: Array<any>;
}

const initialState: IReduxUserState = {
  currentUser: {},
  isLoggedIn: undefined,
  userStartupId: 0,
  messageToStartupListings: [],
  userInbox: [],
  userSent: [],
  hasMoreInbox: false,
  hasMoreSent: false,
  loadingSent: false,
  loadingInbox: false,
  loadingMoreSent: false,
  loadingMoreInbox: false,
  userNotifications: [],
};

export default (state: IReduxUserState = initialState, action: any) => {
  switch (action.type) {
    case LOGIN_USER:
      return { ...state, isLoggedIn: true };
    case LOGOUT_USER:
      return { ...state, isLoggedIn: false };
    case SET_CURRENT_USER:
      return { ...state, currentUser: action.payload };
    case ADD_MESSAGE_TO_STARTUP_LISTINGS:
      return {
        ...state,
        messageToStartupListings: action.payload,
      };

    case FETCH_USER_INBOX:
      return {
        ...state,
        hasMoreInbox: action.payload.hasNext,
        userInbox: action.payload.data,
        loadingInbox: false,
      };
    case FETCH_MORE_INBOX:
      let { userInbox } = state;
      if (Object.keys(action.payload).length !== 0) {
        action.payload.data.forEach((e: any) => {
          userInbox.push(e);
        });
      }
      return {
        ...state,
        hasMoreInbox: action.payload.hasNext,
        userInbox,
        loadingMoreInboxProposals: false,
      };
    case FETCH_USER_SENT:
      return {
        ...state,
        hasMoreSent: action.payload.hasNext,
        userSent: action.payload.data,
        loadingSent: false,
      };
    case FETCH_MORE_SENT:
      let { userSent } = state;
      if (Object.keys(action.payload).length !== 0) {
        action.payload.data.forEach((e: any) => {
          userSent.push(e);
        });
      }
      return {
        ...state,
        hasMoreSent: action.payload.hasNext,
        userSent,
        loadingMoreSent: false,
      };
    case FETCH_SENT_STARTED:
      return {
        ...state,
        loadingSent: true,
      };
    case FETCH_INBOX_STARTED:
      return {
        ...state,
        loadingInbox: true,
      };
    case FETCH_MORE_SENT_STARTED:
      return {
        ...state,
        loadingMoreSent: true,
      };
    case FETCH_MORE_INBOX_STARTED:
      return {
        ...state,
        loadingMoreInbox: true,
      };
    case FETCH_USER_NOTIFICATIONS:
      return {
        ...state,
        userNotifications: action.payload,
      };
    case SET_USER_STARTUPID:
      return {
        ...state,
        userStartupId: action.payload,
      };
    default:
      return state;
  }
};
