import startupService from '../../common/services/startupsServices';
import {
  FETCH_STARTUPS,
  EDITSTARTUP,
  SELECT_PLAN,
  FETCH_STARTUP_DETAILS,
  FETCH_STARTUPS_STARTED,
  FETCH_STARTUP_DETAILS_STARTED,
} from './types';

export const fetchStartups = (pageNumber = 1) => (dispatch: any) => {
  dispatch({
    type: FETCH_STARTUPS_STARTED,
  });
  startupService.getStartups(pageNumber).then(
    res => {
      dispatch({
        type: FETCH_STARTUPS,
        payload: res.data,
      });
    },
    err => {
      dispatch({
        type: FETCH_STARTUPS,
        payload: { data: [] },
      });
    },
  );
};

export const fetchStartupDetails = (id: number) => (dispatch: any) => {
  dispatch({
    type: FETCH_STARTUP_DETAILS_STARTED,
  });
  startupService.getStartupDetails(id).then(
    res => {
      dispatch({
        type: FETCH_STARTUP_DETAILS,
        payload: res.data,
      });
    },
    err => {
      dispatch({
        type: FETCH_STARTUP_DETAILS,
        payload: {},
      });
    },
  );
};

export const editListing = (id: any) => (dispatch: any) => {
  startupService.getStartupDetails(id, true).then(
    res => {
      dispatch({
        type: EDITSTARTUP,
        payload: res.data,
      });
    },
    err => {
      dispatch({
        type: EDITSTARTUP,
        payload: {},
      });
    },
  );
};

export const selectPlan = (plan: any) => (dispatch: any) => {
  dispatch({
    type: SELECT_PLAN,
    payload: plan,
  });
};
