import React, { useState, useEffect } from 'react';
import userService from 'common/services/userService';
import { CircularProgress } from '@material-ui/core';

const UserSubscriptions = (props: any) => {
  const [subscriptions, setSubscriptions] = useState<Array<any>>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [done, setDone] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const { status, data } = await userService.getUserSubscriptions();
        if (status === 200 && data) {
          setSubscriptions(data);
          setIsLoading(false);
          setDone(true);
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    })();
  }, []);

  const gotoSubsciptionDetails = (subscription: any) => {
    console.log(props.history);
    props.history.push('/user/subscriptions/' + subscription.subscriptionId);
  };

  return (
    <div>
      <div className="ml-lg-5 mt-md-5">
        <h1>Invoices and Subscriptions</h1>
        <div className="w-50 my-3 py-3">
          <hr></hr>
        </div>
        <div className="table-responsive shadow">
          <table className="table table-hover">
            <thead className="">
              <tr>
                <th className="py-3">#</th>
                <th>Title of Listing</th>
                <th>Current Plan</th>
                <th>Start Date</th>
                <th>Renewal Date</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody className="bg-white">
              {isLoading && (
                <tr>
                  <td>
                    <CircularProgress color="primary" size={'1rem'} />
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              )}
              {subscriptions &&
                subscriptions.length > 0 &&
                subscriptions.map((subscription, index) => (
                  <tr
                    key={index}
                    className="cursor-pointer"
                    onClick={() => gotoSubsciptionDetails(subscription)}
                  >
                    <td>{index + 1}</td>
                    <td>{subscription.listingType}</td>
                    <td>
                      {subscription.planType &&
                        subscription.planType.split(/(?=[A-Z])/)[0]}
                    </td>
                    <td>
                      {new Date(subscription.planBeginDate).toDateString()}
                    </td>
                    <td>{new Date(subscription.planEndDate).toDateString()}</td>
                    <td>{subscription.status}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        {done && (!subscriptions || subscriptions.length === 0) && (
          <div className="text-danger ml-5 py-5">
            You don't have any subscription
          </div>
        )}
      </div>
    </div>
  );
};

export default UserSubscriptions;
