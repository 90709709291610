import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { CircularProgress } from '@material-ui/core';
import authService from '../../common/services/authService';
import { useSelector } from 'react-redux';
import { User } from './../../common/interfaces/User';

const VerifyEmail = () => {
  const [isloading, setIsLoading] = useState(false);
  const currentUser: User = useSelector((state: any) => state.user.currentUser);

  const resendConfirmationEmail = async (e: any) => {
    e.preventDefault();
    try {
      const { email } = currentUser;
      setIsLoading(true);
      const res = await authService.resendConfirmationEmail(email);
      if (res) {
        Swal.fire(
          'Email Resent Successfully',
          `A new verification email has been sent to ${email}. Didn't get an email after a few minutes? Please check you spam, promotions, social or junk folder.`,
          'success',
        );
        setIsLoading(false);
      } else {
        Swal.fire(
          'Action failed',
          `Unable to resend email. Try again`,
          'error',
        );
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <div className="d-flex flex-column align-items-center container pb-5">
      <div className="text-primary my-3">
        <i className="fas fa-check-circle fa-4x"></i>
      </div>
      <h1 className="h3 text-verify-dark">Verification link sent</h1>
      <div className="card bg-white shadow rounded mb-3 col-lg-6 my-5">
        <div className="card-body px-5 py-5">
          <div className="card-text text-verify-light font-weight-light text-center">
            A verification link has been sent to your email. Kindly click the
            link to verify your account and have access to your dashboard.
            <div className="my-3 text-center">
              <span className="font-weight-bold">
                Didn't get an email after a few minutes?
              </span>
              <br />
              Please check you spam, promotions, social or junk folder.
              <br />
              <br />
              {isloading ? (
                <button className="btn btn-lg btn-primary my-2">
                  <CircularProgress color="inherit" size={'1rem'} />
                </button>
              ) : (
                <button
                  className="btn btn-outline-primary"
                  onClick={resendConfirmationEmail}
                >
                  Resend Confirmation Email
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="pb-5">
        <Link to="/signin" className="btn btn-primary" href="" role="button">
          Login
        </Link>
      </div>
    </div>
  );
};

export default VerifyEmail;
