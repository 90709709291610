import React, { useState, useEffect } from 'react';
import { PostComment } from 'common/interfaces/PostComment';
import CommentItem from './CommentItem';
import { RouteComponentProps } from 'react-router-dom';
import CommentForm from './CommentForm';
import { useSelector } from 'react-redux';

export interface PostCommentsProp extends RouteComponentProps<any> {
  comments: Array<PostComment>;
  postId: number;
  commentAdded: () => void;
  commentDeleted: () => void;
}

const PostComments: React.FunctionComponent<PostCommentsProp> = props => {
  const [comments, setComments] = useState<Array<PostComment>>([]);
  const isLoggedIn = useSelector((state: any) => state.user.isLoggedIn);

  useEffect(() => {
    setComments(props.comments);
  }, [props.comments]);

  const commentAdded = (comment: PostComment) => {
    if (!comment) return;
    const prevComments = [...comments];
    prevComments.push(comment);
    setComments(prevComments);
    props.commentAdded();
  };

  const deleteComment = (comment?: PostComment) => {
    if (!comment) return;
    const filterComments = comments.filter(x => x.id !== comment.id);
    setComments(filterComments);
    props.commentDeleted();
  };

  const signinComment = (e: any) => {
    e.preventDefault();
    const currentPath = props.history.location.pathname;
    props.history.replace({
      ...props.location,
      state: { from: { pathname: currentPath + '#commentform' } },
      pathname: '/signin',
    });
    return;
  };

  return (
    <div>
      {comments && comments.length === 0 && (
        <div className="text-center">
          <span>No comments</span>
        </div>
      )}
      {comments &&
        comments.length > 0 &&
        comments.map((comment: PostComment) => (
          <CommentItem
            comment={comment}
            key={comment.id}
            deleteComment={deleteComment}
            {...props}
          />
        ))}

      {isLoggedIn ? (
        <CommentForm commentAdded={commentAdded} postId={props.postId} />
      ) : (
        <div className="text-center">
          <a className="text-primary cursor-pointer" onClick={signinComment}>
            Sign in{' '}
          </a>{' '}
          to add your comment
        </div>
      )}
    </div>
  );
};

export default PostComments;
