import React from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import CommunityHeader from './CommunityHeader';

const PostListingPageMobile = (props: any) => {
  const { category, posts, postIsLoading } = props;
  const history = useHistory();
  const colorArray = ['#CA1200', '#0051CA', '#FD7900', '#6501A2', '#F4007D'];
  const time = (createdAt: any) => {
    const exactmoment = moment(createdAt).fromNow();
    const Obj: any = {
      hours: 'hrs',
      hour: 'hr',
      minutes: 'mins',
      minute: 'min',
    };
    return exactmoment.replace(/hours|hour|minutes|minute/gi, function(
      matched,
    ) {
      return Obj[matched];
    });
  };
  return (
    <div className="postlisting container d-md-none mb-3">
      <CommunityHeader />
      <div>
        {category && (
          <div className="mb-3">
            <h4>{category} Posts</h4>
          </div>
        )}
        {!category && postIsLoading === true && (
          <div className="text-center p-5">Loading...</div>
        )}
        {!postIsLoading && posts?.length === 0 && (
          <div className="text-center p-5">No posts</div>
        )}
      </div>
      {posts?.length > 0 && (
        <>
          {posts.map((post: any, index: any) => (
            <div
              key={index}
              onClick={() => {
                history.push(`/community/posts/${post?.id}`);
              }}
              className="border-bottom pt-1"
            >
              <div className="d-flex">
                <div>
                  {post.userPhotoUrl ? (
                    <img
                      src={post?.userPhotoUrl}
                      alt=""
                      style={{ height: '45px', width: '45px' }}
                      className="rounded-circle mr-1"
                    />
                  ) : (
                    <div
                      className="ml-n2 mr-3 avaterImageDiv"
                      style={{
                        height: '45px',
                        width: '45px',
                        backgroundColor:
                          colorArray[
                            Math.floor(Math.random() * colorArray.length)
                          ],
                      }}
                    >
                      <h1 className="text-center text-white align-middle mb-1">
                        {post.userFirstname.substr(0, 1).toUpperCase()}
                      </h1>
                    </div>
                  )}
                </div>
                <div style={{ width: '60%' }}>
                  <p className="">{post?.title}</p>
                </div>
                <div className="w-25">
                  <p className="ml-3 text-faded-black">
                    {post?.commentsCount}{' '}
                    {post?.commentsCount > 1 ? 'replies' : 'reply'}
                  </p>
                </div>
              </div>
              <span className="d-flex mt-n2 ml-5b mb-0">
                <div className="mr-auto d-flex">
                  <div
                    className="mt-1 mr-1 technology"
                    style={{ backgroundColor: post.color }}
                  ></div>
                  <h6>{post?.postCategoryName}</h6>
                </div>
                <h6 className="ml-5 text-faded-black">
                  {time(post?.createdAt)}
                </h6>
              </span>
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default PostListingPageMobile;
