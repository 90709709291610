import React, { useState } from 'react';
import GoogleLogin from 'react-google-login';
import authService from '../services/authService';
import { googleAppId } from '../../config';
import { CircularProgress } from '@material-ui/core';
import { RouteComponentProps } from 'react-router-dom';

export interface GoogleLoginBtnProps extends RouteComponentProps<any> {
  displayText: string;
}

const GoogleLoginBtn = (props: GoogleLoginBtnProps) => {
  const [isTriggered, setIstriggered] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const loadLogin = () => {
    setIstriggered(true);
  };

  const onSuccess = async (response: any) => {
    console.log(response);

    if (!response.accessToken) {
      setIstriggered(false);
      return;
    }

    try {
      setIsLoading(true);
      const res = await authService.googleLogin(response);
      if (res) {
        setIsLoading(false);
        const state: any = props.location.state;
        const redirectPath = state ? state.from.pathname : '/user/dashboard';
        props.history.replace(redirectPath);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const onFailure = (response: any) => {
    console.log(response);
    setIstriggered(false);
  };

  return (
    <>
      <div className="g-signin2" data-onsuccess="onSignIn"></div>
      <div className="btn border border-thick min-w-100" onClick={loadLogin}>
        <div className="d-flex justify-content-center align-items-center">
          <div className="bg-google-icon min-w-rem-unit min-h-rem-unit"></div>
          {isLoading ? (
            <span className="px-3 m-w-90">
              <CircularProgress color="inherit" size={'0.9rem'} />
            </span>
          ) : (
            <span
              className="text-muted pl-3 min-w-90"
              style={{ fontFamily: 'Roboto', fontWeight: 500 }}
            >
              {props.displayText}
            </span>
          )}
        </div>
      </div>

      {isTriggered && (
        <GoogleLogin
          clientId={googleAppId || ''}
          autoLoad={true}
          render={renderProps => <div onClick={renderProps.onClick}></div>}
          onSuccess={onSuccess}
          onFailure={onFailure}
          cookiePolicy={'single_host_origin'}
        />
      )}
    </>
  );
};

export default GoogleLoginBtn;
