import { Link } from 'react-router-dom';
import { Slide, Fade } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

const HomeLanding = () => {
  const [firstPostRender, setfirstPostRender] = useState(false);
  const [secondPostRender, setsecondPostRender] = useState(false);
  const isLoggedIn = useSelector((state: any) => state.user.isLoggedIn);

  useEffect(() => {
    setTimeout(() => {
      setfirstPostRender(true);
    }, 500);
    setTimeout(() => {
      setsecondPostRender(true);
    }, 700);
  }, []);

  return (
    <section className="container d-flex flex-wrap align-items-center justify-content-center position-relative min-vh-xl-40 min-vh-lg-85 min-vh-90">
      <div className="text-center">
        <Slide
          direction="right"
          timeout={900}
          in={firstPostRender}
          mountOnEnter
          unmountOnExit
        >
          <h1
            className="font-weight-bold font-size-action"
            style={{ fontFamily: 'Metropolis-ExtraBold' }}
          >
            Start a business. Raise Capital
          </h1>
        </Slide>
        <Fade in={secondPostRender} timeout={2000}>
          <h2
            className="mb-5 font-size-info"
            style={{ fontFamily: 'Metropolis-Medium' }}
          >
            Starting a business can be tough, getting investment
            <br /> shouldn't be.
          </h2>
        </Fade>
        <Slide
          direction="up"
          timeout={900}
          in={secondPostRender}
          mountOnEnter
          unmountOnExit
        >
          <div>
            <Link
              to={isLoggedIn ? '/user/dashboard' : '/signup'}
              className="btn btn-primary font-size-info rounded-5-px py-2 px-5 mx-5 mt-3 mr-md-4"
              style={{ fontFamily: 'Metropolis-Medium' }}
            >
              Get Started
            </Link>
            <Link
              to="/startups"
              className="btn btn-thick-outline-primary font-size-info rounded-5-px mt-3"
              style={{
                fontFamily: 'Metropolis-Medium',
                paddingRight: 30,
                paddingLeft: 30,
              }}
            >
              View Startups
            </Link>
          </div>
        </Slide>
      </div>
      <div className="bg-landing-faded bg-position-bottom position-absolute w-100 h-100"></div>
    </section>
  );
};

export default HomeLanding;
