export enum ListingType {
  Investor = 'Investor',
  Advisor = 'Advisor',
  Startup = 'Startup',
  Business = 'Business',
  Franchise = 'Franchise',
  InvestorDomain = 'investors',
  AdvisorDomain = 'advisors',
  StartupDomain = 'startups',
  BusinessDomain = 'businesses',
  FranchiseDomain = 'franchises',
}
