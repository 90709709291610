import baseApiUrl from '../../config';
import http from './httpService';

const getSubscriptionPlans = async () => {
  const { status, data } = await http.get(`${baseApiUrl}subscriptions/plans`);

  if (status !== 200) return [];

  return data;
};

const subscribeUser = async (obj: any) => {
  const payload = {
    userEmail: obj.userEmail,
    planId: obj.planId,
    profileType: obj.listingType,
    profileId: obj.listingId,
    transactionRef: obj.txref,
    subscriptionId: obj.subscriptionId,
  };

  const { status } = await http.post(
    `${baseApiUrl}subscriptions/subscribe-user`,
    payload,
  );

  if (status !== 200) return false;

  return true;
};

const validateCouponCode = async (couponCode: any, profileType: any) => {
  const { status, data } = await http.post(
    `${baseApiUrl}subscriptions/validate`,
    {
      couponCode,
      profileType,
    },
  );

  if (status === 200 && data) return data;

  return false;
};

export default {
  getSubscriptionPlans,
  subscribeUser,
  validateCouponCode,
};
