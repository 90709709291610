import React from 'react';
import NavbarDesktop from './NavbarDesktop';
import AppNavBarMobile from './NavbarMobile';

const AppNavbar = (props: any) => {
  return (
    <>
      <NavbarDesktop {...props} />
      <AppNavBarMobile />
    </>
  );
};

export default AppNavbar;
