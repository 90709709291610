import React from 'react';
import portfolio from '../../assets/images/portfolio.png';
import presentation from '../../assets/images/presentation.png';
import graph from '../../assets/images/graph.png';
const InvestorBusiness = () => {
  return (
    <div
      className="w-100"
      style={{
        backgroundColor: '#edf7ff',
      }}
    >
      <div className="row">
        <div className="col-sm text-center">
          <img
            src={portfolio}
            alt="portfolio"
            style={{
              marginTop: 50,
              height: 100,
              width: 100,
            }}
          />
          <h3
            className="mt-5"
            style={{
              fontFamily: 'metropolis-light',
            }}
          >
            Invest in businesses <br /> across Africa
          </h3>
        </div>
        <div className="col-sm text-center m-0">
          <img
            src={presentation}
            alt="Presentation"
            style={{
              marginTop: 50,
              height: 100,
              width: 100,
              fontFamily: 'metropolis-light',
            }}
          />
          <h3
            className="mt-5"
            style={{
              fontFamily: 'metropolis-light',
            }}
          >
            Invest in businesses <br /> across Africa
          </h3>
        </div>
        <div className="col-sm text-center m-0">
          <img
            src={graph}
            alt="Graph"
            style={{
              marginTop: 50,
              height: 100,
              width: 100,
            }}
          />
          <h3
            className="mt-5"
            style={{
              fontFamily: 'metropolis-light',
            }}
          >
            Invest in businesses <br /> across Africa
          </h3>
        </div>
      </div>
    </div>
  );
};

export default InvestorBusiness;
