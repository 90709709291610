import React from 'react';
import { Route, Switch } from 'react-router-dom';
import StartupListingItemPage from './../startup/StartupListingItemPage';
import InterestedInvestors from './interestedInvestors';
import NotificationPage from './NotificationPage';
import UserDashboard from './Dashboard';
import Message from './Message';
import UserStartup from './UserStartup';
import UserSubscriptions from './UserSubscription';
import SubscriptionDetails from './SubscriptionDetails';
import UpgradeStartup from './UpgradeStartup';
import UserSettings from './UserSettings';
import CommunityPage from './CommunityPage';

const DashboardRoutes = (props: any) => {
  return (
    <Switch>
      <Route path={`${props.match.url}/dashboard`} component={UserDashboard} />

      <Route
        path={`${props.match.url}/startups/:id`}
        component={StartupListingItemPage}
      />
      <Route
        path={`${props.match.url}/startup/:id/upgrade`}
        component={UpgradeStartup}
      />
      <Route path={`${props.match.url}/startup`} component={UserStartup} />
      <Route
        path={`${props.match.url}/investors`}
        component={InterestedInvestors}
      />
      <Route
        path={`${props.match.url}/notification`}
        component={NotificationPage}
      />
      <Route path={`${props.match.url}/community`} component={CommunityPage} />
      <Route
        path={`${props.match.url}/subscriptions/:id`}
        component={SubscriptionDetails}
        {...props}
      />
      <Route
        path={`${props.match.url}/subscriptions`}
        component={UserSubscriptions}
        {...props}
      />
      <Route path={`${props.match.url}/messages`} component={Message} />
      <Route
        path={`${props.match.url}/notification`}
        component={NotificationPage}
      />
      <Route path={`${props.match.url}/settings`} component={UserSettings} />
    </Switch>
  );
};

export default DashboardRoutes;
