import React from 'react';

const ContactFail = (props: any) => {
  return (
    <div className="align-items-center bg-white d-flex flex-column shadow rounded-1">
      <i className="fas fa-times-circle text-danger my-5 h2 font-weight-bold"></i>
      <h1 className="h4 text-verify-dark">Not sent</h1>
      <div className="p-5">
        <p className="h5 text-verify-light font-weight-light text-center">
          We're so sorry. Your proposal message could not be sent at this time.
          Please try again in a few moments.
        </p>
      </div>
      <div className="pb-5">
        <button className="btn btn-primary" onClick={props.handleOnDone}>
          Okay
        </button>
      </div>
    </div>
  );
};

export default ContactFail;
