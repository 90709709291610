export const GET_STARTUPS = 'GET_STARTUPS';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const LOGIN_USER = 'LOGIN_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
export const FETCH_STARTUPS = 'FETCH_STARTUPS';
export const FETCH_STARTUPS_STARTED = 'FETCH_STARTUPS_STARTED';
export const FETCH_STARTUP_DETAILS = 'FETCH_STARTUP_DETAILS';
export const FETCH_STARTUP_DETAILS_STARTED = 'FETCH_STARTUP_DETAILS_STARTED';
export const EDITSTARTUP = 'EDITSTARTUP';
export const SELECT_PLAN = 'SELECT_PLAN';
export const ADD_MESSAGE_TO_STARTUP_LISTINGS =
  'ADD_MESSAGE_TO_STARTUP_LISTINGS';
export const FETCH_USER_INBOX = 'FETCH_USER_INBOX';
export const FETCH_USER_SENT = 'FETCH_USER_SENT';
export const FETCH_MORE_SENT = 'FETCH_MORE_SENT';
export const FETCH_MORE_INBOX = 'FETCH_MORE_INBOX';
export const FETCH_INBOX_STARTED = 'FETCH_INBOX_STARTED';
export const FETCH_SENT_STARTED = 'FETCH_SENT_STARTED';
export const FETCH_MORE_SENT_STARTED = 'FETCH_MORE_SENT_STARTED';
export const FETCH_MORE_INBOX_STARTED = 'FETCH_MORE_INBOX_STARTED';
export const FETCH_USER_NOTIFICATIONS = 'FETCH_USER_NOTIFICATIONS';
export const SET_USER_STARTUPID = 'SET_USER_STARTUPID';
