import React from 'react';
import { Route, Switch } from 'react-router-dom';
import About from './pages/static/About';
import Contact from './pages/static/Contact';
import Home from './pages/home/Home';
import PageNotFound from './pages/static/PageNotFound';
import Login from './pages/auth/Login';
import Signup from './pages/auth/Signup';
import VerifyEmail from './pages/auth/VerifyEmail';
import RegisterStartup from './pages/startup/RegisterStartup';
import ForgotPassword from 'pages/auth/ForgotPassword';
import StartupListingItemPage from 'pages/startup/StartupListingItemPage';
import Startups from './pages/startup/startuplisting';
import ProtectedRoute from './common/components/ProtectedRoute';
import ConfirmUserEmail from './pages/auth/ConfirmUserEmail';
import UserEmailConfirmSuccess from './pages/auth/UserEmailConfirmSuccess';
import InvestorPage from './pages/investor/InvestorPage';
import Sidebar from 'pages/user/Sidebar';
import PostDetailsPage from 'pages/community/PostDetailsPage';
import CreatePost from 'pages/community/CreatePost';
import Pricing from 'pages/static/Pricing';
import PostListingPage from 'pages/community/PostListingPage';
import PostCategory from 'pages/community/PostCategory';

const Routes = () => {
  return (
    <Switch>
      <Route path="/signin" component={Login} />
      <Route path="/forgot-password" component={ForgotPassword} />
      <Route path="/signup" component={Signup} />
      <Route path="/registration-success" component={VerifyEmail} />
      <ProtectedRoute path="/register-startup" component={RegisterStartup} />
      <Route path="/about" component={About} />
      <Route path="/contact" component={Contact} />
      <Route path="/user/confirm-email" component={ConfirmUserEmail} />
      <Route
        path="/user/confirm-email-success"
        component={UserEmailConfirmSuccess}
      />
      <ProtectedRoute path="/user" component={Sidebar} />
      <Route path="/startups/:id" component={StartupListingItemPage} />
      <Route path="/startups" component={Startups} />
      <Route path="/pricing" component={Pricing} />
      <Route path="/investors" component={InvestorPage} />
      <ProtectedRoute path="/community/create-post" component={CreatePost} />
      <Route path="/community/categories" component={PostCategory} />
      <Route path="/community/posts/:id" component={PostDetailsPage} />
      <Route path="/community" component={PostListingPage} />
      <Route path="/" exact component={Home} />
      <Route path="*" component={PageNotFound} />
    </Switch>
  );
};

export default Routes;
