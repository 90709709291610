import React from 'react';
import InvestorText from './InvestorText';
import InvestorBusiness from './InvestorBusiness';
import FeaturedInvestors from './FeaturedInvestors';
import InvestorsCarousel from './InvestorsCarousel';
import ViewMoreBtn from './ViewMoreBtn';
const InvestorPage = () => {
  return (
    <section style={{ overflow: 'hidden' }}>
      <InvestorText />
      <InvestorBusiness />
      <FeaturedInvestors />
      <InvestorsCarousel />
      <ViewMoreBtn />
    </section>
  );
};

export default InvestorPage;
