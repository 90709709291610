import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, RouteComponentProps, Redirect } from 'react-router-dom';
import { UserDto } from '../../common/interfaces/User';
import authService from '../../common/services/authService';
import { CircularProgress } from '@material-ui/core';
import FormAlertMessage from '../../common/components/FormAlertMessage';
import Swal from 'sweetalert2';
import GoogleLoginBtn from 'common/components/GoogleLoginBtn';
import FacebookLoginBtn from 'common/components/FacebookLoginBtn';
import { useSelector } from 'react-redux';

export interface LoginProps extends RouteComponentProps<any> {}

const Login = (props: LoginProps) => {
  const { register, handleSubmit, errors } = useForm<UserDto>();
  const isLoggedIn = useSelector((state: any) => state.user.isLoggedIn);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const onSubmit = async (values: UserDto) => {
    try {
      setIsLoading(true);
      const res = await authService.login(values);
      if (res) {
        setIsLoading(false);
        const state: any = props.location.state;
        const redirectPath = state ? state.from.pathname : '/user/dashboard';
        props.history.replace(redirectPath);
      } else {
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);

      const { response } = err;

      if (response && response.data && response.data.title) {
        let message = response.data.title;

        if (message.includes('email has not been verified')) {
          message = (
            <div className="d-block w-100">
              Your email address has not been verified. Please kindly click the
              link that was sent to your email to verify your account. <br />
              <br />
              <span className="font-weight-bold">
                Didn't get an email after a few minutes?
              </span>
              <br />
              Please check your spam, promotions, social or junk folder.
              <br />
              <br />
              <button
                className="btn btn-primary btn-sm"
                onClick={e => resendConfirmationEmail(e, values.email)}
              >
                Resend Confirmation Email
              </button>
            </div>
          );
        }

        setErrorMessage(message);
      }
    }
  };

  const resendConfirmationEmail = async (e: any, email: string) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const res = await authService.resendConfirmationEmail(email);
      if (res) {
        Swal.fire(
          'Email Resent Successfully',
          `A new verification email has been sent to ${email}. Didn't get an email after a few minutes? Please check you spam, promotions, social or junk folder.`,
          'success',
        );
        setErrorMessage('');
        setIsLoading(false);
      } else {
        Swal.fire(
          'Action failed',
          `Unable to resend email. Try again`,
          'error',
        );
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  return isLoggedIn ? (
    <Redirect to="/user/dashboard" />
  ) : (
    <div className="container-fluid bg-light-grey min-vh-100">
      <div className="container">
        <div className="row justify-content-center p-md-5 p-2">
          <div className="col-md-5">
            <div className="text-center">
              <h4 className="text-black font-weight-bold">Welcome back!</h4>
              <div className="text-black small">
                <div className="pb-2">Please sign in</div>
              </div>
            </div>
            <div className="mt-5 mb-4 p-md-5 p-4 bg-form shadow rounded-10-px small">
              <form onSubmit={handleSubmit(onSubmit)} method="POST" noValidate>
                <div className="form-row">
                  <div className="form-group col-12">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      name="email"
                      ref={register({
                        required: 'Required',
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                          message: 'invalid email address',
                        },
                      })}
                      className={
                        errors.email
                          ? 'form-control is-invalid'
                          : 'form-control'
                      }
                    />
                    <div className="invalid-feedback">
                      {errors.email && 'A valid email address is required'}
                    </div>
                  </div>

                  <div className="form-group col-12">
                    <label htmlFor="password">Password</label>
                    <input
                      type="password"
                      name="password"
                      ref={register({ required: true })}
                      className={
                        errors.password
                          ? 'form-control is-invalid'
                          : 'form-control'
                      }
                    />
                    <div className="invalid-feedback">
                      {errors.password && 'Password is required'}
                    </div>
                  </div>

                  {errorMessage && (
                    <div className="form-group col-12">
                      <FormAlertMessage message={errorMessage} />
                    </div>
                  )}

                  <div className="form-group col-12">
                    <div className="row justify-content-center py-3">
                      <div className="col-md-12">
                        <button
                          type="submit"
                          className="btn btn-primary btn-block rounded-5-px d-flex justify-content-center align-items-center"
                        >
                          <div className="bg-compex-icon min-w-rem-unit min-h-rem-unit"></div>

                          {isLoading ? (
                            <span className="px-3 m-w-90 d-block">
                              <CircularProgress color="inherit" size={'1rem'} />
                            </span>
                          ) : (
                            <span
                              className="text-white pl-3 min-w-90"
                              style={{ fontFamily: 'Roboto', fontWeight: 500 }}
                            >
                              Sign in with Compex Africa
                            </span>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>

              <div className="d-flex flex-column justify-content-center mt-3">
                <div className="mx-2 my-2 text-center">
                  <GoogleLoginBtn
                    displayText={'Sign in with Google'}
                    {...props}
                  />
                </div>

                <div className="mx-2 my-2 text-center">
                  <FacebookLoginBtn
                    displayText={'Sign in with Facebook'}
                    {...props}
                  />
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-between text-off-black font-size-below">
              <span>
                New to <strong>Compex Africa</strong>?{' '}
                <Link to="/signup" className="font-weight-bolder">
                  Sign up
                </Link>
              </span>
              <Link
                to="/forgot-password"
                className="text-off-black font-weight-bold"
              >
                Forgot Password?
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
