import React, { useEffect, useState } from 'react';
import moment from 'moment';
import greenrectangle from '../../assets/images/greenrect.png';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import postService from 'common/services/postService';
import avater from '../../assets/images/user.svg';
import { Post } from 'common/interfaces/Post';
import PostComments from './PostComments';
import { useSelector } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import Swal from 'sweetalert2';

export interface PostDetailsPageProp extends RouteComponentProps<any> {}

const PostDetailsPage: React.FunctionComponent<PostDetailsPageProp> = props => {
  const isLoggedIn = useSelector((state: any) => state.user.isLoggedIn);
  const [post, setPost] = useState<Post>();
  const [hasLikedPost, setHasLikedPost] = useState(false);
  const [postLikes, setPostLikes] = useState(0);
  const [commentsCount, setCommentsCount] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [latestPosts, setLatestPosts] = useState<Array<Post>>([]);
  const history = useHistory();
  const colorArray = ['#CA1200', '#0051CA', '#FD7900', '#6501A2', '#F4007D'];

  useEffect(() => {
    const id = props.match.params.id;
    (async () => {
      const { status, data } = await postService.getPosts();
      if (status === 200 && data) setLatestPosts(data.data);
    })();

    if (id) {
      (async () => {
        const { status, data } = await postService.getPostDetail(id, false);
        if (status === 200 && data) {
          const mappedData = setPostsColor(data);
          setPost(mappedData);
          setHasLikedPost(data?.userHasLiked);
          setPostLikes(data?.likes);
          setCommentsCount(data?.commentsCount);
        }
      })();
    }
  }, []);

  const setPostsColor = (post: Post) => {
    if (post.postCategoryName === 'Technology') post.color = '#00ca93';
    else if (post.postCategoryName === 'Fashion') post.color = '#EA004D';
    else if (post.postCategoryName === 'Event') post.color = '#DD5000';
    else if (post.postCategoryName === 'Design') post.color = '#2002B3';
    else post.color = '#DD5000';
    return post;
  };

  const togglePostLike = async () => {
    if (!isLoggedIn) {
      // redirect after sign in
      const currentPath = history.location.pathname;
      history.replace({
        ...props.location,
        state: { from: { pathname: currentPath } },
        pathname: '/signin',
      });
      return;
    }

    const hasLiked = hasLikedPost;
    const likes = postLikes;
    setPostLikes(hasLiked ? likes - 1 : likes + 1);
    setHasLikedPost(!hasLikedPost);

    const res = await postService.togglePostLike(post?.id || 0);
    if (!res) {
      setPostLikes(likes);
      setHasLikedPost(hasLiked);
    }
  };

  const commentAdded = () => {
    setCommentsCount(commentsCount + 1);
  };

  const commentDeleted = () => {
    setCommentsCount(commentsCount - 1);
  };

  const editPost = () => {
    history.push(`/community/create-post?id=${post?.id}`);
  };

  const deletePost = () => {
    Swal.fire(
      'Are you sure?',
      'Do you really want to delete post?',
      'warning',
    ).then(async ({ value }) => {
      if (value) {
        try {
          setIsDeleting(true);
          console.log('post id is ' + post?.id);
          const { status } = await postService.deletePost(post?.id || 0);
          if (status === 200) {
            setIsDeleting(false);
            props.history.replace('/community');
          } else {
            setIsDeleting(false);
          }
        } catch (error) {
          setIsDeleting(false);
        }
      }
    });
  };

  const color = post?.color;

  return (
    <div className="bg-gray p-5">
      {post ? (
        <div className="row">
          <div className="col-lg-9 col-sm">
            <h2 className="metroBold">{post?.title}</h2>
            <div className="d-flex">
              <span className="pr-2">
                <i
                  className="fas fa-square font-size-info"
                  style={{ color: color }}
                ></i>
              </span>
              <p className=" metroBold ">{post?.postCategoryName}</p>
            </div>
            <hr />
            <div className="row">
              <div className="col-sm-2 col-md-2 col-lg-2 col-xl-1">
                {post.userPhotoUrl ? (
                  <img
                    className="rounded-circle"
                    src={post?.userPhotoUrl || avater}
                    alt="profile pic"
                    style={{ width: '64px', height: '64px' }}
                  />
                ) : (
                  <div
                    className="d-flex justify-content-center align-items-center avaterImageDiv"
                    style={{
                      height: '64px',
                      width: '64px',
                      backgroundColor:
                        colorArray[
                          Math.floor(Math.random() * colorArray.length)
                        ],
                    }}
                  >
                    <h1 className="mt-2 text-center text-white font-weight-bold">
                      {post.userFirstname.substr(0, 1).toUpperCase()}
                    </h1>
                  </div>
                )}
              </div>
              <div className="col-sm-10 col-md-10 col-lg-10 col-xl-11">
                <div className="d-flex justify-content-between">
                  <p className="metroBold">{`${post?.userFirstname} ${post?.userLastname}`}</p>
                  <p className="metroBold">
                    {moment(post?.createdAt).fromNow()}
                  </p>
                </div>

                <div
                  id="postBody"
                  className="pt-5 pb-2"
                  dangerouslySetInnerHTML={{ __html: post?.body }}
                ></div>
                <div className="d-flex flex-column flex-md-row justify-content-between">
                  <div className="d-flex">
                    <div className="d-flex metroBold">
                      <p>{commentsCount}</p>
                      <p className="ml-2">Replies</p>
                    </div>
                    <div className="d-flex pl-5 metroBold">
                      <p>{post?.views}</p>
                      <p className="ml-2">Views</p>
                    </div>
                  </div>

                  <div className="d-flex justify-items-end">
                    {post?.isOwner && (
                      <>
                        <div className="mx-3">
                          <span
                            className={
                              post?.isApproved ? 'text-success' : 'text-warning'
                            }
                          >
                            <i className="fas fa-square mr-2"></i>
                          </span>
                          {post?.isApproved ? 'Active' : 'Pending'}
                        </div>
                        <div className="mx-3">
                          <i
                            className="fas fa-edit font-size-info text-warning cursor-pointer"
                            onClick={editPost}
                          ></i>
                        </div>
                        <div className="mr-5 text-danger">
                          <i
                            className="fas fa-trash-alt font-size-info text-danger cursor-pointer"
                            onClick={deletePost}
                          ></i>
                          {isDeleting && (
                            <span className="px-3 m-w-90 d-block">
                              <CircularProgress color="inherit" size={'1rem'} />
                            </span>
                          )}
                        </div>
                      </>
                    )}

                    <div className="d-flex align-items-center metroBold">
                      <span>{postLikes}</span>
                      <span onClick={togglePostLike}>
                        {hasLikedPost ? (
                          <i className="fas fa-heart font-size-info ml-3 text-primary cursor-pointer"></i>
                        ) : (
                          <i className="far fa-heart font-size-info ml-3 cursor-pointer"></i>
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />

            <div className="ml-md-5 ml-3">
              <PostComments
                comments={post?.comments}
                postId={post?.id}
                commentAdded={commentAdded}
                commentDeleted={commentDeleted}
                {...props}
              />
            </div>
          </div>
          <div
            className="col-lg-3 col-sm pt-5 pl-lg-5 pl-xl-5 pl-sm-0"
            style={{ marginTop: '55px' }}
          >
            <h6 className="metroBold">Suggested Topics</h6>
            <hr />

            {latestPosts?.length > 0 &&
              latestPosts.map(post => (
                <div key={post?.id} className="row">
                  <div className="col-sm-3 col-md-2 col-lg-2 col-xl-2">
                    {post.userPhotoUrl ? (
                      <img
                        className="rounded-circle"
                        src={post?.userPhotoUrl}
                        alt="profile pic"
                        style={{ width: '30px', height: '30px' }}
                      />
                    ) : (
                      <div
                        className="d-flex justify-content-center align-items-center avaterImageDiv"
                        style={{
                          height: '30px',
                          width: '30px',
                          backgroundColor:
                            colorArray[
                              Math.floor(Math.random() * colorArray.length)
                            ],
                        }}
                      >
                        <h5 className="mt-2 text-white font-weight-bold">
                          {post.userFirstname.substr(0, 1).toUpperCase()}
                        </h5>
                      </div>
                    )}
                  </div>
                  <div
                    className="col-sm-9 col-md-9 col-lg-9 col-xl-10"
                    style={{ fontFamily: 'metropolis-light' }}
                  >
                    <p
                      className="cursor-pointer"
                      onClick={() =>
                        history.push(`/community/posts/${post?.id}`)
                      }
                    >
                      {post.title}
                    </p>
                  </div>
                </div>
              ))}

            {latestPosts?.length === 0 && (
              <div>
                <p className="text-center">No Suggested posts</p>
              </div>
            )}
          </div>
        </div>
      ) : (
        <span>loading ....</span>
      )}
    </div>
  );
};

export default PostDetailsPage;
