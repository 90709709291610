import { Link } from 'react-router-dom';
import React from 'react';
import { compexDomain } from 'config';

const HomeBecomeInvestor = () => {
  return (
    <section className="container-fluid bg-graylight min-vh-70 py-5">
      <div className="container">
        <div className="row py-5  min-vh-70 min-vh-lg-40">
          <div className="col-md-6 align-items-center min-vh-40">
            <div className="bg-investor-portrait bg-position-center bg-position-md-top min-w-100 min-h-100"></div>
          </div>
          <div className="col-md-6 d-flex flex-wrap">
            <h2
              className="w-100"
              style={{ fontFamily: 'Metropolis-ExtraBold' }}
            >
              Invest in the next generation of businesses
            </h2>

            <div style={{ fontFamily: 'metropolis-light' }}>
              <p className=" w-100">
                Invest in the next generation of businesses and support the
                ecosystem in making businesses thrive beyond their capacity.
              </p>
              <p className=" w-100">
                Discover early stage, high potential startups seeking loan or
                equity investment. Sector ranging from Agriculture to Finance
                and Manufacturing, you'll find the right business to invest in.
              </p>
            </div>

            <a
              href={`${compexDomain}/investors`}
              className="btn btn-primary font-size-info rounded-5-px py-2 px-5 mt-auto"
              style={{ fontFamily: 'Metropolis-Medium' }}
            >
              Become an investor
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeBecomeInvestor;
