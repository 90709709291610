import React, { Component } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { connect } from 'react-redux';
import ContactStatupForm from './ContactStartupForm';
import userService from '../services/userService';
import StartupPaywall from './StartupPaywall';
import { ListingType } from '../utils/listingTypes';
import { addMessageListings } from '../../state/actions/user';

type Props = {
  sendTo: string;
  userId: number;
  addMessageListings: any;
  canSendMessage: boolean;
  messageToStartupListings: any;
  disable: any;
};

interface State {
  [selector: string]: any | State;
}

class ContactStartupBtn extends Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      listingToSend: '',
      listingTypeToSend: '',
      messageData: {
        investorID: 0,
        proposalTitle: '',
        proposal: '',
        businessID: 0,
        franchiseID: 0,
        startupID: 0,
        advisorID: 0,
      },
    };
    this.handleOnNameSelect = this.handleOnNameSelect.bind(this);
    this.handleOnTypeSelect = this.handleOnTypeSelect.bind(this);
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
    this.handleOnDone = this.handleOnDone.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
  }

  MySwal = withReactContent(Swal);

  handleOnTypeSelect(e: any) {
    let { messageData } = this.state;
    messageData = {
      ...messageData,
      startupID: this.state.messageData.startupID,
    };
    this.setState({
      messageData,
      listingTypeToSend: e.target.value,
    });
  }

  handleOnNameSelect(e: any) {
    let { messageData } = this.state;
    let listType = this.state.listingTypeToSend;

    messageData = {
      ...messageData,
      startupID: this.props.userId,
      advisorID: listType === 'Advisor' ? parseInt(e.target.value) : 0,
      investorID: listType === 'Investor' ? parseInt(e.target.value) : 0,
      businessID: listType === 'Business' ? parseInt(e.target.value) : 0,
      franchiseID: listType === 'Franchise' ? parseInt(e.target.value) : 0,
    };

    this.setState({
      listingToSend: e.target.value,
      messageData,
    });
  }

  handleOnSubmit(e: any) {
    e.preventDefault();
    const { messageData } = this.state;
    const res = userService.sendStartupMessage(messageData);
    return res;
  }

  handleOnDone() {
    this.MySwal.clickConfirm();
  }

  handleOnChange(e: any) {
    const { name, value } = e.target;
    let { messageData } = this.state;
    messageData[name] = value;
    this.setState({
      ...this.state,
      messageData,
    });
  }

  componentDidMount() {
    const { addMessageListings } = this.props;
    addMessageListings();
  }

  render() {
    const { canSendMessage } = this.props;
    /* prepare the options to display*/

    const allowedListings: { [key: string]: any } = {
      Business: [],
      Investor: [],
      Advisor: [],
      Franchise: [],
      Startup: [],
    };

    if (this.props.sendTo === ListingType.Startup) {
      delete allowedListings[ListingType.Startup];
      delete allowedListings[ListingType.Business];
      delete allowedListings[ListingType.Franchise];
      Object.keys(allowedListings).forEach(listingType => {
        allowedListings[
          listingType
        ] = this.props.messageToStartupListings.filter(
          (listing: any) => listing.profileType === listingType,
        );
      });
    }

    const getPaywall = () => {
      return <StartupPaywall></StartupPaywall>;
    };

    return (
      <div className="w-100">
        <button
          disabled={this.props.disable}
          className="btn p-2 py-2a btn-primary rounded-1 h5 w-100"
          onClick={() => {
            canSendMessage
              ? this.MySwal.fire({
                  customClass: {
                    popup: 'bg-white shadow w-lg-60 w-md-60 w-90',
                  },
                  showConfirmButton: false,
                  html: (
                    <ContactStatupForm
                      sendTo={this.props.sendTo}
                      allowedListings={allowedListings}
                      handleOnNameSelect={this.handleOnNameSelect}
                      handleOnTypeSelect={this.handleOnTypeSelect}
                      handleOnSubmit={this.handleOnSubmit}
                      handleOnChange={this.handleOnChange}
                    ></ContactStatupForm>
                  ),
                  showCloseButton: true,
                })
              : this.MySwal.fire({
                  customClass: {
                    popup: 'bg-white  ',
                  },
                  showConfirmButton: false,
                  showCloseButton: true,
                  html: getPaywall(),
                });
          }}
        >
          Contact Startup
        </button>
      </div>
    );
  }
}
export default connect(
  (state: any) => ({
    messageToStartupListings: state.user.messageToStartupListings,
  }),
  { addMessageListings },
)(ContactStartupBtn);
