import React, { Component } from 'react';
import { compexDomain } from 'config';

class StartupPaywall extends Component {
  state = {
    selected: 'investor',
  };

  addTick(profile: any) {
    this.setState({
      selected: profile,
    });
  }
  render() {
    return (
      <div className=" bg-white p-3 d-flex flex-column custom-paywall">
        <div className="text-center mb-5">
          <p className="font-weight-bold h5 mb-3">
            Create a listing to perform this action
          </p>
          <p className="h7 font-weight-normal">
            Kindly select a category and proceed
          </p>
        </div>
        <div className="d-flex px-5 flex-wrap justify-content-between">
          <button
            className="custom-relative w-md-40 w-lg-40
                    w-100 mb-5 p-4 shadow text-center bg-white border-0
                    rounded"
            onClick={() => {
              this.addTick('investor');
            }}
          >
            <div className="d-block mb-3">
              <i
                className={
                  'fas fa-check-circle ' +
                  (this.state.selected === 'investor'
                    ? 'text-primary'
                    : 'text-white')
                }
              ></i>
            </div>
            <div className="bg-investor-avatar mw-100 mx-auto"></div>
            <p className="text-primary small font-weight-bold">Investor</p>
          </button>
          <div className="w-100 d-lg-none d-md-none"></div>
          <button
            className="custom-relative w-md-40 w-lg-40
                    w-100 mb-5 p-4 shadow text-center bg-white border-0
                    rounded"
            onClick={() => {
              this.addTick('advisor');
            }}
          >
            <div className="d-block">
              <i
                className={
                  'fas fa-check-circle ' +
                  (this.state.selected === 'advisor'
                    ? 'text-primary'
                    : 'text-white')
                }
              ></i>
            </div>
            <div className="bg-advisor-avatar mw-100 mx-auto"></div>
            <p className="text-primary small font-weight-bold">
              Professional Advisor
            </p>
          </button>
          <div className="w-100 d-lg-none d-md-none"></div>
        </div>
        {this.state.selected === 'investor' ? (
          <a
            className="btn btn-primary btn-lg mx-auto"
            href={`${compexDomain}/register-investor/plans`}
          >
            Proceed
          </a>
        ) : (
          <a
            className="btn btn-primary btn-lg  mx-auto"
            href={`${compexDomain}/register-advisor/plans`}
          >
            Proceed
          </a>
        )}
      </div>
    );
  }
}

export default StartupPaywall;
