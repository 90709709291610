import React, { Component } from 'react';
import Joi from 'joi-browser';
import { CircularProgress } from '@material-ui/core';
import ContactSuccess from './ContactSuccess';
import ContactFail from './ContactFail';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

type Props = {
  sendTo: string;
  handleOnNameSelect: any;
  handleOnTypeSelect: any;
  handleOnSubmit: any;
  handleOnChange: any;
  allowedListings: any;
};

type State = {
  errors: any;
  contactData: {
    proposalTitle: string;
    proposal: string;
    businessID: number;
    franchiseID: number;
    advisorID: number;
    investorID: number;
    startupID: number;
    listingType: string;
  };
  loading: boolean;
};

class ContactStatupForm extends Component<Props, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      errors: {},
      contactData: {
        proposalTitle: '',
        proposal: '',
        businessID: 0,
        franchiseID: 0,
        advisorID: 0,
        investorID: 0,
        startupID: 0,
        listingType: '',
      },
      loading: false,
    };

    this.handleOnSubmit = this.handleOnSubmit.bind(this);
    this.handleOnDone = this.handleOnDone.bind(this);
  }

  MySwal = withReactContent(Swal);

  validate() {
    const { error } = Joi.validate(this.state.contactData, this.schema, {
      abortEarly: false,
    });
    if (!error) return null;
    const errors: any = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  }

  handleOnNameSelect(e: React.ChangeEvent<HTMLSelectElement>) {
    let listType = this.state.contactData.listingType;
    let { contactData } = { ...this.state };
    contactData = {
      ...contactData,
      businessID: listType === 'Business' ? parseInt(e.target.value) : 0,
      franchiseID: listType === 'Franchise' ? parseInt(e.target.value) : 0,
      advisorID: listType === 'Advisor' ? parseInt(e.target.value) : 0,
      investorID: listType === 'Investor' ? parseInt(e.target.value) : 0,
      startupID: listType === 'Startup' ? parseInt(e.target.value) : 0,
    };
    this.setState({
      contactData,
    });
    this.props.handleOnNameSelect(e);
  }

  handleOnTypeSelect(e: any) {
    let { contactData } = this.state;
    contactData = {
      ...contactData,
      listingType: e.target.value,
    };
    this.setState({
      contactData,
    });

    this.props.handleOnTypeSelect(e);
  }

  handleOnDone() {
    this.MySwal.clickConfirm();
  }

  handleOnSubmit(e: any) {
    e.preventDefault();
    this.setState({ loading: true });

    const errors = this.validate();
    if (errors) {
      this.setState({ loading: false });
      this.setState({ errors: errors || {} });
      return;
    }

    const res = this.props.handleOnSubmit(e);
    res.then(
      (mssg: any) => {
        switch (mssg) {
          case true:
            this.setState({ loading: false });
            this.MySwal.fire({
              showConfirmButton: false,
              html: (
                <ContactSuccess
                  handleOnDone={this.handleOnDone}
                ></ContactSuccess>
              ),
            }).then(() => {
              window.location.reload();
            });

            break;
          case false:
            this.setState({ loading: false });
            this.MySwal.fire({
              showConfirmButton: false,
              html: (
                <ContactFail handleOnDone={this.handleOnDone}></ContactFail>
              ),
            });

            break;
          default:
            this.setState({ loading: false });
            break;
        }
      },
      (reason: any) => {
        this.setState({ loading: false });
      },
    );
  }

  schema = {
    proposalTitle: Joi.string()
      .min(1)
      .max(100)
      .required()
      .label('Subject'),
    proposal: Joi.string()
      .min(1)
      .max(300)
      .required()
      .label('message'),
    listingType: Joi.string()
      .required()
      .label('Listing Type'),
    advisorID: Joi.number()
      .empty(0)
      .when('listingType', {
        is: 'Advisor',
        then: Joi.number()
          .required()
          .label('Advisor'),
      }),
    investorID: Joi.number()
      .empty(0)
      .when('listingType', {
        is: 'Investor',
        then: Joi.number()
          .required()
          .label('Investor'),
      }),
    businessID: Joi.number()
      .empty(0)
      .when('listingType', {
        is: 'Business',
        then: Joi.number()
          .required()
          .label('Business'),
      }),
    franchiseID: Joi.number()
      .empty(0)
      .when('listingType', {
        is: 'Franchise',
        then: Joi.number()
          .required()
          .label('Franchie'),
      }),
    startupID: Joi.number()
      .empty(0)
      .when('listingType', {
        is: 'Startup',
        then: Joi.number()
          .required()
          .label('Startup'),
      }),
  };
  handleOnChange(e: any) {
    const { name, value }: any = e.target;
    let { contactData }: any = { ...this.state };
    contactData[name] = value;
    this.setState({
      ...this.state,
      contactData,
    });
    this.props.handleOnChange(e);
  }

  render() {
    /*return the options selected*/

    const { allowedListings }: any = this.props;

    let listingTypes: any = [];
    Object.keys(allowedListings).forEach(listingType => {
      listingTypes.push(
        <option value={listingType} key={listingType}>
          {listingType}
        </option>,
      );
    });

    let listingNames: any = [];
    const key = this.state.contactData.listingType
      ? this.state.contactData.listingType
      : 'Investor';
    allowedListings[key].forEach((listing: { id: any; profileName: any }) => {
      listingNames.push(
        <option value={listing.id} key={listing.id}>
          {listing.profileName}
        </option>,
      );
    });

    return (
      <div className="h7 d-flex flex-column bg-white p-2 rounded-1">
        <div className="px-lg-5 px-md-5 text-left">
          <form
            onSubmit={e => {
              this.handleOnSubmit(e);
            }}
          >
            <div className="form-group">
              <label htmlFor="exampleFormControlInput1">Subject</label>
              <input
                type="text"
                name="proposalTitle"
                className={
                  this.state.errors['proposalTitle']
                    ? 'form-control is-invalid'
                    : 'form-control'
                }
                id="exampleFormControlInput1"
                placeholder="Enter a subject that captures the receiver's attention"
                onChange={e => {
                  this.handleOnChange(e);
                }}
              />
              <div className="invalid-feedback">
                {this.state.errors['proposalTitle']}
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="exampleFormControlTextarea1" className="h9">
                Type in your message here (Max: 300 characters)
              </label>
              <textarea
                name="proposal"
                className={
                  this.state.errors['proposal']
                    ? 'form-control is-invalid'
                    : 'form-control'
                }
                id="exampleFormControlTextarea1"
                rows={7}
                onChange={(e: any) => {
                  this.handleOnChange(e);
                }}
                wrap="hard"
              ></textarea>
              <div className="invalid-feedback">
                {this.state.errors['proposal']}
              </div>
            </div>
            <label htmlFor="selectListingToPropose">
              Select a listing to send
            </label>
            <div className="d-flex flex-wrap justify-content-between align-items-end">
              <div className="form-group w-md-30 w-100 mb-0 mb-2 mb-md-0">
                <select
                  className={
                    this.state.errors['listingType']
                      ? 'form-control is-invalid'
                      : 'form-control'
                  }
                  id="selectListingToPropose"
                  onChange={e => {
                    this.handleOnTypeSelect(e);
                  }}
                >
                  <option value="">Select type</option>
                  {listingTypes}
                </select>
                <div className="invalid-feedback">
                  {this.state.errors['listingType']}
                </div>
              </div>
              <div className="form-group w-md-30 w-100 mb-0 mb-2 mb-md-0">
                <select
                  className={
                    this.state.errors['advisorID']
                      ? 'form-control is-invalid'
                      : this.state.errors['investorID']
                      ? 'form-control is-invalid'
                      : this.state.errors['businessID']
                      ? 'form-control is-invalid'
                      : this.state.errors['franchiseID']
                      ? 'form-control is-invalid'
                      : this.state.errors['startupID']
                      ? 'form-control is-invalid'
                      : 'form-control'
                  }
                  onChange={e => {
                    this.handleOnNameSelect(e);
                  }}
                >
                  <option value={0}>Select name</option>
                  {listingNames}
                </select>
                <div className="invalid-feedback">
                  {this.state.errors['advisorID']
                    ? this.state.errors['advisorID']
                    : this.state.errors['investorID']
                    ? this.state.errors['investorID']
                    : this.state.errors['businessID']
                    ? this.state.errors['businessID']
                    : this.state.errors['startupID']
                    ? this.state.errors['startupID']
                    : this.state.errors['franchiseID']}
                </div>
              </div>
              <button
                type="submit"
                className="btn btn-primary w-md-30 w-100 mb-0 mb-2 mb-md-0"
                disabled={this.state.loading ? true : false}
              >
                {this.state.loading ? (
                  <CircularProgress color="inherit" size={'1rem'} />
                ) : (
                  'Send'
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default ContactStatupForm;
