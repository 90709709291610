import moment from 'moment';
import { compexCookieDomain } from '../../config';

// returns the cookie with the given name,
// or undefined if not found
export const getCookie = (name: string) => {
  //prettier-ignore
  //eslint-disable-next-line
  let matches = document.cookie.match(new RegExp("(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"));
  return matches ? decodeURIComponent(matches[1]) : undefined;
};

export const setCookie = (name: string, value: any, options: any = {}) => {
  console.log(compexCookieDomain);
  const expiryDate = moment(new Date())
    .add(12, 'h')
    .toDate();
  options = {
    path: '/',
    // add other defaults here if necessary
    domain: compexCookieDomain,
    expires: expiryDate,
    ...options,
  };

  if (options.expires instanceof Date) {
    options.expires = options.expires.toUTCString();
  }

  let updatedCookie =
    encodeURIComponent(name) + '=' + encodeURIComponent(value);

  for (let optionKey in options) {
    updatedCookie += '; ' + optionKey;
    let optionValue = options[optionKey];
    if (optionValue !== true) {
      updatedCookie += '=' + optionValue;
    }
  }

  document.cookie = updatedCookie;
};

export const deleteCookie = (name: string) => {
  setCookie(name, '', {
    'max-age': -1,
  });
};
