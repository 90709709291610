import React, { useRef, useState, useEffect, useCallback } from 'react';
import { useHistory, RouteComponentProps } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import queryString from 'query-string';
import withReactContent from 'sweetalert2-react-content';
import { useForm, Controller } from 'react-hook-form';
import {
  RegisterStartupDto,
  FileUpload,
} from 'common/interfaces/RegisterStartupDto';
import PhoneInput from 'react-phone-input-2';
import { CircularProgress } from '@material-ui/core';
import 'react-phone-input-2/lib/style.css';
import { Switch } from '@material-ui/core';
import { startupIndustries } from 'common/utils/startupIndustries';
import NumberFormat from 'react-number-format';
import { CountryInput, RegionInput } from 'common/components/LocationInput';
import startupsServices from 'common/services/startupsServices';
import subscriptionService from 'common/services/subscriptionService';
import Swal from 'sweetalert2';
import StartupPlans from './StartupPlans';
import PaymentModal from 'common/components/PaymentModal';
import { spacePascalWord } from 'common/utils/helpers';
import { SET_USER_STARTUPID } from 'state/actions/types';
import useScript from 'common/utils/useScript';
import startupPlanTypes from 'common/utils/startupPlanTypes';

export interface RegisterStartupProps extends RouteComponentProps<any> {}

const RegisterStartup = (props: RegisterStartupProps) => {
  useScript('https://api.ravepay.co/flwv3-pug/getpaidx/api/flwpbf-inline.js');
  const {
    register,
    handleSubmit,
    errors,
    control,
    watch,
    setValue,
    getValues,
  } = useForm<RegisterStartupDto>();

  const history = useHistory();
  const dispatch = useDispatch();

  const userStartupId = useSelector((state: any) => state.user.userStartupId);

  const [startup, setStartup] = useState<RegisterStartupDto>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const locationCountry = useRef('');
  locationCountry.current = watch('locationCountry', '');
  const locationState = useRef('');
  locationState.current = watch('locationState', '');
  const incorporationCountry = useRef('');
  incorporationCountry.current = watch('incorporationCountry', '');
  const [clearRegion, setClearRegion] = useState(false);
  let hasActiveInvestor: string = String(watch('hasActiveInvestors', 'false'));
  const [salesModel, setSalesModel] = useState<Array<string>>([]);
  const [salesModelError, setSalesModelError] = useState(false);
  const [agreed, setAgreed] = useState(false);
  const [shareholdersTableFile, setShareholdersTableFile] = useState<
    Array<FileUpload>
  >([]);
  const [incorporationDocsFile, setIncorporationDocsFile] = useState<
    Array<FileUpload>
  >([]);
  const [pitchDeckFile, setPitchDeckFile] = useState<Array<FileUpload>>([]);
  const [proofOfIDsFile, setProofOfIDsFile] = useState<Array<FileUpload>>([]);
  const [productFlyerFile, setProductFlyerFile] = useState<Array<FileUpload>>(
    [],
  );
  const [showDocs, setShowDocs] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState('');
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);
  const [hasSubscribed, setHasSubscribed] = useState(false);
  const [planError, setPlanError] = useState(false);
  const [isValidating, setIsValidating] = useState(false);
  const [isValidCode, setIsValidCode] = useState<boolean>();
  const [couponCode, setCouponCode] = useState('');

  let prevSelectedPlan = useSelector(
    (state: any) => state.startup.selectedPlan,
  );
  const user = useSelector((state: any) => state.user.currentUser);

  const autofillForm = (data: any) => {
    setStartup(data);
    for (let [key, value] of Object.entries(data)) {
      if (value !== null) setValue(key, value);
    }

    setShowDocs(data.showDocs);

    const dbSalesModel: string = data?.salesModel;
    if (dbSalesModel) {
      const sales = dbSalesModel.replace(/,/g, '').split(' ');
      setSalesModel(sales);
    }

    if (data.files && data.files.length > 0) {
      const { files } = data;

      files.forEach((file: FileUpload) => {
        if (file.category === 'shareholdersTableFile')
          setShareholdersTableFile([file]);
        if (file.category === 'incorporationDocsFile')
          setIncorporationDocsFile([file]);
        if (file.category === 'pitchDeckFile') setPitchDeckFile([file]);
        if (file.category === 'proofOfIDsFile') setProofOfIDsFile([file]);
        if (file.category === 'productFlyerFile') setProductFlyerFile([file]);
      });
    }

    const { subscriptions } = data;
    if (subscriptions && subscriptions.length > 0) {
      setSelectedPlan(subscriptions[0].planType);
      setHasSubscribed(true);
    } else {
      setSelectedPlan(data?.selectedPlan);
    }
  };

  const autofillUserDetails = () => {
    const { email, firstname, lastname, phone } = user;
    setValue('firstName', firstname);
    setValue('lastName', lastname);
    setValue('email', email);
    setValue('phone', phone);
  };

  useEffect(() => {
    const { id: paramId } = queryString.parse(props.location.search);
    const id = Number(paramId);
    if (id && !startup) {
      // fetch startup with id and autofill form
      (async () => {
        const res: any = await startupsServices.getStartupDetails(id, true);
        if (res && res.data) {
          autofillForm(res.data);
        }
      })();
    } else {
      if (userStartupId) {
        alertHaveStartup();
      }

      if (user?.email) autofillUserDetails();
    }

    (async () => {
      const res = await subscriptionService.getSubscriptionPlans();
      if (res) {
        setSubscriptionPlans(res);
      }
    })();

    setSelectedPlan(prevSelectedPlan);
    setShowPlans(prevSelectedPlan ? false : true);
  }, [props]);

  const alertHaveStartup = () => {
    Swal.fire(
      'You have already registered a startup',
      'You can only register one startup, click ok to view your startup',
    ).then(() => {
      props.history.push('/user/startup');
    });
  };

  const selectLocationCountry = (val: string, clearRegion?: boolean) => {
    locationCountry.current = val;
    if (clearRegion) {
      locationCountry.current = '';
      setClearRegion(true);
    }
  };

  const selectLocationState = (val: string) => {
    locationState.current = val;
  };

  const selectIncorporationCountry = (val: string) => {
    incorporationCountry.current = val;
  };

  const handleSalesModelSelect = (e: any) => {
    const { checked, value } = e.target;
    if (checked) {
      setSalesModel([...salesModel, value]);
      setSalesModelError(false);
    } else {
      const filtered = salesModel.filter(x => x !== value);
      setSalesModel([...filtered]);
    }
  };

  const switchShowDocs = (e: any) => {
    const { checked } = e.target;
    setShowDocs(checked);
  };

  const handleFileUpload = (e: any) => {
    const file = e.target.files[0];
    const { name: controlName } = e.target;

    if (file && file.size <= 2000000) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadstart = () => {
        console.log('reading file ...');
      };
      reader.onloadend = () => {
        const result: string = String(reader.result);
        const base64Url = result.split(',')[1];
        if (base64Url) {
          const newFile: FileUpload = {
            name: file.name,
            type: file.type,
            size: file.size,
            fileUrl: result,
            category: controlName,
          };

          if (controlName === 'shareholdersTableFile') {
            setShareholdersTableFile([newFile]);
          } else if (controlName === 'incorporationDocsFile') {
            setIncorporationDocsFile([newFile]);
          } else if (controlName === 'pitchDeckFile') {
            setPitchDeckFile([newFile]);
          } else if (controlName === 'proofOfIDsFile') {
            setProofOfIDsFile([newFile]);
          } else if (controlName === 'productFlyerFile') {
            setProductFlyerFile([newFile]);
          }
        }
      };
    } else {
      Swal.fire('Error', 'File is too large', 'error');
    }
  };

  const deleteShareHoldersTableFile = () => {
    setShareholdersTableFile([]);
  };

  const deleteIncorporationDocsFile = () => {
    setIncorporationDocsFile([]);
  };

  const deletePitchDeckFile = () => {
    setPitchDeckFile([]);
  };

  const deleteProofOfIDsFile = () => {
    setProofOfIDsFile([]);
  };

  const deleteProductFlyerFile = () => {
    setProductFlyerFile([]);
  };

  const onSelectPlan = (plan: string) => {
    if (hasSubscribed) {
      upgradePlan(plan);
    } else {
      setSelectedPlan(plan);
    }
  };

  const upgradePlan = async (plan: string) => {
    var dto = getValues();

    await saveChanges(dto);

    promptPayment(plan, Number(startup?.id), String(startup?.email), true);
  };

  const [showPlans, setShowPlans] = useState(false);

  const toggleShowPlans = () => {
    setShowPlans(!showPlans);
  };

  const MySwal: any = withReactContent(Swal);

  const promptPayment = (
    selectedPlan: string,
    startupId: number,
    workEmail: string,
    isUpgrading = false,
  ) => {
    const plans: Array<any> = [...subscriptionPlans];
    const plan = plans.find(x => x.planType === selectedPlan);

    const { email, phone } = user;
    const successMessage = isUpgrading
      ? 'Subscription was successful and your changes was saved'
      : email !== workEmail
      ? `A verification link has been sent to ${workEmail}. You must confirm your email before your listing will be approved.`
      : 'Your request has been sent for approval';

    if (email && plan && startupId) {
      MySwal.fire({
        customClass: 'bg-transparent w-lg-30 w-md-30 w-90',
        showConfirmButton: false,
        html: (
          <PaymentModal
            planId={plan.planId}
            amount={plan.amount}
            userEmail={email}
            userPhone={phone}
            listingId={startupId}
            listingType="Startup"
            redirectTo={`startups/${startupId}`}
            successMessage={successMessage}
          ></PaymentModal>
        ),
      });
    }
  };

  const validateCouponCode = async (e: any) => {
    if (couponCode) {
      // validate coupon code
      setIsValidating(true);
      const res = await subscriptionService.validateCouponCode(
        couponCode,
        'Startup',
      );
      if (res) {
        setSelectedPlan(startupPlanTypes.coupon);
        setIsValidCode(true);
        setIsValidating(false);
      } else {
        setSelectedPlan('');
        setIsValidCode(false);
        setIsValidating(false);
      }
    }
  };

  const onSubmit = async (values: RegisterStartupDto) => {
    const dto = getDto(values);

    if (dto.salesModel.length === 0) {
      setSalesModelError(true);
      Swal.fire(
        'Error',
        'One or more required field is empty. Please complete it before submitting',
        'error',
      );
      return;
    }

    if (!selectedPlan) {
      setPlanError(true);
      Swal.fire(
        'Error',
        'You must select a plan or enter a valid subscription code before submitting',
        'error',
      );
      return;
    }

    try {
      setIsSubmitting(true);
      const res = await startupsServices.registerStartup(dto);

      if (res && res.id) {
        setIsSubmitting(false);
        if (hasSubscribed) {
          Swal.fire('Success', 'Changes saved successfully', 'success').then(
            () => {
              history.push('/user/startup');
            },
          );
        } else if (selectedPlan === startupPlanTypes.coupon) {
          Swal.fire(
            'Startup listing registered successfully',
            'Your request has been sent for approval',
            'success',
          ).then(() => {
            history.replace(`/user/startup`);
          });
        } else {
          promptPayment(selectedPlan, res.id, res.email);
        }
      } else {
        setIsSubmitting(false);
        Swal.fire('Error', 'Unable to register startup', 'error');
      }
    } catch (error) {
      setIsSubmitting(false);
    }
  };

  const saveAndExit = async (values: RegisterStartupDto) => {
    const dto = getDto(values);

    try {
      setIsSaving(true);
      const res = await startupsServices.registerStartup(dto);

      if (res && res.id) {
        setIsSaving(false);
        dispatch({ type: SET_USER_STARTUPID, payload: res.id });
        Swal.fire('Success', 'Changes saved successfully', 'success').then(
          () => {
            history.push(`/startups/${res.id}`);
          },
        );
      } else {
        setIsSaving(false);
        Swal.fire('Error', 'Unable to save changes', 'error');
      }
    } catch (error) {
      setIsSaving(false);
      const { response } = error;

      if (response && response.data && response.data.title) {
        let message = response.data.title;
        if (message.includes('You already have a startup')) {
          alertHaveStartup();
        }
      }
    }
  };

  const saveChanges = async (values: RegisterStartupDto) => {
    const dto = getDto(values);

    try {
      setIsSaving(true);
      const res = await startupsServices.registerStartup(dto);

      if (res && res.id) {
        setIsSaving(false);
        dispatch({ type: SET_USER_STARTUPID, payload: res.id });
      } else {
        setIsSaving(false);
        Swal.fire('Error', 'Unable to save changes', 'error');
      }
    } catch (error) {
      setIsSaving(false);
      const { response } = error;

      if (response && response.data && response.data.title) {
        let message = response.data.title;
        if (message.includes('You already have a startup')) {
          alertHaveStartup();
        }
      }
    }
  };

  const getDto = (dto: RegisterStartupDto) => {
    dto.salesModel = salesModel;
    dto.selectedPlan = selectedPlan;
    dto.shareholdersTable = shareholdersTableFile;
    dto.incorporationDocs = incorporationDocsFile;
    dto.pitchDeck = pitchDeckFile;
    dto.proofOfIDs = proofOfIDsFile;
    dto.productFlyer = productFlyerFile;
    dto.showDocs = showDocs;
    dto.yearOfEst = dto.yearOfEst || 0;
    dto.hasActiveInvestors = !!dto.hasActiveInvestors;
    dto.numActiveCustomers = dto.numActiveCustomers || 0;

    dto.id = (startup && startup.id) || 0;
    dto.selectedPlan = dto.selectedPlan || 'None';
    dto.agreedToTerms = dto.agreedToTerms || false;
    dto.couponCode = couponCode;

    return dto;
  };

  return (
    <div className="row justify-content-center p-2 p-md-5">
      <div className="col-md-10 bg-white shadow rounded small p-2 p-md-5">
        <div className="text-center">
          <h4 className="text-primary">Register your Startup</h4>
          <div className="text- small">
            Kindly fill in the details of your startup as accurate as possible
          </div>
        </div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          method="POST"
          noValidate
          className="my-5"
        >
          <div className="form-row p-3">
            <div className="form-group col-md-6 px-md-3 py-2">
              <label htmlFor="startupName">
                Name of your startup{' '}
                <span className="text-danger text-small">*</span>
              </label>
              <input
                type="text"
                name="startupName"
                ref={register({ required: true })}
                className={
                  errors.startupName
                    ? 'form-control is-invalid'
                    : 'form-control'
                }
              />
              <div className="invalid-feedback">
                {errors.startupName && 'Startup name is required'}
              </div>
            </div>

            <div className="form-group col-md-6 px-md-3 py-2">
              <label htmlFor="industry">
                Which of these industries best describe your startup{' '}
                <span className="text-danger text-small">*</span>
              </label>
              <select
                name="industry"
                ref={register({ required: true })}
                className={
                  errors.industry ? 'form-control is-invalid' : 'form-control'
                }
              >
                <option value="">select industry</option>
                {startupIndustries.map((industry, index) => (
                  <option value={industry.name} key={index}>
                    {industry.name}
                  </option>
                ))}
              </select>
              <div className="invalid-feedback">
                {errors.industry && 'Industry is required'}
              </div>
            </div>

            <div className="form-group col-md-6 px-md-3 py-2">
              <label htmlFor="website">Your startup web address</label>{' '}
              <span className="text-danger text-small">*</span>
              <input
                type="text"
                name="website"
                placeholder="e.g www.compexafrica.com"
                ref={register({ required: true })}
                className={
                  errors.website ? 'form-control is-invalid' : 'form-control'
                }
              />
              <div className="invalid-feedback">
                {errors.website && 'Web address is required'}
              </div>
            </div>

            <div className="form-group col-md-6 px-md-3 py-2">
              <label htmlFor="shortDescription">
                Describe your startup{' '}
                <small>(tweet style - 280 characters or less)</small>{' '}
                <span className="text-danger text-small">*</span>
              </label>
              <input
                type="text"
                name="shortDescription"
                ref={register({ required: true, max: 280 })}
                className={
                  errors.shortDescription
                    ? 'form-control is-invalid'
                    : 'form-control'
                }
              />
              <div className="invalid-feedback">
                {errors.shortDescription && 'Short description is required'}
              </div>
            </div>

            <div className="form-group col-md-6 px-md-3 py-2">
              <label htmlFor="detailDescription">
                Describe your startup, full length.{' '}
                <small>
                  (Go all in, talk about your features and selling points)
                </small>{' '}
                <span className="text-danger text-small">*</span>
              </label>
              <textarea
                name="detailDescription"
                ref={register({ required: true, max: 2000 })}
                rows={3}
                className={
                  errors.detailDescription
                    ? 'form-control is-invalid'
                    : 'form-control'
                }
              />
              <div className="invalid-feedback">
                {errors.detailDescription && 'Detail description is required'}
              </div>
            </div>

            <div className="form-group col-md-6 px-md-3 py-2">
              <label htmlFor="investmentType">
                What type of investment are you looking for?{' '}
                <span className="text-danger text-small">*</span>
              </label>
              <select
                name="investmentType"
                ref={register({ required: true })}
                className={
                  errors.investmentType
                    ? 'form-control is-invalid'
                    : 'form-control'
                }
              >
                <option value="">select an option</option>
                <option value="Loan">Loan</option>
                <option value="Equity">Equity</option>
              </select>
              <div className="invalid-feedback">
                {errors.investmentType && 'Investment type is required'}
              </div>
            </div>

            <div className="form-group col-md-6 px-md-3 py-2">
              <label htmlFor="reason">
                Reason for seeking investment{' '}
                <span className="text-danger text-small">*</span>
              </label>
              <select
                name="reason"
                ref={register({ required: true })}
                className={
                  errors.reason ? 'form-control is-invalid' : 'form-control'
                }
              >
                <option value="">select an option</option>
                <option value="to expand">Improve technology</option>
                <option value="to expand">Market research</option>
                <option value="to expand">Legal advice</option>
                <option value="to expand">Professional advice</option>
                <option value="to expand">Technical support</option>
                <option value="to expand">Marketing</option>
                <option value="to expand">Other</option>
              </select>
              <div className="invalid-feedback">
                {errors.reason && 'Reason is required'}
              </div>
            </div>

            <div className="form-group col-md-6 px-md-3 py-2">
              <label htmlFor="yearOfEst">
                Year of establishment{' '}
                <span className="text-danger text-small">*</span>
              </label>
              <input
                type="number"
                name="yearOfEst"
                ref={register({ required: true, min: 1970, max: 2020 })}
                className={
                  errors.yearOfEst ? 'form-control is-invalid' : 'form-control'
                }
              />
              <div className="invalid-feedback">
                {errors.yearOfEst && 'Valid Year is required'}
              </div>
            </div>

            <div className="form-group col-md-6 px-md-3 py-2">
              <label htmlFor="socialMedia">
                Link to social media profile{' '}
                <span className="text-danger text-small">*</span>
              </label>
              <input
                type="text"
                name="socialMedia"
                ref={register({ required: true, max: 100 })}
                className={
                  errors.socialMedia
                    ? 'form-control is-invalid'
                    : 'form-control'
                }
              />
              <div className="invalid-feedback">
                {errors.socialMedia && 'Social media link is required'}
              </div>
            </div>

            <div className="form-group col-md-6 px-md-3 py-2">
              <label htmlFor="hasActiveInvestors">
                Any active investor(s)?{' '}
                <span className="text-danger text-small">*</span>
              </label>
              <select
                name="hasActiveInvestors"
                ref={register({ required: true })}
                className={
                  errors.hasActiveInvestors
                    ? 'form-control is-invalid'
                    : 'form-control'
                }
              >
                <option value="">select an option</option>
                <option value="true">Yes</option>
                <option value="false">No</option>
              </select>
              <div className="invalid-feedback">
                {errors.hasActiveInvestors && 'Active investor/s is required'}
              </div>
            </div>

            <div className="form-group col-md-6 px-md-3 py-2">
              <label htmlFor="equityHolding" className="text-muted">
                Equity holding (%)
              </label>
              <Controller
                name="equityHolding"
                control={control}
                rules={{
                  required: hasActiveInvestor === 'true' ? true : false,
                }}
                as={
                  <NumberFormat
                    thousandSeparator={true}
                    type="text"
                    className={
                      errors.equityHolding
                        ? 'form-control is-invalid'
                        : 'form-control'
                    }
                    disabled={hasActiveInvestor === 'false' ? true : false}
                  />
                }
              />
            </div>

            <div className="form-group col-md-6 px-md-3 py-2">
              <label htmlFor="firstName">
                Primary contact's first name{' '}
                <span className="text-danger text-small">*</span>
              </label>
              <input
                type="text"
                name="firstName"
                ref={register({ required: true, max: 50 })}
                className={
                  errors.firstName ? 'form-control is-invalid' : 'form-control'
                }
              />
              <div className="invalid-feedback">
                {errors.firstName && 'First name is required'}
              </div>
            </div>

            <div className="form-group col-md-6 px-md-3 py-2">
              <label htmlFor="lastName">
                Primary contact's last name{' '}
                <span className="text-danger text-small">*</span>
              </label>
              <input
                type="text"
                name="lastName"
                ref={register({ required: true, max: 50 })}
                className={
                  errors.lastName ? 'form-control is-invalid' : 'form-control'
                }
              />
              <div className="invalid-feedback">
                {errors.lastName && 'Last name is required'}
              </div>
            </div>

            <div className="form-group col-md-6 px-md-3 py-2">
              <label htmlFor="email">
                Primary contact's email address{' '}
                <span className="text-danger text-small">*</span>
              </label>
              <input
                type="email"
                name="email"
                placeholder="please use your company email address, not your personal one"
                ref={register({
                  required: 'Required',
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: 'invalid email address',
                  },
                })}
                className={
                  errors.email ? 'form-control is-invalid' : 'form-control'
                }
              />
              <div className="invalid-feedback">
                {errors.email && 'Valid email is required'}
              </div>
            </div>

            <div className="form-group col-md-6 px-md-4">
              <label htmlFor="phone">
                Primary contact's phone number{' '}
                <span className="text-danger text-small">*</span>
              </label>
              <Controller
                as={PhoneInput}
                type="text"
                name="phone"
                control={control}
                rules={{ required: true, minLength: 9 }}
                country={'ng'}
                inputClass={
                  errors.phone
                    ? 'form-control w-100 is-invalid'
                    : 'form-control w-100'
                }
              />
              <div className={errors.phone ? 'is-invalid' : ''}></div>
              <div className="invalid-feedback">
                {errors.phone &&
                  errors.phone.type === 'required' &&
                  'Phone number is required'}
                {errors.phone &&
                  errors.phone.type === 'minLength' &&
                  'Phone number of minimum length 6'}
              </div>
            </div>

            <div className="form-group col-md-6 px-md-3 py-2">
              <label htmlFor="locationCountry">
                Where is your startup physically located?{' '}
                <span className="text-danger text-small">*</span>
              </label>
              <Controller
                name="locationCountry"
                control={control}
                rules={{ required: true }}
                as={
                  <CountryInput
                    onChange={selectLocationCountry}
                    className={
                      errors.locationCountry
                        ? 'form-control is-invalid'
                        : 'form-control'
                    }
                    value={locationCountry.current}
                    id="locationCountry"
                  />
                }
              />
              <div className="invalid-feedback">
                {errors.locationCountry && 'Country is required'}
              </div>

              <Controller
                name="locationState"
                control={control}
                rules={{ required: true }}
                as={
                  <RegionInput
                    country={locationCountry.current}
                    onChange={selectLocationState}
                    clearRegions={clearRegion}
                    id="registeredRegion"
                    className={
                      errors.locationState
                        ? 'form-control is-invalid'
                        : 'form-control'
                    }
                    value={locationState.current}
                  />
                }
              />
              <div className="invalid-feedback">
                {errors.locationState && 'State is required'}
              </div>
            </div>

            <div className="form-group col-md-6 px-md-3 py-2">
              <label htmlFor="incorporationCountry">
                If different, in which country is your startup incorporated?
              </label>

              <Controller
                name="incorporationCountry"
                control={control}
                as={
                  <CountryInput
                    onChange={selectIncorporationCountry}
                    className={
                      errors.incorporationCountry
                        ? 'form-control is-invalid'
                        : 'form-control'
                    }
                    value={incorporationCountry.current}
                    id="incorporationCountry"
                  />
                }
              />

              <div className="invalid-feedback">
                {errors.incorporationCountry && 'Country is required'}
              </div>
            </div>

            <div className="form-group col-md-6 px-md-3 py-2">
              <label htmlFor="hasLaunched">
                Has your product launched?{' '}
                <span className="text-danger text-small">*</span>
              </label>
              <select
                name="hasLaunched"
                ref={register({ required: true })}
                className={
                  errors.hasLaunched
                    ? 'form-control is-invalid'
                    : 'form-control'
                }
              >
                <option value="">select option</option>
                <option value="Yes">Yes, it's public</option>
                <option value="public beta">Yes, it's public beta</option>
                <option value="private beta">Yes, it's private beta</option>
                <option value="No">No</option>
              </select>
              <div className="invalid-feedback">
                {errors.hasLaunched && 'Country is required'}
              </div>
            </div>

            <div className="form-group col-md-6 px-md-3 py-2">
              <label htmlFor="salesModel">
                What's your sales model{' '}
                <span className="text-danger text-small">*</span>
              </label>
              <div className={errors.salesModel ? 'row is-invalid' : 'row'}>
                <div className="form-group form-check mx-3">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="b2b"
                    value="b2b"
                    name="b2b"
                    onChange={handleSalesModelSelect}
                    checked={salesModel.includes('b2b')}
                  />
                  <label className="form-check-label" htmlFor="b2b">
                    B2B
                  </label>
                </div>
                <div className="form-group form-check mx-3">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="b2c"
                    value="b2c"
                    name="b2c"
                    onChange={handleSalesModelSelect}
                    checked={salesModel.includes('b2c')}
                  />
                  <label className="form-check-label" htmlFor="b2c">
                    B2C
                  </label>
                </div>
                <div className="form-group form-check mx-3">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="b2g"
                    value="b2g"
                    name="b2g"
                    onChange={handleSalesModelSelect}
                    checked={salesModel.includes('b2g')}
                  />
                  <label className="form-check-label" htmlFor="b2g">
                    B2G
                  </label>
                </div>
                <div className="form-group form-check mx-3">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="c2c"
                    value="c2c"
                    name="c2c"
                    onChange={handleSalesModelSelect}
                    checked={salesModel.includes('c2c')}
                  />
                  <label className="form-check-label" htmlFor="c2c">
                    C2C
                  </label>
                </div>
                <div className="form-group form-check mx-3">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="others"
                    value="others"
                    name="others"
                    onChange={handleSalesModelSelect}
                    checked={salesModel.includes('others')}
                  />
                  <label className="form-check-label" htmlFor="others">
                    Others/not sure
                  </label>
                </div>
              </div>

              <div className="text-danger">
                {salesModelError && 'Select one or more sales model'}
              </div>
            </div>

            <div className="form-group col-md-6 px-md-3 py-2">
              <label htmlFor="investmentNeeded">
                How much investment do you need? (₦){' '}
                <span className="text-danger text-small">*</span>
              </label>
              <Controller
                name="investmentNeeded"
                control={control}
                rules={{ required: true }}
                as={
                  <NumberFormat
                    thousandSeparator={true}
                    type="text"
                    className={
                      errors.investmentNeeded
                        ? 'form-control is-invalid'
                        : 'form-control'
                    }
                  />
                }
              />
              <div className="invalid-feedback">
                {errors.investmentNeeded && 'Field is required'}
              </div>
            </div>

            <div className="form-group col-md-6 px-md-3 py-2">
              <label htmlFor="lastMonthRevenue">
                How much revenue did you do last month? (₦){' '}
                <span className="text-danger text-small">*</span>
              </label>
              <Controller
                name="lastMonthRevenue"
                control={control}
                rules={{ required: true }}
                as={
                  <NumberFormat
                    thousandSeparator={true}
                    type="text"
                    className={
                      errors.lastMonthRevenue
                        ? 'form-control is-invalid'
                        : 'form-control'
                    }
                  />
                }
              />
              <div className="invalid-feedback">
                {errors.lastMonthRevenue && 'Field is required'}
              </div>
            </div>

            <div className="form-group col-md-6 px-md-3 py-2">
              <label htmlFor="lastTwoMonthsRevenue">
                How much revenue did you do last two month? (₦){' '}
                <span className="text-danger text-small">*</span>
              </label>
              <Controller
                name="lastTwoMonthsRevenue"
                control={control}
                rules={{ required: true }}
                as={
                  <NumberFormat
                    thousandSeparator={true}
                    type="text"
                    className={
                      errors.lastTwoMonthsRevenue
                        ? 'form-control is-invalid'
                        : 'form-control'
                    }
                  />
                }
              />
              <div className="invalid-feedback">
                {errors.lastTwoMonthsRevenue && 'Field is required'}
              </div>
            </div>

            <div className="form-group col-md-6 px-md-3 py-2">
              <label htmlFor="currentCost">
                What is your current cost? (₦){' '}
                <span className="text-danger text-small">*</span>
              </label>
              <Controller
                name="currentCost"
                control={control}
                rules={{ required: true }}
                as={
                  <NumberFormat
                    thousandSeparator={true}
                    type="text"
                    className={
                      errors.currentCost
                        ? 'form-control is-invalid'
                        : 'form-control'
                    }
                  />
                }
              />
              <div className="invalid-feedback">
                {errors.currentCost && 'Field is required'}
              </div>
            </div>

            <div className="form-group col-md-6 px-md-3 py-2">
              <label htmlFor="projectedMonthlyRevenue">
                Projected monthly revenue (₦){' '}
                <span className="text-danger text-small">*</span>
              </label>
              <Controller
                name="projectedMonthlyRevenue"
                control={control}
                rules={{ required: true }}
                as={
                  <NumberFormat
                    thousandSeparator={true}
                    type="text"
                    className={
                      errors.projectedMonthlyRevenue
                        ? 'form-control is-invalid'
                        : 'form-control'
                    }
                  />
                }
              />
              <div className="invalid-feedback">
                {errors.projectedMonthlyRevenue && 'Field is required'}
              </div>
            </div>

            <div className="form-group col-md-6 px-md-3 py-2">
              <label htmlFor="numActiveCustomers">
                How many active customers do you have?{' '}
                <span className="text-danger text-small">*</span>
              </label>
              <input
                type="number"
                name="numActiveCustomers"
                placeholder=""
                ref={register({ required: true })}
                className={
                  errors.numActiveCustomers
                    ? 'form-control is-invalid'
                    : 'form-control'
                }
              />
              <div className="invalid-feedback">
                {errors.numActiveCustomers && 'Field is required'}
              </div>
            </div>

            <div className="form-group col-md-6 px-md-3 py-2">
              <label htmlFor="allCofounders">
                Please list all co-founders along with equity ownership (%) and
                links to LinkedIn profile for each including yourself{' '}
                <span className="text-danger text-small">*</span>
              </label>
              <textarea
                name="allCofounders"
                ref={register({ required: true, max: 2000 })}
                rows={2}
                className={
                  errors.allCofounders
                    ? 'form-control is-invalid'
                    : 'form-control'
                }
              />
              <div className="invalid-feedback">
                {errors.allCofounders && 'Detail description is required'}
              </div>
            </div>

            <div className="form-group col-md-6 px-md-3 py-2">
              <label htmlFor="customerGrowthChannel">
                What channel(s) or tool(s) are currently fueling your customer
                growth? <span className="text-danger text-small">*</span>
              </label>
              <textarea
                name="customerGrowthChannel"
                ref={register({ required: true, max: 2000 })}
                rows={2}
                className={
                  errors.customerGrowthChannel
                    ? 'form-control is-invalid'
                    : 'form-control'
                }
              />
              <div className="invalid-feedback">
                {errors.customerGrowthChannel &&
                  'Detail description is required'}
              </div>
            </div>

            <div className="form-group col-md-6 px-md-3 py-2">
              <label htmlFor="videoUrl">
                Please provide a link to your pitch video here
                <em className="small">
                  (this would be displayed to the public)
                </em>{' '}
                <span className="text-danger text-small">*</span>
              </label>
              <input
                type="text"
                name="videoUrl"
                placeholder=""
                ref={register({ required: true })}
                className={
                  errors.videoUrl ? 'form-control is-invalid' : 'form-control'
                }
              />
              <div className="invalid-feedback">
                {errors.videoUrl && 'Field is required'}
              </div>
            </div>

            <div className="form-group col-md-6 px-md-3 py-2">
              <label htmlFor="otherInfo">
                Any thing else you want to tell us?{' '}
                <span className="text-danger text-small">*</span>
              </label>
              <input
                type="text"
                name="otherInfo"
                placeholder=""
                ref={register({ required: true })}
                className={
                  errors.otherInfo ? 'form-control is-invalid' : 'form-control'
                }
              />
              <div className="invalid-feedback">
                {errors.otherInfo && 'Field is required'}
              </div>
            </div>

            <div className="form-group col-md-6 px-md-3 py-2">
              <label htmlFor="howYouHeardAboutUs">
                Has do you hear about us?{' '}
                <span className="text-danger text-small">*</span>
              </label>
              <select
                name="howYouHeardAboutUs"
                ref={register({ required: true })}
                className={
                  errors.howYouHeardAboutUs
                    ? 'form-control is-invalid'
                    : 'form-control'
                }
              >
                <option value="">select option</option>
                <option value="Facebook">Facebook</option>
                <option value="Instagram">Instagram</option>
                <option value="Twitter">Twitter</option>
                <option value="LinkedIn">LinkedIn</option>
                <option value="Newspaper">Newspaper</option>
                <option value="Google Search">Google Search</option>
                <option value="Friend/Family">Friend/Family</option>
                <option value="Other">Other</option>
              </select>
              <div className="invalid-feedback">
                {errors.howYouHeardAboutUs && 'Country is required'}
              </div>
            </div>

            <div className="form-group col-12 px-md-3 pt-2">
              <h6>
                Upload documents{' '}
                <span className="small">(Max file size 2MB)</span>{' '}
              </h6>
              <div className="row">
                <div className="form-group col-md-6 px-md-4">
                  <label>Please upload your latest shareholder's table</label>
                  <div>
                    <div className="upload-btn-wrapper">
                      <button type="button" className="btn btn-outline-primary">
                        Choose file
                      </button>
                      <input
                        type="file"
                        name="shareholdersTableFile"
                        onChange={handleFileUpload}
                      />
                    </div>

                    <div>
                      {shareholdersTableFile.map(file => (
                        <div className="mb-2" key={file.name}>
                          {file.type === 'application/pdf' ? (
                            <i className="fas fa-file-pdf fa-2x pr-2 text-danger"></i>
                          ) : (
                            <i className="far fa-file-image fa-2x pr-2 text-info"></i>
                          )}
                          <span className="small pr-3">
                            <a
                              href={file.fileUrl}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {file.name}
                            </a>
                          </span>
                          <i
                            className="far fa-times-circle text-danger cursor-pointer"
                            onClick={deleteShareHoldersTableFile}
                          ></i>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                <div className="form-group col-md-6 px-md-4">
                  <label>Please upload your incorporation document here </label>
                  <div>
                    <div className="upload-btn-wrapper">
                      <button type="button" className="btn btn-outline-primary">
                        Choose file
                      </button>
                      <input
                        type="file"
                        name="incorporationDocsFile"
                        onChange={handleFileUpload}
                      />
                    </div>
                    <div>
                      {incorporationDocsFile.map(file => (
                        <div className="mb-2" key={file.name}>
                          {file.type === 'application/pdf' ? (
                            <i className="fas fa-file-pdf fa-2x pr-2 text-danger"></i>
                          ) : (
                            <i className="far fa-file-image fa-2x pr-2 text-info"></i>
                          )}
                          <span className="small pr-3">
                            <a
                              href={file.fileUrl}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {file.name}
                            </a>
                          </span>
                          <i
                            className="far fa-times-circle text-danger cursor-pointer"
                            onClick={deleteIncorporationDocsFile}
                          ></i>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                <div className="form-group col-md-6 px-md-4">
                  <label>Please upload your pitch deck here</label>
                  <div>
                    <div className="upload-btn-wrapper">
                      <button type="button" className="btn btn-outline-primary">
                        Choose file
                      </button>
                      <input
                        type="file"
                        name="pitchDeckFile"
                        onChange={handleFileUpload}
                      />
                    </div>
                    <div>
                      {pitchDeckFile.map(file => (
                        <div className="mb-2" key={file.name}>
                          {file.type === 'application/pdf' ? (
                            <i className="fas fa-file-pdf fa-2x pr-2 text-danger"></i>
                          ) : (
                            <i className="far fa-file-image fa-2x pr-2 text-info"></i>
                          )}
                          <span className="small pr-3">
                            <a
                              href={file.fileUrl}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {file.name}
                            </a>
                          </span>
                          <i
                            className="far fa-times-circle text-danger cursor-pointer"
                            onClick={deletePitchDeckFile}
                          ></i>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                <div className="form-group col-md-6 px-md-4">
                  <label>Please upload proof of ID for each founder</label>
                  <div>
                    <div className="upload-btn-wrapper">
                      <button type="button" className="btn btn-outline-primary">
                        Choose file
                      </button>
                      <input
                        type="file"
                        name="proofOfIDsFile"
                        onChange={handleFileUpload}
                      />
                    </div>
                    <div>
                      {proofOfIDsFile.map(file => (
                        <div className="mb-2" key={file.name}>
                          {file.type === 'application/pdf' ? (
                            <i className="fas fa-file-pdf fa-2x pr-2 text-danger"></i>
                          ) : (
                            <i className="far fa-file-image fa-2x pr-2 text-info"></i>
                          )}
                          <span className="small pr-3">
                            <a
                              href={file.fileUrl}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {file.name}
                            </a>
                          </span>
                          <i
                            className="far fa-times-circle text-danger cursor-pointer"
                            onClick={deleteProofOfIDsFile}
                          ></i>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                <div className="form-group col-md-6 px-md-4">
                  <label>
                    Upload a product descriptive flyer of your startup.
                  </label>
                  <div>
                    <div className="upload-btn-wrapper">
                      <button type="button" className="btn btn-outline-primary">
                        Choose file
                      </button>
                      <input
                        type="file"
                        name="productFlyerFile"
                        onChange={handleFileUpload}
                      />
                    </div>
                    <div>
                      {productFlyerFile.map(file => (
                        <div className="mb-2" key={file.name}>
                          {file.type === 'application/pdf' ? (
                            <i className="fas fa-file-pdf fa-2x pr-2 text-danger"></i>
                          ) : (
                            <i className="far fa-file-image fa-2x pr-2 text-info"></i>
                          )}
                          <span className="small pr-3">
                            <a
                              href={file.fileUrl}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {file.name}
                            </a>
                          </span>
                          <i
                            className="far fa-times-circle text-danger cursor-pointer"
                            onClick={deleteProductFlyerFile}
                          ></i>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                <div className="form-group col-md-6 px-md-4">
                  <label>
                    Would you like to make your documents public{' '}
                    <em className="small">
                      (however, proof of ID would never be displayed)
                    </em>
                  </label>
                  <div className="pl-2 font-weight-normal text-primary">
                    No
                    <Switch
                      checked={showDocs}
                      onChange={switchShowDocs}
                      color="primary"
                    ></Switch>
                    Yes
                  </div>
                </div>
              </div>
            </div>

            <div className="form-group col-12 px-md-3 py-2 mx-2">
              {!showPlans && (
                <div>
                  <div className="mb-2">
                    <span className="h6">Selected plan:</span>{' '}
                    <span className="h6 font-weight-normal">
                      {spacePascalWord(selectedPlan, false)}
                    </span>
                  </div>
                  <div
                    className="d-inline-flex align-items-center text-primary cursor-pointer"
                    onClick={toggleShowPlans}
                  >
                    <div>
                      <i className="far fa-plus-square fa-2x"></i>{' '}
                    </div>
                    <div>
                      <span className="px-2 h6">Change plan</span>
                    </div>
                  </div>
                </div>
              )}

              {showPlans && (
                <div>
                  <div className="text-center">
                    <h5 className="d-inline-block border-bottom pb-2">
                      Choose a plan
                    </h5>
                  </div>
                  <StartupPlans
                    planChange={onSelectPlan}
                    currentPlan={selectedPlan}
                    selectedPlan={selectedPlan}
                    isUpgrading={hasSubscribed}
                    isDraft={!hasSubscribed}
                    {...props}
                  />

                  {planError && (
                    <div className="text-danger">
                      {errors.howYouHeardAboutUs && 'Choose a plan'}
                    </div>
                  )}
                </div>
              )}
            </div>

            <div className="form-group col-12 px-md-3 py-2 mx-2">
              <p>Have a subscription code?</p>
              <div className="form-row">
                <div className="col-md-10 mb-3">
                  <input
                    type="text"
                    name="couponCode"
                    onChange={e => setCouponCode(e.target.value)}
                    value={couponCode}
                    id="couponCode"
                    className="form-control form-control-sm"
                    placeholder="Enter subscription code"
                  />
                </div>
                <div className="col-md-2">
                  <button
                    className="btn btn-sm btn-primary w-100"
                    type="button"
                    disabled={hasSubscribed}
                    onClick={!hasSubscribed ? validateCouponCode : void 0}
                  >
                    {isValidating ? (
                      <CircularProgress color="inherit" size={'1rem'} />
                    ) : (
                      'Validate'
                    )}
                  </button>
                </div>
              </div>
              <div className="py-2">
                {isValidCode && (
                  <span>
                    <i className="fa fa-check-circle fa-2x text-success"></i>
                    <span className="px-3">Valid Code.</span>
                  </span>
                )}
                {isValidCode === false && (
                  <span>
                    <i className="fa fa-times-circle fa-2x text-danger"></i>
                    <span className="px-3">
                      code not valid or not usable on this form.
                    </span>
                  </span>
                )}
              </div>
            </div>

            <div className="form-group col-12 px-md-3 py-2">
              <div className="row">
                <div className="form-group form-check mx-3">
                  <input
                    type="checkbox"
                    name="agreedToTerms"
                    className="form-check-input"
                    ref={register({ required: true })}
                  />
                  <label className="form-check-label" htmlFor="agreedToTerms">
                    I agree to the terms and conditions of this platform,
                    including its{' '}
                    <a
                      href="https://compexafrica.com/privacy"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Privacy{' '}
                    </a>
                    and{' '}
                    <a
                      href="https://compexafrica/privacy"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      cookie policies
                    </a>
                    .
                  </label>
                  <div className="text-danger">
                    {errors.agreedToTerms && 'You must accept agreement.'}
                  </div>
                </div>
              </div>
            </div>

            <div className="form-group col-12 px-md-3 pt-5 text-center">
              <button
                type="button"
                className="btn btn-secondary mr-5"
                onClick={() => {
                  // you can run auto save function here eg: autoSave(values)
                  saveAndExit(getValues());
                }}
              >
                {isSaving ? (
                  <CircularProgress color="inherit" size={'1rem'} />
                ) : (
                  'Save and Exit'
                )}
              </button>

              <button type="submit" className="btn btn-primary">
                {isSubmitting ? (
                  <CircularProgress color="inherit" size={'1rem'} />
                ) : (
                  'Submit'
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default RegisterStartup;
