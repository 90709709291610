import React from 'react';

const FeaturedInvestors = () => {
  return (
    <section>
      <div className="container w-100 p-3" style={{ padding: 20 }}>
        <div className="row">
          <div className="col-sm text-center w-100 p-3">
            <hr
              className="w-100 "
              style={{
                color: 'grey',
                backgroundColor: 'black',
              }}
            />
          </div>
          <div className="col-sm text-center">
            <h4
              style={{
                textAlign: 'center',
                fontFamily: 'metropolis-light',
                fontWeight: 'bold',
              }}
            >
              Featured Investors
            </h4>
          </div>
          <div className="col-sm text-center w-100 p-3">
            <hr
              className="w-100 "
              style={{
                color: 'grey',
                backgroundColor: 'black',
              }}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeaturedInvestors;
