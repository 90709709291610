import React from 'react';

const ContactSuccess = (props: any) => {
  return (
    <div className="align-items-center bg-white d-flex flex-column rounded-1">
      <i className="fas fa-check-circle text-primary my-5 h2 font-weight-bold"></i>
      <h1 className="h4 text-verify-dark">sent</h1>
      <div className="p-5">
        <p className="h5 text-verify-light font-weight-light text-center">
          Your message has been sent to the recipient.
        </p>
      </div>
      <div className="pb-5">
        <button className="btn btn-primary" onClick={props.handleOnDone}>
          Done
        </button>
      </div>
    </div>
  );
};

export default ContactSuccess;
