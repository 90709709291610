import React from 'react';
import testimonialMan from '../../assets/images/testimonial-man.png';
import kebab from '../../assets/images/kebab.png';
import { numberFormatter } from '../utils/helpers';
import { compexDomain } from 'config';
import tempAvatar from '../../assets/images/user.svg';

const InvestorCard = (props: any | number) => {
  const imgUrl =
    props.photos && props.photos.length > 0
      ? props.photos[0].fileUrl
      : tempAvatar;

  return (
    <div
      className="d-flex flex-column shadow rounded-10 bg-white py-3 px-4 mb-5 mx-2"
      style={{ maxWidth: 320, borderRadius: 10, height: 400 }}
    >
      <div className="d-flex flex-row align-items-start justify-content-between">
        <div className="w-15">
          <img
            src={imgUrl}
            alt="..."
            className=" img-fluid rounded-circle p-1"
            style={{
              minHeight: '4rem',
              minWidth: '4rem',
              maxHeight: '4rem',
              maxWidth: '4rem',
            }}
          />
        </div>
        <div
          className="rounded-circle bg-success"
          style={{ width: '8px', height: '8px' }}
        ></div>
        <div className="w-70 line-height-2 mb-3 pl-3">
          <p className="h8 d-inline">{props.whoYouAre}</p>
          <br></br>
          <p className="h10 d-inline">
            {props.designation} in {props.currentLocation}
          </p>
        </div>
        <img src={kebab} alt="menu-icon" />
      </div>
      <div className="w-100 border-top mb-3"></div>
      <div className="d-flex flex-row justify-content-between">
        <div className="h9 line-height-2 font-weight-bold">Interests</div>
        <div className="h9 line-height-2 w-70 text-right">
          {props.interests}
        </div>
      </div>
      <div className="d-flex flex-row justify-content-between">
        <div className="h9 line-height-2 font-weight-bold">Background:</div>
        <div className="h9 line-height-2 w-70 text-right">
          {props.background}
        </div>
      </div>
      <div className="w-100 border-top mb-3"></div>
      <div className="d-flex flex-row justify-content-between">
        <div className="h9 line-height-2 font-weight-bold">Locations</div>
        <div className="h9 line-height-2 w-70 text-right">{props.location}</div>
      </div>
      <div className="d-flex flex-row justify-content-between">
        <div className="h9 line-height-2 font-weight-bold">Industries</div>
        <div className="h9 line-height-2 w-70 text-right">
          {props.industries}
        </div>
      </div>
      <div className="d-flex flex-row justify-content-between">
        <div className="h9 line-height-2 font-weight-bold">Investment size</div>
        <div className="h9 line-height-2">
          ₦&nbsp;{numberFormatter(props.investmentRangeFrom)}
          &nbsp;-&nbsp; ₦&nbsp;{numberFormatter(props.investmentRangeTo)}
        </div>
      </div>
      <div className="d-flex flex-row justify-content-end py-2 mt-auto mb-3">
        <button
          type="button"
          style={{
            background: '#fffff',
            borderRadius: 7,
            borderColor: '#002880',
            width: 150,
            fontFamily: 'Poppins',
            fontWeight: 'bold',
            color: '#002880',
          }}
          onClick={() => {
            window.location.href = `${compexDomain}/investors/${props.id}`;
          }}
        >
          <b>Send Proposal</b>
        </button>
      </div>
    </div>
  );
};

export default InvestorCard;
