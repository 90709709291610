export const helper1 = () => {};

export function numberFormatter(num: number) {
  var si = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ];
  var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var i;
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break;
    }
  }
  return (num / si[i].value).toFixed(0).replace(rx, '$1') + si[i].symbol;
}

export const spacePascalWord = (word: string, firstOnly: boolean) => {
  if (typeof word !== 'string') return word;

  const words = word.split(/(?=[A-Z])/);
  if (firstOnly) return words[0];
  else return words.join(' ');
};

export const planActionText = (
  isUpgrading: boolean,
  planType: string,
  currentPlan: string,
) => {
  if (!isUpgrading) return 'Select plan';

  const plan = spacePascalWord(planType, true);
  currentPlan = spacePascalWord(currentPlan, true);

  if (currentPlan === plan && plan !== 'Basic') return 'Renew plan';
  else if (currentPlan === 'Basic' && plan !== 'Basic') return 'Upgrade plan';
  else if (
    currentPlan === 'Monthly' &&
    (plan === 'Quarterly' || plan === 'Yearly')
  )
    return 'Upgrade plan';
  else if (currentPlan === 'Quarterly' && plan === 'Yearly')
    return 'Upgrade plan';
  else return 'Select plan';
};
