import React from 'react';

export type PlanCardProp = {
  className: string;
  style: object;
  headerFontSize?: string;
  name: string;
  recommended?: boolean;
  amount: string;
  features: Array<string>;
  disabled: boolean;
  actionText: string;
  onSelectPlan: any;
  selectedPlan: string;
  plusFeature?: string;
};

const PlanCard: React.FunctionComponent<PlanCardProp> = props => {
  return (
    <div
      className={
        props.className
          ? props.className
          : 'bg-white shadow border p-4 plan-card'
      }
      style={props.style ? props.style : {}}
    >
      <div className="h7y text-plan d-flex flex-column align-items-center h-100">
        <div className="text-center border-bottom mb-3 pb-3 min-w-60">
          <div
            className={
              props.headerFontSize
                ? 'text-plan ' + props.headerFontSize
                : 'text-plan h7'
            }
          >
            {props.name && props.name.split(/(?=[A-Z])/)[0]} Plan
          </div>
          <div className="font-weight-bold text-primary">{props.amount}</div>
          {props.recommended && (
            <small className="text-sm-1 text-plan">(Most popular)</small>
          )}
        </div>
        {props.features.map((feature, index) => (
          <div className="w-100 min-w-rem-13 d-flex" key={index}>
            <i className="fas fa-check-circle text-primary"></i>
            <p className="pl-2 small">{feature}</p>
          </div>
        ))}
        {
          <span className="w-100 min-w-rem-13">
            {props.plusFeature && props.plusFeature}
          </span>
        }
        {props.selectedPlan === props.name && (
          <div className="text-center py-3">
            <i className="fas fa-check-circle fa-2x text-success"></i>
          </div>
        )}
        <div className="mt-auto">
          <button
            type="button"
            className="btn btn-primary text-center h8a"
            name={props.name}
            disabled={props.disabled}
            onClick={() =>
              !props.disabled ? props.onSelectPlan(props.name) : void 0
            }
            style={{ width: '179px', height: '42px' }}
          >
            {props.actionText ? props.actionText : 'Select plan'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PlanCard;
