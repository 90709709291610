import React from 'react';
import { Link } from 'react-router-dom';
import tick from '../../assets/images/tick.svg';

const UserEmailConfirmSuccess = () => {
  return (
    <div>
      <div className="d-flex flex-column align-items-center container my-5 pb-5">
        <div className="rounded-circle bg-primary p-3 w-25 col-lg-1 my-5">
          <img src={tick} alt="..."></img>
        </div>
        <h1 className="h3 text-verify-dark">Email Verified Successfully</h1>
        <div className="card bg-white shadow rounded mb-3 col-lg-6 my-5">
          <div className="card-body px-5 py-5">
            <p className="card-text h5 text-verify-light font-weight-light text-center">
              Email address has been verified successfully.
            </p>
          </div>
        </div>
        <div className="pb-5">
          <Link to="/login" className="btn btn-primary" href="#" role="button">
            Proceed to Login
          </Link>
        </div>
      </div>
    </div>
  );
};

export default UserEmailConfirmSuccess;
