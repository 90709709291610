import React from 'react';
import { useDispatch } from 'react-redux';
import { selectPlan } from '../../state/actions/startup';
import StartupPlans from 'pages/startup/StartupPlans';
import { RouteComponentProps } from 'react-router-dom';

export interface PricingProp extends RouteComponentProps<any> {}

const Pricing: React.FunctionComponent<PricingProp> = props => {
  const dispatch = useDispatch();

  const onSelectPlan = (plan: string) => {
    dispatch(selectPlan(plan));
  };

  return (
    <div className="my-3">
      <div className="text-center">
        <h2 className="text-center font-weight-bolder border-bottom py-2 d-inline-block">
          Choose a startup Plan
        </h2>
      </div>
      <StartupPlans
        isUpgrading={false}
        planChange={onSelectPlan}
        currentPlan={''}
        selectedPlan={''}
        {...props}
      />
    </div>
  );
};

export default Pricing;
