import {
  FETCH_STARTUPS,
  EDITSTARTUP,
  SELECT_PLAN,
  FETCH_STARTUP_DETAILS,
  FETCH_STARTUP_DETAILS_STARTED,
  FETCH_STARTUPS_STARTED,
} from './../actions/types';

const initialState = {
  startups: [],
  startupLoading: false,
  startupDetailLoading: false,
  startupDetails: {},
  pageNumber: 1,
  pageSize: 50,
  totalCount: 0,
  selectedPlan: '',
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case FETCH_STARTUPS:
      const { data, pageNumber, pageSize, totalCount } = action.payload;
      return {
        ...state,
        startups: data,
        pageNumber,
        pageSize,
        totalCount,
        startupLoading: false,
      };
    case FETCH_STARTUPS_STARTED:
      return { ...state, startupLoading: true };
    case FETCH_STARTUP_DETAILS:
      return {
        ...state,
        startupDetails: action.payload,
        startupDetailLoading: false,
      };
    case FETCH_STARTUP_DETAILS_STARTED:
      return {
        ...state,
        startupDetailLoading: true,
      };
    case EDITSTARTUP:
      return {
        ...state,
        formdata: action.payload,
      };
    case SELECT_PLAN:
      return {
        ...state,
        selectedPlan: action.payload,
      };
    default:
      return state;
  }
};
