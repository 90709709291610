import http from './httpService';
import baseApiUrl from '../../config';

const getInvestors = (queryString = '', pageNumber = 1) => {
  return http.get(
    `${baseApiUrl}investors?interests=${queryString}&pageNumber=${pageNumber}`,
  );
};
const getInterestedInvestors = (queryString = 'startups', pageNumber = 1) => {
  return http.get(
    `${baseApiUrl}investors?interests=${queryString}&pageNumber=${pageNumber}`,
  );
};
export default {
  getInvestors,
  getInterestedInvestors,
};
