import React, { useState, useEffect } from 'react';
import { Slide, Fade } from '@material-ui/core';
import Arrowwhite from '../../assets/images/Arrowwhite.png';
import '../../assets/styles/investor.css';
const InvestorText = () => {
  const [firstPostRender, setfirstPostRender] = useState(false);
  const [secondPostRender, setsecondPostRender] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setfirstPostRender(true);
    }, 500);
    setTimeout(() => {
      setsecondPostRender(true);
    }, 700);
  }, []);

  function handleClick(e: any) {
    e.preventDefault();
    window.location.href =
      'https://www.compexafrica.com/register-investor/plans';
  }

  return (
    <div className="container d-flex flex-wrap align-items-center justify-content-center position-relative mb-3 mt-3">
      <div className="text-center">
        <Slide
          direction="right"
          timeout={900}
          in={firstPostRender}
          mountOnEnter
          unmountOnExit
        >
          <h1
            className="font-weight-bold font-size-action"
            style={{ fontFamily: 'metropolis-light' }}
          >
            Invest in Africa's <br /> next unicorn
          </h1>
        </Slide>
        <Fade in={secondPostRender} timeout={2000}>
          <h2 className="mb-5 font-size-info">
            Get access to Pre-screened businesses across varioius <br />
            industries
          </h2>
        </Fade>
        <Slide
          direction="up"
          timeout={900}
          in={secondPostRender}
          mountOnEnter
          unmountOnExit
        >
          <div
            className="btn btn-primary font-weight-bold font-size-info rounded-10-px py-3 px-5 mt-3"
            style={{ fontFamily: 'metropolis-light' }}
            onClick={handleClick}
          >
            Become an Investor
            <img
              src={Arrowwhite}
              alt="Arrow"
              style={{
                height: 32,
                width: 32,
                marginLeft: 25,
                transform: 'rotate(-45deg)',
              }}
            />
          </div>
        </Slide>
      </div>
      <div className="bg-landing-faded bg-position-bottom position-absolute w-100 h-100"></div>
    </div>
  );
};

export default InvestorText;
