import React, { useEffect, useState } from 'react';
import InvestorCard from '../../common/components/InvestorCard';
import investorService from '../../common/services/investorService';
import { Investor } from '../../common/interfaces/investor';
import InvestorsCarousel from 'pages/investor/InvestorsCarousel';

const InterestedInvestors = () => {
  const [interestedInvestors, setInterestedInvestors] = useState<Investor[]>(
    [],
  );

  useEffect(() => {
    (async () => {
      const { status, data } = await investorService.getInterestedInvestors();
      if (status === 200 && data) setInterestedInvestors(data.data);
    })();
  }, []);

  return (
    <div className="container justify-content-center mt-5">
      <p
        className="pl-4 m-3"
        style={{ fontFamily: 'Metropolis-ExtraBold', fontSize: 'xx-large' }}
      >
        Investors Interested in your startup
      </p>
      {interestedInvestors?.length > 0 && (
        <div className="row container-fluid">
          <div className="row justify-content-center ml-2">
            {interestedInvestors.map(investor => (
              <InvestorCard
                key={investor.id}
                id={investor.id}
                whoYouAre={investor.whoYouAre}
                designation={investor.designation}
                currentLocation={investor.locations}
                interests="buying"
                background="background"
                location={investor.location}
                industries={investor.industries}
                investmentRangeFrom={investor.investmentRangeFrom}
                investmentRangeTo={investor.investmentRangeTo}
              />
            ))}
          </div>
        </div>
      )}
      {interestedInvestors?.length === 0 && (
        <div className="m-5 py-5">You don't have interested investor yet</div>
      )}
      <div className=" row justify-content-center">
        <a
          href="https://compexafrica.com/investors"
          className="btn btn-primary font-weight-bold font-size-info rounded-10-px py-3 px-5 mt-3"
          style={{ fontFamily: 'metropolis-light', marginBottom: 20 }}
        >
          View More Investors
        </a>
      </div>
      <div
        className="bg-white w-100"
        style={{
          marginTop: '10rem',
        }}
      >
        <div
          className=" text-center p-3"
          style={{ fontFamily: 'Metropolis-ExtraBold' }}
        >
          <h3>You might also be interested in</h3>
        </div>
        <div>
          <InvestorsCarousel></InvestorsCarousel>
        </div>
        {/* <div className="row">
          {suggestedInvestors?.length > 0 && (
            <div className="row container-fluid">
              <div className="row justify-content-center ml-2">
                {suggestedInvestors.map(investor => (
                  <InvestorCard
                    key={investor.id}
                    id={investor.id}
                    whoYouAre={investor.whoYouAre}
                    designation={investor.designation}
                    currentLocation={investor.locations}
                    interests="buying"
                    background="background"
                    location={investor.location}
                    industries={investor.industries}
                    investmentRangeFrom={investor.investmentRangeFrom}
                    investmentRangeTo={investor.investmentRangeTo}
                  />
                ))}
              </div>
            </div>
          )}
        </div> */}
      </div>
    </div>
  );
};

export default InterestedInvestors;
