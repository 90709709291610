import { combineReducers } from 'redux';
import startupReducer from './startup';
import userReducer from './user';
import { LOGOUT_USER } from './../actions/types';

const appReducer = combineReducers({
  startup: startupReducer,
  user: userReducer,
});

const rootReducer = (state: any, action: any) => {
  //when logout reset all state
  if (action.type === LOGOUT_USER) {
    state = undefined;
  }
  return appReducer(state, action);
};
export type AppState = ReturnType<typeof rootReducer>;

export default rootReducer;
