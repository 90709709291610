import * as React from 'react';

const HomeAsSeenOn = () => {
  return (
    <section data-testid="asSeenOn" className="container-fluid bg-seen">
      <div className="container py-5 py-md-0">
        <div className="d-flex flex-wrap justify-content-between align-items-center min-vh-50">
          <div className="bg-businessday bg-position-bottom min-w-md-15 min-w-40 min-h-rem-business m-2"></div>
          <div className="bg-how-we bg-position-bottom min-w-md-15 min-w-40 min-h-rem-how m-2"></div>
          <div className="bg-cnbc bg-position-bottom min-w-md-15 min-w-40 min-h-rem-news m-2"></div>
          <div className="bg-techcabal bg-position-bottom min-w-md-15 min-w-40 min-h-rem-info m-2"></div>
          <div className="bg-disrupt-africa bg-position-bottom min-w-md-15 min-w-40 min-h-rem-disrupt m-2"></div>
        </div>
      </div>
    </section>
  );
};

export default HomeAsSeenOn;
