import React, { useState, useRef } from 'react';
import { RouteComponentProps, Redirect } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { Link } from 'react-router-dom';
import authService from 'common/services/authService';
import { UserDto } from 'common/interfaces/User';
import { CircularProgress } from '@material-ui/core';
import FormAlertMessage from 'common/components/FormAlertMessage';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import GoogleLoginBtn from 'common/components/GoogleLoginBtn';
import FacebookLoginBtn from 'common/components/FacebookLoginBtn';
import { useSelector } from 'react-redux';

export interface SignupProps extends RouteComponentProps<any> {}

const Signup = (props: SignupProps) => {
  const { register, handleSubmit, errors, watch, control } = useForm<UserDto>();
  const isLoggedIn = useSelector((state: any) => state.user.isLoggedIn);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const password = useRef({});
  password.current = watch('password', '');

  const onSubmit = async (values: UserDto) => {
    try {
      setIsLoading(true);
      const res = await authService.signUp(values);
      if (res) {
        setIsLoading(false);
        props.history.replace('/registration-success');
      } else {
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);

      const { response } = err;

      if (response && response.data && response.data.title) {
        let message =
          response.data.title === `User With ${values.email} Already Exists`
            ? `You already have a Compex Africa Account, kindly Sign in with your Compex Africa credentials.`
            : response.data.title;
        setErrorMessage(message);
      }
    }
  };

  return isLoggedIn ? (
    <Redirect to="/user/dashboard" />
  ) : (
    <>
      <div className="container-fluid bg-light-grey min-w-vh-100">
        <div className="container">
          <div className="row justify-content-center p-2 p-md-5">
            <div className="col-md-10">
              <div className="text-center">
                <h4 className="text-black font-weight-bold">
                  Create a Compex Africa account
                </h4>
                <div className="text-black small">
                  <div className="pb-2">
                    You will need a <strong>Compex Africa</strong> account to
                    login to Startup Zone
                  </div>
                </div>
              </div>
              <div className="my-5 p-4 p-md-5 bg-form rounded-20-px shadow">
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  method="POST"
                  noValidate
                  className="small"
                >
                  <div className="form-row">
                    <div className="form-group col-md-6 px-md-4">
                      <label htmlFor="firstname">First Name</label>
                      <input
                        type="text"
                        name="firstname"
                        ref={register({ required: true })}
                        className={
                          errors.firstname
                            ? 'form-control is-invalid'
                            : 'form-control'
                        }
                      />
                      <div className="invalid-feedback">
                        {errors.firstname && 'First name is required'}
                      </div>
                    </div>

                    <div className="form-group col-md-6 px-md-4">
                      <label htmlFor="lastname">Last Name</label>
                      <input
                        type="text"
                        name="lastname"
                        ref={register({ required: true })}
                        className={
                          errors.lastname
                            ? 'form-control is-invalid'
                            : 'form-control'
                        }
                      />
                      <div className="invalid-feedback">
                        {errors.lastname && 'Last name is required'}
                      </div>
                    </div>

                    <div className="form-group col-md-6 px-md-4">
                      <label htmlFor="email">Email Address</label>
                      <input
                        type="email"
                        name="email"
                        ref={register({
                          required: 'Required',
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                            message: 'invalid email address',
                          },
                        })}
                        className={
                          errors.email
                            ? 'form-control is-invalid'
                            : 'form-control'
                        }
                      />
                      <div className="invalid-feedback">
                        {errors.email && 'A valid email address is required'}
                      </div>
                    </div>

                    <div className="form-group col-md-6 px-md-4">
                      <label htmlFor="phoneNumber">Phone Number</label>
                      <Controller
                        as={PhoneInput}
                        type="text"
                        name="phoneNumber"
                        control={control}
                        rules={{ required: true, minLength: 9 }}
                        country={'ng'}
                        inputClass={
                          errors.phoneNumber
                            ? 'form-control w-100 is-invalid'
                            : 'form-control w-100'
                        }
                      />
                      <div
                        className={errors.phoneNumber ? 'is-invalid' : ''}
                      ></div>
                      <div className="invalid-feedback">
                        {errors.phoneNumber &&
                          errors.phoneNumber.type === 'required' &&
                          'Phone number is required'}
                        {errors.phoneNumber &&
                          errors.phoneNumber.type === 'minLength' &&
                          'Phone number of minimum length 6'}
                      </div>
                    </div>

                    <div className="form-group col-md-6 px-md-4">
                      <label htmlFor="password">Password</label>
                      <input
                        type="password"
                        name="password"
                        ref={register({
                          required: 'Password is required',
                          minLength: {
                            value: 8,
                            message: 'Password must have at least 8 characters',
                          },
                        })}
                        className={
                          errors.password
                            ? 'form-control is-invalid'
                            : 'form-control'
                        }
                      />
                      <div className="invalid-feedback">
                        {errors.password && errors.password.message}
                      </div>
                    </div>

                    <div className="form-group col-md-6 px-md-4">
                      <label htmlFor="confirmPassword">Confirm password</label>
                      <input
                        type="password"
                        name="confirmPassword"
                        ref={register({
                          validate: value =>
                            value === password.current ||
                            'Password confirmation must match password',
                        })}
                        className={
                          errors.confirmPassword
                            ? 'form-control is-invalid'
                            : 'form-control'
                        }
                      />
                      <div className="invalid-feedback">
                        {errors.confirmPassword &&
                          errors.confirmPassword.message}
                      </div>
                    </div>

                    {errorMessage && (
                      <div className="form-group col-12">
                        <FormAlertMessage message={errorMessage} />
                      </div>
                    )}

                    <div className="form-group col-12">
                      <div className="row justify-content-center pt-4">
                        <div className="col-md-6">
                          <button
                            type="submit"
                            className="btn btn-primary btn-block rounded-5-px d-flex justify-content-center align-items-center"
                          >
                            <div className="bg-compex-icon min-w-rem-unit min-h-rem-unit"></div>

                            {isLoading ? (
                              <span className="px-3 m-w-90 d-block">
                                <CircularProgress
                                  color="inherit"
                                  size={'1rem'}
                                />
                              </span>
                            ) : (
                              <span
                                className="text-white pl-3 min-w-90"
                                style={{
                                  fontFamily: 'Roboto',
                                  fontWeight: 500,
                                }}
                              >
                                Sign up with Compex Africa
                              </span>
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>

                <div className="d-flex flex-wrap flex-column flex-md-row justify-content-around align-items-center min-w-100 mt-2">
                  <p
                    className="text-muted pl-3"
                    style={{ fontFamily: 'Roboto', fontWeight: 500 }}
                  >
                    OR
                  </p>
                  <div className="w-100"></div>
                  <div className="my-2 text-center w-100 w-md-40">
                    <GoogleLoginBtn
                      displayText={'Sign up with Google'}
                      {...props}
                    />
                  </div>

                  <div className="my-2 text-center w-100 w-md-40">
                    <FacebookLoginBtn
                      displayText={'Sign up with Facebook'}
                      {...props}
                    />
                  </div>
                  <div className="w-100"></div>
                  <div className="mt-4 font-size-below">
                    Already have a <strong>Compex Africa</strong> account?{' '}
                    <Link to="/signin" className="font-weight-bolder">
                      sign in
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Signup;
