import http from './httpService';
import baseApiUrl from '../../config';
import { ListingType } from '../utils/listingTypes';

const getUserContext = () => {
  return http.get(`${baseApiUrl}users/context`);
};

const getMessageListings = () => {
  return http.get(
    `${baseApiUrl}users/listings?excludeMore=${ListingType.Startup}&excludeMore=${ListingType.Business}&excludeMore=${ListingType.Franchise}`,
  );
};

const sendStartupMessage = async (proposal: any) => {
  const { status } = await http.post(
    `${baseApiUrl}proposals/startup`,
    proposal,
  );
  if (status === 200) return true;
};

const getUserInbox = (pageNumber = 1, pageSize = 10, searchString = '') => {
  return http.get(
    `${baseApiUrl}proposals/inbox?PageNumber=${pageNumber}&PageSize=${pageSize}&searchString=${searchString}`,
  );
};

const getUserSent = (pageNumber = 1, pageSize = 10, searchString = '') => {
  return http.get(
    `${baseApiUrl}proposals/sent?PageNumber=${pageNumber}&PageSize=${pageSize}&searchString=${searchString}`,
  );
};

const viewMessageListing = async (id: number) => {
  const { status } = await http.post(`${baseApiUrl}proposals/${id}/viewed`);
  if (status === 200) return true;
};

const readMessage = async (id: number) => {
  const { status } = await http.post(`${baseApiUrl}proposals/${id}/read`);
  if (status === 200) return true;
};

const markAllMessagesRead = async () => {
  return http.post(`${baseApiUrl}proposals/readall`);
};

const getNotifications = () => {
  return http.get(`${baseApiUrl}notifications`);
};

const getUserSubscriptions = () => {
  return http.get(`${baseApiUrl}users/subscriptions`);
};

const getUserSubscription = (id: any) => {
  return http.get(`${baseApiUrl}users/subscriptions/${id}`);
};

const changeSubscriptionRenewal = async (id: any, renewal: boolean) => {
  return http.get(`${baseApiUrl}subscriptions/${id}/renewal?status=${renewal}`);
};

export default {
  getUserContext,
  getMessageListings,
  sendStartupMessage,
  getUserInbox,
  getUserSent,
  markAllMessagesRead,
  readMessage,
  viewMessageListing,
  getNotifications,
  getUserSubscriptions,
  getUserSubscription,
  changeSubscriptionRenewal,
};
