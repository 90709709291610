import {
  LOGIN_USER,
  LOGOUT_USER,
  SET_CURRENT_USER,
  ADD_MESSAGE_TO_STARTUP_LISTINGS,
  FETCH_USER_INBOX,
  FETCH_USER_SENT,
  FETCH_MORE_SENT,
  FETCH_MORE_INBOX,
  FETCH_INBOX_STARTED,
  FETCH_SENT_STARTED,
  FETCH_MORE_SENT_STARTED,
  FETCH_MORE_INBOX_STARTED,
  FETCH_USER_NOTIFICATIONS,
  SET_USER_STARTUPID,
} from './types';
import { User } from './../../common/interfaces/User';
import userService from '../../common/services/userService';
import startupsServices from 'common/services/startupsServices';

export const setCurrentUser = (user: User) => (dispatch: any) => {
  dispatch({ type: SET_CURRENT_USER, payload: user });
};

export const loginUser = () => (dispatch: any) => {
  dispatch({ type: LOGIN_USER });
};

export const logoutUser = () => (dispatch: any) => {
  dispatch({ type: LOGOUT_USER });
};

export const addMessageListings = () => (dispatch: any) => {
  try {
    userService.getMessageListings().then(
      res => {
        dispatch({
          type: ADD_MESSAGE_TO_STARTUP_LISTINGS,
          payload: res.data,
        });
      },
      err => {
        dispatch({
          type: ADD_MESSAGE_TO_STARTUP_LISTINGS,
          payload: [],
        });
      },
    );
  } catch (error) {}
};

export const fetchUserInbox = (
  pageNumber: number,
  pageSize: number,
  searchString = '',
) => async (dispatch: any) => {
  dispatch({ type: FETCH_INBOX_STARTED });
  await userService.getUserInbox(pageNumber, pageSize, searchString).then(
    res => {
      dispatch({
        type: FETCH_USER_INBOX,
        payload: res && res.data,
      });
    },
    err => {
      dispatch({
        type: FETCH_USER_INBOX,
        payload: {},
      });
    },
  );
};

export const fetchUserSent = (
  pageNumber: number,
  pageSize: number,
  searchString = '',
) => async (dispatch: any) => {
  dispatch({ type: FETCH_SENT_STARTED });
  await userService.getUserSent(pageNumber, pageSize, searchString).then(
    res => {
      dispatch({
        type: FETCH_USER_SENT,
        payload: res.data,
      });
    },
    err => {
      dispatch({
        type: FETCH_USER_SENT,
        payload: {},
      });
    },
  );
};

export const fetchMoreSent = (
  pageNumber: number,
  pageSize: number,
  searchString = '',
) => async (dispatch: any) => {
  dispatch({ type: FETCH_MORE_SENT_STARTED });
  await userService.getUserSent(pageNumber, pageSize, searchString).then(
    res => {
      dispatch({
        type: FETCH_MORE_SENT,
        payload: res.data,
      });
    },
    err => {
      dispatch({
        type: FETCH_MORE_SENT,
        payload: {},
      });
    },
  );
};

export const fetchMoreInbox = (
  pageNumber: number,
  pageSize: number,
  searchString = '',
) => async (dispatch: any) => {
  dispatch({ type: FETCH_MORE_INBOX_STARTED });
  await userService.getUserInbox(pageNumber, pageSize, searchString).then(
    res => {
      dispatch({
        type: FETCH_MORE_INBOX,
        payload: res.data,
      });
    },
    err => {
      dispatch({
        type: FETCH_MORE_INBOX,
        payload: {},
      });
    },
  );
};

export const fetchUserNotifications = () => (dispatch: any) => {
  userService.getNotifications().then(
    res => {
      dispatch({
        type: FETCH_USER_NOTIFICATIONS,
        payload: res.data,
      });
    },
    err => {
      dispatch({
        type: FETCH_USER_NOTIFICATIONS,
        payload: [],
      });
    },
  );
};

export const setUserStartupId = (userId: number) => (dispatch: any) => {
  startupsServices.getUserStartups(userId).then(res => {
    if (res?.data?.data?.length > 0) {
      dispatch({
        type: SET_USER_STARTUPID,
        payload: res?.data?.data[0].id,
      });
    }
  });
};
