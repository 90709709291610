import store from '../../state/store';
import jwtDecode from 'jwt-decode';
import baseApiUrl from '../../config';
import http from './httpService';
import userService from '../services/userService';
import {
  LOGIN_USER,
  LOGOUT_USER,
  SET_CURRENT_USER,
} from '../../state/actions/types';
import { UserDto } from '../interfaces/User';
import { User } from '../interfaces/User';
import { setUserStartupId } from 'state/actions/user';
import { getCookie, setCookie, deleteCookie } from '../utils/cookie';

const tokenKey = '_compex_token';
const authToken = getCookie(tokenKey) || '';
http.setJwt(authToken);

const signUp = async (userDto: UserDto) => {
  store.dispatch({
    type: SET_CURRENT_USER,
    payload: { email: userDto.email },
  });

  const { status } = await http.post(`${baseApiUrl}account/register`, userDto);
  if (status === 200) {
    return true;
  } else {
    return false;
  }
};

const login = async (userDto: UserDto) => {
  const { status, data } = await http.post(
    `${baseApiUrl}account/login`,
    userDto,
  );
  if (status === 200 && data && data.token) {
    const user = getJwtUser(data.token);
    if (user) {
      setCookie(tokenKey, data.token);
      http.setJwt(data.token);
      getSetUserContext(user);
    }
    return user;
  } else {
    return null;
  }
};

const loginUserLocal = () => {
  const token = getCookie(tokenKey);
  if (token) {
    try {
      const user = getJwtUser(token);
      if (user && user.expireIn) {
        //clear token after expiry time
        const expiryTime = new Date(user.expireIn * 1000);
        if (expiryTime < new Date()) {
          deleteCookie(tokenKey);
          http.setJwt('none');
          return;
        }

        http.setJwt(token);
        getSetUserContext(user);
      }
    } catch (error) {}
  }
};

const googleLogin = async (response: any) => {
  const { status, data } = await http.post(
    `${baseApiUrl}account/google-login`,
    {
      token: response.tokenId,
    },
  );

  if (status === 200 && data && data.token) {
    const user = getJwtUser(data.token);
    if (user) {
      http.setJwt(data.token);
      setCookie(tokenKey, data.token);
      getSetUserContext(user);
    }
    return user;
  } else {
    return null;
  }
};

const facebookLogin = async (response: any) => {
  const { status, data } = await http.post(
    `${baseApiUrl}account/facebook-login`,
    {
      token: response.accessToken,
    },
  );

  if (status === 200 && data && data.token) {
    const user = getJwtUser(data.token);
    if (user) {
      http.setJwt(data.token);
      setCookie(tokenKey, data.token);
      getSetUserContext(user);
    }
    return user;
  } else {
    return null;
  }
};

const getSetUserContext = (user: any) => {
  user.firstName = user.firstname;
  user.lastName = user.surname;
  setCurrentUser(user);

  store.dispatch({ type: LOGIN_USER });

  store.dispatch(setUserStartupId(user.userId));

  userService
    .getUserContext()
    .then(res => {
      if (res.data) {
        setCurrentUser(res.data);
      }
    })
    .catch(err => console.log(err));
};

const setCurrentUser = (data: any) => {
  const {
    userId,
    firstName: firstname,
    lastName: lastname,
    email,
    phoneNumber: phone,
    roles,
    subscriptions,
    photoUrl,
  } = data;

  const user: User = {
    userId,
    firstname,
    lastname,
    email,
    phone: phone || '',
    roles,
    subscriptions,
    photoUrl,
    displayName: `${firstname ? firstname : ''} ${lastname ? lastname : ''}`,
  };

  if (roles && roles.length > 0) {
    user.isAdmin = roles.includes('Admin');
    user.isSuperAdmin = roles.includes('SuperAdmin');
  }

  store.dispatch({
    type: SET_CURRENT_USER,
    payload: user,
  });
};

const logOutUser = () => {
  deleteCookie(tokenKey);
  http.setJwt('none');
  store.dispatch({ type: LOGOUT_USER });
  store.dispatch({ type: SET_CURRENT_USER, payload: {} });
};

const getJwtUser = (token: string) => {
  try {
    const result: any = jwtDecode(token);
    if (result) {
      const claimsPrefix =
        'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/';
      const user: any = {
        userId: parseInt(result.unique_name, 10),
        firstname: result[claimsPrefix + 'givenname'],
        surname: result[claimsPrefix + 'surname'],
        email: result[claimsPrefix + 'emailaddress'],
        expireIn: result.exp,
      };
      const roles =
        result['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
      user.roles = roles;
      user.isAdmin = roles.includes('Admin');
      user.displayName = `${user.firstname} ${
        user.surname ? user.surname : ''
      }`;

      return user;
    }
    return null;
  } catch (error) {
    return null;
  }
};

const confirmUserEmail = async (email: any, token: any) => {
  try {
    const { status } = await http.post(`${baseApiUrl}account/confirm-email`, {
      email,
      token,
    });
    if (status === 200) return true;
  } catch (error) {
    console.log(error);
  }
};

const resendConfirmationEmail = async (email: string) => {
  const { status } = await http.post(
    `${baseApiUrl}account/resend-confirmation-email`,
    {
      email,
    },
  );
  if (status === 200) return true;
};

const requestPasswordReset = async (email: string) => {
  const { status } = await http.post(
    `${baseApiUrl}account/request-password-reset`,
    {
      email,
    },
  );
  if (status === 200) return true;
};

const resetPassword = async (
  email: string,
  password: string,
  token: string,
) => {
  const { status } = await http.post(`${baseApiUrl}account/reset-password`, {
    email,
    password,
    token,
  });
  if (status === 200) return true;
};

const updateCurrentUser = async (userDto: any) => {
  const { status } = await http.post(`${baseApiUrl}users/update`, userDto);
  if (status === 200) {
    return true;
  } else {
    return false;
  }
};

export default {
  signUp,
  login,
  logOutUser,
  loginUserLocal,
  googleLogin,
  facebookLogin,
  resendConfirmationEmail,
  requestPasswordReset,
  resetPassword,
  confirmUserEmail,
  updateCurrentUser,
};
