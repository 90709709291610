import React from 'react';
import { Link, RouteComponentProps, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import authService from 'common/services/authService';
import { motion } from 'framer-motion';
import tempAvatar from '../../src/assets/images/default-avatar.png';
import MessageNotification from 'common/components/MessageNotification';
import Notification from 'common/components/Notification';
import { compexDomain } from 'config';
import blankImage from 'assets/images/default.png';

interface AppNavbarProps extends RouteComponentProps<any> {}

const NavbarDesktop = (props: AppNavbarProps) => {
  const isLoggedIn = useSelector((state: any) => state.user.isLoggedIn);
  const currentUser = useSelector((state: any) => state.user.currentUser);

  const signOut = () => {
    authService.logOutUser();
    props.history.replace('/');
  };

  const isSignin = useRouteMatch('/signin');
  const isSignup = useRouteMatch('/signup');
  const isForgotPassword = useRouteMatch('/forgot-password');

  const match =
    isSignin?.isExact || isSignup?.isExact || isForgotPassword?.isExact;

  const startupWidth = isLoggedIn ? 'w-30' : '';

  return (
    <header
      className={`d-none d-md-block ${match ? 'bg-light-grey' : 'bg-white'}`}
    >
      <nav className="max-nav navbar fixed-top navbar-expand-lg navbar-light bg-white">
        <Link
          className="navbar-brand font-weight-bold text-black d-flex align-items-center position-relative"
          to="/"
        >
          <span className="bg-logo logo-small mr-3 position-absolute"></span>
          <span
            className="d-none d-lg-block d-xl-block pl-5"
            style={{ fontFamily: 'Metropolis-ExtraBold' }}
          >
            Startup Zone
          </span>
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarDesktopMenu"
          aria-controls="navbarDesktopMenu"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className=" collapse navbar-collapse" id="navbarDesktopMenu">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item my-lg-auto mx-2 mb-2">
              <a className="nav-link text-black nav-text" href={compexDomain}>
                CompexAfrica
              </a>
            </li>
            <li className={`nav-item my-lg-auto mx-2 mb-2 ${startupWidth}`}>
              <Link className="nav-link text-black nav-text" to="/startups">
                View Startups
              </Link>
            </li>
            <li className="nav-item my-lg-auto mx-2 mb-2">
              <Link className="nav-link text-black nav-text" to="/investors">
                Investors
              </Link>
            </li>
            <li className="nav-item my-lg-auto mx-2 mb-2">
              <Link className="nav-link text-black nav-text" to="/community">
                Community
              </Link>
            </li>
            <li className="nav-item my-lg-auto mx-2 mb-2">
              <Link className="nav-link text-black nav-text" to="/pricing">
                Pricing
              </Link>
            </li>
            {!isLoggedIn && (
              <>
                <li className="nav-item my-lg-auto mx-2 mb-2">
                  <Link
                    className="btn btn-thick-outline-primary font-weight-bold px-md-2 px-xl-3"
                    to="/signin"
                  >
                    Sign in
                  </Link>
                </li>
                <li className="nav-item my-lg-auto mx-1 mb-2">
                  <Link
                    className="btn btn-primary border-thick-primary font-weight-bold px-md-2 px-xl-3 "
                    to="/signup"
                  >
                    Sign up
                  </Link>
                </li>
              </>
            )}
            {isLoggedIn && (
              <>
                <li className="nav-item p-0 w-10 my-auto dropdown">
                  <MessageNotification history={props.history} />
                </li>
                <li className="nav-item p-0  w-10 my-auto dropdown">
                  <Notification history={props.history} />
                </li>
                <li className="nav-item dropdown text-center">
                  <div
                    className="cursor-pointer"
                    id="navbarDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <div
                      className="border rounded-circle"
                      style={{
                        height: '3.2rem',
                        width: '3.2rem',
                      }}
                    >
                      {currentUser &&
                        (currentUser.photoUrl !== null ? (
                          <img
                            src={currentUser.photoUrl || blankImage}
                            alt="..."
                            className="img-fluid rounded-circle"
                          ></img>
                        ) : (
                          <div
                            className="avaterImageDiv bg-primary"
                            style={{
                              height: '3.2rem',
                              width: '3.2rem',
                            }}
                          >
                            <h1 className="text-center text-white align-middle">
                              {currentUser.firstname.substr(0, 1).toUpperCase()}
                            </h1>
                          </div>
                        ))}
                    </div>
                  </div>
                  <div
                    className="dropdown-menu dropdown-menu-right min-w-rem-disrupt"
                    aria-labelledby="navbarDropdown"
                  >
                    <Link to="/user/dashboard" className="dropdown-item">
                      Dashboard
                    </Link>
                    <div
                      className="dropdown-item cursor-pointer"
                      onClick={signOut}
                    >
                      Sign out
                    </div>
                  </div>
                </li>
              </>
            )}
          </ul>
        </div>
      </nav>
    </header>
  );
};

export default NavbarDesktop;
