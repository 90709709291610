//env variables used here are set in .env .env.development .env.staging
const baseApiUrl =
  process.env.REACT_APP_CUSTOM_NODE_ENV === 'production'
    ? process.env.REACT_APP_PRODUCTION_URL
    : process.env.REACT_APP_CUSTOM_NODE_ENV === 'development'
    ? process.env.REACT_APP_DEV_URL
    : process.env.REACT_APP_CUSTOM_NODE_ENV === 'staging'
    ? process.env.REACT_APP_STAGING_URL
    : process.env.REACT_APP_LOCAL_URL;

export const API_publicKey = 'FLWPUBK_TEST-fea505a99c60f61e6041f0f7da95a6c2-X';

export const paymentPublicKey =
  process.env.REACT_APP_CUSTOM_NODE_ENV === 'production'
    ? process.env.REACT_APP_LIVE_PAYMENT_KEY
    : process.env.REACT_APP_TEST_PAYMENT_KEY;

export const googleAppId =
  process.env.REACT_APP_CUSTOM_NODE_ENV === 'production'
    ? process.env.REACT_APP_GOOGLE_APPID_PROD
    : process.env.REACT_APP_GOOGLE_APPID_DEV;

export const facebookAppId = '497032640966561';

export const compexDomain =
  process.env.REACT_APP_CUSTOM_COMPEX_DOMAIN || 'localhost:3000';

export const compexCookieDomain =
  process.env.REACT_APP_CUSTOM_COMPEX_COOKIE_DOMAIN || 'localhost';

export default baseApiUrl;
