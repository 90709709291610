export const startupIndustries = [
  { name: 'Fintech' },
  { name: 'Health' },
  { name: 'Smart Cities' },
  { name: 'Biotech' },
  { name: 'Collaboration' },
  { name: 'Entertainment' },
  { name: 'IT Tool' },
  { name: 'Security' },
  { name: 'Sports' },
  { name: 'Real Estate' },
  { name: 'Transportation' },
  { name: 'Media' },
  { name: 'HR' },
  { name: 'Education' },
  { name: 'Marketing' },
  { name: 'Customer Success' },
  { name: 'Retail' },
  { name: 'E-commerce' },
  { name: 'Industry' },
  { name: 'Software' },
];
