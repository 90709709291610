const startupStatus = {
  Draft: 'Draft',
  Pending: 'Pending',
  Approved: 'Approved',
  Declined: 'Declined',
  ChangesRequired: 'ChangesRequired',
};

Object.freeze(startupStatus);

export default startupStatus;
