import React from 'react';
import PlanCard from 'common/components/PlanCard';
import { RouteComponentProps } from 'react-router-dom';
import { planActionText } from 'common/utils/helpers';
import planTypes from 'common/utils/startupPlanTypes';
import { useDispatch } from 'react-redux';
import { selectPlan } from 'state/actions/startup';

export interface StartupPlansProp extends RouteComponentProps<any> {
  isUpgrading: boolean;
  isDraft?: boolean;
  planChange: any;
  currentPlan: string;
  selectedPlan: string;
}

const StartupPlans: React.FunctionComponent<StartupPlansProp> = props => {
  const dispatch = useDispatch();

  const onSelectPlan = (plan: string) => {
    if (props.location.pathname === '/pricing') {
      dispatch(selectPlan(plan));
      props.history.push('/register-startup');
    } else {
      props.planChange(plan);
      return;
    }
  };

  const currentPlan =
    props.isUpgrading || props.isDraft ? props.currentPlan : props.selectedPlan;

  const actionText = (planType: string) => {
    return planActionText(props.isUpgrading, planType, props.currentPlan);
  };

  return (
    <div className="d-flex flex-wrap flex-lg-nowrap h-100 justify-content-center">
      <PlanCard
        name={planTypes.monthly}
        amount="NGN 2,000"
        onSelectPlan={onSelectPlan}
        selectedPlan={currentPlan}
        actionText={actionText(planTypes.monthly)}
        disabled={false}
        features={[
          'Speedy profile activation',
          '5 introductions to investors',
          'One month validity',
          'Access to startup community',
          'Startup consulting',
          'Access to startup-to-scale up dynamic podcast',
        ]}
        className="p-4 bg-white rounded shadow-lg shadow-lg-left mt-lg-5 mb-5 plan"
        style={{ width: '305px', maxWidth: '364px', height: '425px' }}
      />
      <PlanCard
        name={planTypes.quarterly}
        amount="NGN 5,000"
        onSelectPlan={onSelectPlan}
        selectedPlan={currentPlan}
        actionText={actionText(planTypes.quarterly)}
        disabled={false}
        recommended={true}
        features={[
          'All features in monthly plan',
          '15 introductions to investors',
          '2 minute elevator pitch video',
          'Professionally crafted business plan',
          'Discount on high-end networking events',
        ]}
        className="p-5 bg-white rounded shadow-lg mt-lg-3 mb-5 plan"
        style={{
          width: '305px',
          maxWidth: '364px',
          height: '487px',
          zIndex: '2',
        }}
        headerFontSize="h2a"
      />
      <PlanCard
        name={planTypes.yearly}
        amount="NGN 20,000"
        onSelectPlan={onSelectPlan}
        selectedPlan={currentPlan}
        actionText={actionText(planTypes.yearly)}
        disabled={false}
        features={[
          'All features in quarterly plan',
          '30 introductions to investors',
          'Full access to high-end networking events and event speakers',
          'Enterprise support',
          'Premium badge to scheduled workshop',
          'Mini valuation',
        ]}
        className="p-4 bg-white rounded shadow-lg shadow-lg-right mt-lg-5 mb-5 plan"
        style={{ width: '305px', maxWidth: '364px', height: '425px' }}
      />
    </div>
  );
};

export default StartupPlans;
