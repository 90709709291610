import * as React from 'react';

const HomeMailingList = () => {
  return (
    <section className="container-fluid bg-scenery bg-size-cover bg-position-center">
      <div className="container min-vh-60 min-vh-xl-30 d-flex flex-wrap align-items-center justify-content-center">
        <div className="min-w-100">
          <h2
            className="font-size-orangy text-center mb-5 text-black"
            style={{ fontFamily: 'Metropolis-ExtraBold' }}
          >
            Stay up to date. Join our mailing list
          </h2>
          <form
            className="position-relative"
            action="//compexafrica.us14.list-manage.com/subscribe/post?u=22877e334359b80e8c0b09b0d&amp;id=21a4cabaac"
            method="post"
            name="mc-embedded-subscribe-form"
            target="_blank"
          >
            <input
              type="email"
              name="EMAIL"
              placeholder="Enter your email"
              className="w-100 border-thick-primary rounded-50-px text-black py-2"
              style={{ paddingLeft: '10%', fontFamily: 'Metropolis-Medium' }}
            />
            <button
              className="position-absolute h-100 btn btn-primary rounded-50-px w-sm-40 w-lg-30 font-weight-bold font-size-unit font-size-md-info"
              style={{
                right: '0%',
                wordWrap: 'break-word',
                fontFamily: 'Metropolis-SemiBold',
              }}
            >
              Subscribe
            </button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default HomeMailingList;
