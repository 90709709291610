import React from 'react';
import HomeLanding from './HomeLanding';
import HomeFeatures from './HomeFeatures';
import HomeBecomeInvestor from './HomeBecomeInvestor';
import HomeTestimonials from './HomeTestimonials';
import HomeAsSeenOn from './HomeAsSeenOn';
import HomeMailingList from './HomeMailingList';

const Home = () => {
  return (
    <section style={{ overflow: 'hidden' }}>
      <HomeLanding />
      <HomeFeatures />
      <HomeBecomeInvestor />
      <HomeTestimonials />
      <HomeAsSeenOn />
      <HomeMailingList />
    </section>
  );
};

export default Home;
