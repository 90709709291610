import React, { useRef, useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useSelector, useDispatch } from 'react-redux';
import { fetchStartupDetails } from './../../state/actions/startup';
import { CircularProgress } from '@material-ui/core';
import FadeIn from 'react-fade-in';
import randomstring from 'randomstring';
import { Link, useHistory } from 'react-router-dom';
import ContactStartupBtn from 'common/components/ContactStartupBtn';
import { spacePascalWord } from 'common/utils/helpers';
import startupStatus from 'common/utils/startupStatus';
import Swal from 'sweetalert2';
import startupsServices from 'common/services/startupsServices';
import StartupIndustryImage from '../../common/utils/StartupIndustryImage';
import { SET_USER_STARTUPID } from '../../state/actions/types';

const StartupListingItemPage = (props: any) => {
  const history = useHistory();
  const startupDetails = useSelector(
    (state: any) => state.startup.startupDetails,
  );
  const startupDetailLoading = useSelector(
    (state: any) => state.startup.startupDetailLoading,
  );
  const validURL = (str: any) => {
    var pattern = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$',
      'i',
    ); // fragment locator
    return !!pattern.test(str);
  };

  const validateYouTubeUrl = (url: string) => {
    if (url) {
      var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
      var match = url.match(regExp);
      if (match && match[2].length == 11) return true;
      else return false;
    } else return false;
  };

  const getYouTubeId = (url: any) => {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);

    return match && match[2].length === 11 ? match[2] : null;
  };
  const imgUrl =
    startupDetails.industry &&
    StartupIndustryImage[startupDetails.industry.replace(/[- ]/g, '')]
      ? StartupIndustryImage[startupDetails.industry.replace(/[- ]/g, '')]
          .defaultImage
      : StartupIndustryImage.default.defaultImage;

  const [isDeleting, setIsDeleting] = useState(false);
  const currentUser = useSelector((state: any) => state.user.currentUser);
  const dispatch = useDispatch();
  const video = useRef<any>();
  const playBttn = useRef<any>();

  useEffect(() => {
    const id = props.match?.params?.id;
    if (id) {
      dispatch(fetchStartupDetails(id));
    } else if (props.userStartupId) {
      dispatch(fetchStartupDetails(props.userStartupId));
    }
  }, [dispatch, props]);

  const deleteStartup = () => {
    Swal.fire(
      'Are you sure',
      'Do you really want to delete your startup?',
      'warning',
    ).then(async ({ value }) => {
      if (value && startupDetails?.id) {
        try {
          setIsDeleting(true);
          const { status } = await startupsServices.deleteStartup(
            startupDetails.id,
          );
          if (status === 200) {
            setIsDeleting(false);
            dispatch({ type: SET_USER_STARTUPID, payload: 0 });
            history.push('/user/dashboard');
          } else {
            setIsDeleting(false);
          }
        } catch (error) {
          setIsDeleting(false);
        }
      }
    });
  };

  const playVideo = () => {
    video.current.play();
    playBttn.current.style.visibility = 'hidden';
  };
  const pauseVideo = () => {
    playBttn.current.style.visibility = 'visible';
    playBttn.current.classList.remove('bg-video');
  };

  return startupDetailLoading ? (
    <div
      style={{ minHeight: '60vh' }}
      className="d-flex justify-content-center align-items-center"
    >
      <CircularProgress color="primary" size={'3rem'} />
    </div>
  ) : (
    startupDetails && (
      <FadeIn>
        <div>
          <div className="container">
            <div className=" custom-center h-100 d-flex flex-wrap flex-md-nowrap flex-xl-nowrap justify-content-center align-items-stretch mt-5">
              <div className="w-md-60 w-100 w-lg-60 d-flex flex-column mb-md-5">
                <h1>{startupDetails.startupName}</h1>
                <p className="h1z ">{startupDetails.shortDescription}</p>

                <div className="w-100 position-relative d-flex flex-column justify-content-center mb-lg-0 mb-md-5">
                  <div className="position-relative rounded-3">
                    {validateYouTubeUrl(
                      startupDetails.videoUrl && startupDetails.videoUrl,
                    ) ? (
                      <div className="video-container">
                        <iframe
                          ref={video}
                          src={`//www.youtube.com/embed/${getYouTubeId(
                            startupDetails.videoUrl,
                          )}`}
                          frameBorder="0"
                          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                        ></iframe>
                      </div>
                    ) : (
                      <img
                        src={imgUrl}
                        alt="Default Startup Sector"
                        className="w-100"
                        style={{ minHeight: '400px' }}
                      ></img>
                    )}
                  </div>
                </div>
              </div>

              <div className="w-100 w-md-40  d-flex flex-column ml-md-3 align-self-stretch">
                <div className="w-100 border-top mb-5 mt-3 border-thick-primary"></div>
                {(startupDetails.isOwner || currentUser.isAdmin) && (
                  <>
                    <div className="px-1 pb-3 ml-2">
                      <div className="d-flex">
                        <div
                          className={
                            startupDetails.status === startupStatus.Approved
                              ? 'text-success mb-3'
                              : startupDetails.status ===
                                  startupStatus.Pending ||
                                startupDetails.status ===
                                  startupStatus.ChangesRequired
                              ? 'text-warning mb-3'
                              : startupDetails.status === startupStatus.Draft ||
                                startupDetails.status === startupStatus.Declined
                              ? 'text-danger mb-3'
                              : 'mb-3'
                          }
                        >
                          <span>
                            <i className="fas fa-circle"></i>
                          </span>
                          <span className="small mx-2">
                            {spacePascalWord(startupDetails.status, true)}
                          </span>
                        </div>

                        {!startupDetails.subscription && (
                          <div className="mx-3">
                            <i className="fas fa-square text-muted mr-2"></i>
                            <span className="text-muted">No plan</span>
                          </div>
                        )}
                        {startupDetails.subscription && (
                          <div className="mx-3">
                            <i className="fas fa-square text-success mr-2"></i>
                            <Link
                              to={`/user/subscriptions/${startupDetails.subscription.subscriptionId}`}
                            >
                              {startupDetails.subscription.planType &&
                                spacePascalWord(
                                  startupDetails.subscription.planType,
                                  true,
                                ) + ' plan'}
                            </Link>
                          </div>
                        )}

                        {!startupDetails.subscription && (
                          <div>
                            <div>
                              <Link
                                to={`/register-startup?id=${startupDetails.id}`}
                              >
                                Select plan
                              </Link>
                            </div>
                          </div>
                        )}

                        {startupDetails.subscription && (
                          <div>
                            <Link
                              to={`/user/startup/${startupDetails.id}/upgrade`}
                            >
                              Change/renew plan
                            </Link>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="px-1 pb-3 ml-2">
                      <Link
                        className="btn btn-dark px-5 py-2 mr-2"
                        to={`/register-startup?id=${startupDetails.id}`}
                      >
                        {startupDetails.status === startupStatus.Draft
                          ? 'Complete profile'
                          : 'Edit'}
                      </Link>
                      <button
                        type="button"
                        className="btn btn-dark px-5 py-2"
                        onClick={deleteStartup}
                      >
                        {isDeleting ? (
                          <CircularProgress color="inherit" size={'1rem'} />
                        ) : (
                          'Delete'
                        )}
                      </button>
                    </div>
                  </>
                )}
                <div
                  className="h-68 d-flex flex-column shadow-lg bg-modal rounded-2 p-4 mb-3 min-h-rem-lg-34 min-w-rem-lg-24"
                  id="info"
                >
                  <p>Looking to raise</p>
                  <div className="h1 font-weight-bold">
                    <NumberFormat
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={'N'}
                      value={startupDetails.investmentNeeded}
                    />
                  </div>
                  <span className="my-3">
                    <i
                      className="fas fa-map-marker-alt mr-3"
                      style={{ fontSize: '1.3rem' }}
                    ></i>
                    {startupDetails.locationState},{' '}
                    {startupDetails.locationCountry}
                  </span>
                  <span className="my-3">
                    <i className="fas fa-tags fa-flip-horizontal mr-3"></i>
                    {startupDetails.industry}
                  </span>
                  <span className="my-3">
                    <i
                      className="fas fa-globe mr-3"
                      style={{ fontSize: '1.3rem' }}
                    ></i>
                    {startupDetails.website}
                  </span>
                </div>

                <div className="mb-4  d-md-block d-lg-block">
                  <ContactStartupBtn
                    sendTo="Startup"
                    userId={startupDetails.id}
                    canSendMessage={startupDetails.canContact}
                    disable={
                      startupDetails.isOwner || startupDetails.hasContacted
                    }
                  ></ContactStartupBtn>
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid p-0">
            <div className="bg-white">
              <ul
                className="nav custom-nav-pills mb-3 bg-white container  "
                id="pills-tab"
                role="tablist"
              >
                <li className="nav-item bg-white mr-5">
                  <a
                    className="nav-link py-3 active"
                    id="pills-home-tab"
                    data-toggle="pill"
                    href="#pills-home"
                    role="tab"
                    aria-controls="pills-home"
                    aria-selected="true"
                  >
                    Overview
                  </a>
                </li>
                <li className="nav-item bg-white mr-5">
                  <a
                    className="nav-link py-3 "
                    id="pills-profile-tab"
                    data-toggle="pill"
                    href="#pills-profile"
                    role="tab"
                    aria-controls="pills-profile"
                    aria-selected="false"
                  >
                    Team
                  </a>
                </li>
                <li className="nav-item bg-white mr-5">
                  <a
                    className="nav-link py-3 "
                    id="pills-contact-tab"
                    data-toggle="pill"
                    href="#pills-contact"
                    role="tab"
                    aria-controls="pills-contact"
                    aria-selected="false"
                  >
                    Financials
                  </a>
                </li>
                <li className="nav-item bg-white">
                  <a
                    className="nav-link py-3 "
                    id="pills-document-tab"
                    data-toggle="pill"
                    href="#pills-document"
                    role="tab"
                    aria-controls="pills-document"
                    aria-selected="false"
                  >
                    Documents
                  </a>
                </li>
              </ul>
            </div>
            <div className="tab-content" id="pills-tabContent">
              <div
                className="container tab-pane fade show active"
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
              >
                <div className="d-flex flex-column">
                  <p className="py-5">{startupDetails.detailDescription}</p>
                  <div className="row d-flex justify-content-center">
                    <div className="d-flex col-md-9 flex-wrap justify-content-center border-thick bg-white p-5 mb-5">
                      <div className="w-md-40 w-100  flex-wrap font-weight-bold h7 my-3">
                        Has the product launched?:<span className="mx-3"></span>
                      </div>
                      <div className="w-md-60 w-100 font-weight-normal my-3">
                        {startupDetails.hasLaunched}
                      </div>
                      <div className="w-md-40 w-100 flex-wrap font-weight-bold h7 my-3">
                        Country of incorporation:<span className="mx-3"></span>
                      </div>
                      <div className="w-md-60 w-100 font-weight-normal my-3">
                        {startupDetails.locationCountry}{' '}
                      </div>
                      <div className="w-md-40 w-100 flex-wrap font-weight-bold h7 my-3">
                        Year of establishment:<span className="mx-3"></span>
                      </div>{' '}
                      <div className="w-md-60 w-100 font-weight-normal my-3">
                        {startupDetails.yearOfEst}{' '}
                      </div>
                      <div className="w-md-40 w-100 flex-wrap font-weight-bold h7 my-3">
                        Reason for investment:<span className="mx-3"></span>
                      </div>
                      <div className="w-md-60 w-100 font-weight-normal my-3">
                        {startupDetails.reason}
                      </div>
                      <div className="w-md-40 w-100 flex-wrap font-weight-bold h7 my-3">
                        Sales model:<span className="mx-3"></span>
                      </div>
                      <div className="w-md-60 w-100 font-weight-normal my-3">
                        {startupDetails.salesModel}{' '}
                      </div>
                      <div className="w-md-40 w-100 flex-wrap font-weight-bold h7 my-3">
                        Number of active customers:
                        <span className="mx-3"></span>
                      </div>
                      <div className="w-md-60 w-100 font-weight-normal my-3">
                        {startupDetails.numActiveCustomers}{' '}
                      </div>
                      <div className="w-md-40 w-100 flex-wrap font-weight-bold h7 my-3">
                        <span className="mr-3">
                          Channels/tools fueling customer growth:
                        </span>
                      </div>
                      <div className="w-md-60 w-100 font-weight-normal my-3">
                        {startupDetails.customerGrowthChannel}
                      </div>
                      <div className="w-md-40 w-100 flex-wrap font-weight-bold h7 my-3">
                        Type of investment required:
                        <span className="mx-3"></span>
                      </div>
                      <div className="w-md-60 w-100 font-weight-normal my-3">
                        {startupDetails.investmentType}{' '}
                      </div>
                      <div className="w-md-40 w-100 flex-wrap font-weight-bold h7 my-3">
                        Social media link:<span className="mx-3"></span>
                      </div>
                      <div className="w-md-60 w-100 font-weight-normal my-3">
                        {startupDetails.socialMedia}
                      </div>
                      <div className="w-md-40 w-100 flex-wrap font-weight-bold h7 my-3">
                        Any active investor:<span className="mx-3"></span>
                      </div>
                      <div className="w-md-60 w-100 font-weight-normal my-3">
                        {startupDetails.hasActiveInvestors
                          ? `Yes, ${startupDetails.equityHolding}%`
                          : 'No'}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="container tab-pane fade"
                id="pills-profile"
                role="tabpanel"
                aria-labelledby="pills-profile-tab"
              >
                <div className="d-flex flex-column">
                  <p className="py-5">
                    {startupDetails.canViewContacts ? (
                      startupDetails.allCofounders
                    ) : (
                      <span className="text-danger">
                        Confidential until you contact this Startup
                      </span>
                    )}
                  </p>
                  <div className="row d-flex justify-content-center">
                    <div className="d-flex col-md-9 flex-wrap justify-content-center border-thick bg-white p-5 mb-5">
                      <div className="w-md-40 w-100  flex-wrap font-weight-bold h7 my-3">
                        Primary Contact's First Name:
                        <span className="mx-3"></span>
                      </div>
                      <div className="w-md-60 w-100 font-weight-normal my-3">
                        {startupDetails.canViewContacts ? (
                          startupDetails.firstName
                        ) : (
                          <span className="text-danger">
                            Confidential until you contact this Startup
                          </span>
                        )}
                      </div>
                      <div className="w-md-40 w-100 flex-wrap font-weight-bold h7 my-3">
                        Primary Contact's Last Name:
                        <span className="mx-3"></span>
                      </div>
                      <div className="w-md-60 w-100 font-weight-normal my-3">
                        {startupDetails.canViewContacts ? (
                          startupDetails.lastName
                        ) : (
                          <span className="text-danger">
                            Confidential until you contact this Startup
                          </span>
                        )}
                      </div>
                      <div className="w-md-40 w-100 flex-wrap font-weight-bold h7 my-3">
                        Primary Contact's Email Address:
                        <span className="mx-3"></span>
                      </div>{' '}
                      <div className="w-md-60 w-100 font-weight-normal my-3">
                        {startupDetails.canViewContacts ? (
                          startupDetails.email
                        ) : (
                          <span className="text-danger">
                            Confidential until you contact this Startup
                          </span>
                        )}
                      </div>
                      <div className="w-md-40 w-100 flex-wrap font-weight-bold h7 my-3">
                        Primary Contact's Phone Number:
                        <span className="mx-3"></span>
                      </div>
                      <div className="w-md-60 w-100 font-weight-normal my-3">
                        {startupDetails.canViewContacts ? (
                          startupDetails.phone
                        ) : (
                          <span className="text-danger">
                            Confidential until you contact this Startup
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="container tab-pane fade"
                id="pills-contact"
                role="tabpanel"
                aria-labelledby="pills-contact-tab"
              >
                <div className="d-flex flex-column py-5">
                  <div className="row d-flex justify-content-center">
                    <div className="d-flex col-md-9 flex-wrap justify-content-center border-thick bg-white p-5 mb-5">
                      <div className="w-md-40 w-100  flex-wrap font-weight-bold h7 my-3">
                        Last Month's Revenue:<span className="mx-3"></span>
                      </div>
                      <div className="w-md-60 w-100 font-weight-normal my-3">
                        <NumberFormat
                          displayType={'text'}
                          thousandSeparator={true}
                          prefix={'N'}
                          value={startupDetails.lastMonthRevenue}
                        />
                      </div>
                      <div className="w-md-40 w-100 flex-wrap font-weight-bold h7 my-3">
                        Two Months ago:<span className="mx-3"></span>
                      </div>
                      <div className="w-md-60 w-100 font-weight-normal my-3">
                        <NumberFormat
                          displayType={'text'}
                          thousandSeparator={true}
                          prefix={'N'}
                          value={startupDetails.lastTwoMonthsRevenue}
                        />
                      </div>
                      <div className="w-md-40 w-100 flex-wrap font-weight-bold h7 my-3">
                        Monthly cost:
                        <span className="mx-3"></span>
                      </div>{' '}
                      <div className="w-md-60 w-100 font-weight-normal my-3">
                        <NumberFormat
                          displayType={'text'}
                          thousandSeparator={true}
                          prefix={'N'}
                          value={startupDetails.currentCost}
                        />
                      </div>
                      <div className="w-md-40 w-100 flex-wrap font-weight-bold h7 my-3">
                        Projected Montly revenue:
                        <span className="mx-3"></span>
                      </div>
                      <div className="w-md-60 w-100 font-weight-normal my-3">
                        <NumberFormat
                          displayType={'text'}
                          thousandSeparator={true}
                          prefix={'N'}
                          value={startupDetails.projectedMonthlyRevenue}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="container tab-pane fade"
                id="pills-document"
                role="tabpanel"
                aria-labelledby="pills-document-tab"
              >
                <div className="d-flex flex-column py-5">
                  <div className="row d-flex justify-content-center">
                    <div className="d-flex col-md-6 flex-wrap border-thick bg-white p-5 mb-5">
                      {startupDetails.canViewContacts ? (
                        startupDetails.documents &&
                        startupDetails.documents.length ? (
                          startupDetails.documents.map((document: any) => (
                            <div
                              key={randomstring.generate()}
                              className="ml-md-5 font-weight-bold h7 "
                            >
                              <a
                                href={document.fileUrl}
                                className="h8a font-weight-normal"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <i className="fas fa-file-pdf fa-2x pr-2 text-danger"></i>
                                <span className="px-md-4 h6 font-weight-normal">
                                  {document.name}
                                </span>
                              </a>
                            </div>
                          ))
                        ) : (
                          <p className="text-danger">
                            No document was uploaded
                          </p>
                        )
                      ) : (
                        <p className="text-danger">
                          Confidential until you contact this Startup
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </FadeIn>
    )
  );
};

export default StartupListingItemPage;
