import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Link, useHistory } from 'react-router-dom';
import latestimg from '../../assets/images/latestimg.png';
import CommunityHeader from './CommunityHeader';
import postService from 'common/services/postService';
import avater from '../../assets/images/user.svg';
import defaultavater from '../../assets/images/default-avatar.png';
import { Post } from 'common/interfaces/Post';

const PostCategory = () => {
  const [categories, setCategories] = useState<Array<any>>([]);
  const [latestPosts, setLatestPosts] = useState<Array<Post>>([]);
  const [postIsLoading, setpostIsLoading] = useState<boolean>(true);
  const [latestIsLoading, setlatestIsLoading] = useState<boolean>(true);

  const colorArray = ['#CA1200', '#0051CA', '#FD7900', '#6501A2', '#F4007D'];
  const history = useHistory();

  useEffect(() => {
    (async () => {
      const { status, data } = await postService.getCategories(true);
      if (status === 200 && data) {
        const mappedData = setCategoriesColor(data);
        setCategories(mappedData);
        setpostIsLoading(false);
      }
    })();

    (async () => {
      const { status, data } = await postService.getPosts();
      if (status === 200 && data) setLatestPosts(data.data);
      setlatestIsLoading(false);
    })();
  }, []);

  const setCategoriesColor = (data: Array<any>) => {
    return data.map(category => {
      if (category.name === 'Technology') category.color = '#00ca93';
      else if (category.name === 'Fashion') category.color = '#EA004D';
      else if (category.name === 'Event') category.color = '#DD5000';
      else if (category.name === 'Design') category.color = '#2002B3';
      else category.color = '#DD5000';
      return category;
    });
  };

  return (
    <div className="postlisting container">
      <CommunityHeader />
      <div className="row mb-8">
        <div className="col-lg-6">
          <div
            className="d-flex mr-2"
            style={{ borderBottom: '3px solid #C8C8C8' }}
          >
            <p>Category</p>
            <p className="ml-auto mr-3">Topics</p>
          </div>
          {postIsLoading === true && (
            <div className="text-center p-5">Loading...</div>
          )}
          {categories.map(category => {
            const { color } = category;
            return (
              <div
                key={category.id}
                className="d-flex mr-2 border-bottom cursor-pointer"
                onClick={() =>
                  history.push(
                    `/community?category=${category.name}&id=${category.id}`,
                  )
                }
              >
                <div className="width" style={{ backgroundColor: color }}></div>
                <div className="w-65 mr-6 mt-2 ml-2">
                  <h6 className="text-sm-1">{category.name}</h6>
                  <p className="text-sm-2">{category.description}</p>
                </div>
                <div className="mt-4 ml-auto mr-4">
                  <p className="text-sm-2">{category.livePostsCount}</p>
                </div>
              </div>
            );
          })}
        </div>
        <div className="col-lg-6">
          <div
            className="d-flex ml-1 mr-2 "
            style={{ borderBottom: '3px solid #C8C8C8' }}
          >
            <p>Latest</p>
          </div>
          {latestIsLoading === true && (
            <div className="text-center p-5">Loading...</div>
          )}
          {latestPosts?.length > 0 &&
            latestPosts.map(post => (
              <div key={post?.id} className="d-flex ml-1 mr-2 border-bottom">
                <div className="my-auto d-none d-lg-block d-xl-block">
                  {post.userPhotoUrl ? (
                    <img
                      src={post?.userPhotoUrl}
                      alt=""
                      className="rounded-circle"
                      style={{ height: '50px', width: '50px' }}
                    />
                  ) : (
                    <div
                      className="avaterImageDiv"
                      style={{
                        height: '50px',
                        width: '50px',
                        backgroundColor:
                          colorArray[
                            Math.floor(Math.random() * colorArray.length)
                          ],
                      }}
                    >
                      <h1 className="text-center text-white align-middle">
                        {post.userFirstname.substr(0, 1).toUpperCase()}
                      </h1>
                    </div>
                  )}
                </div>
                <div className="w-65 mt-2 ml-2">
                  <p
                    className="text-sm-2 cursor-pointer"
                    onClick={() => history.push(`/community/posts/${post?.id}`)}
                  >
                    {post.title}
                  </p>
                </div>
                <div className="mt-2 ml-auto mr-4">
                  <p className="text-sm-2">
                    {post?.commentsCount}{' '}
                    {post?.commentsCount > 1 ? 'comments' : 'comment'}
                  </p>
                  <p className="text-sm-2 mt-n3">
                    {moment(post?.createdAt).fromNow()}
                  </p>
                </div>
              </div>
            ))}

          {!latestIsLoading && latestPosts?.length === 0 && (
            <div>
              <p className="text-center">No latest posts</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PostCategory;
