import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import home from '../../assets/images/home.png';
import homeactive from '../../assets/images/home-black.png';
import logo from '../../assets/images/blacklogo.png';
import logoactive from '../../assets/images/logo-coloured.png';
import Investors from '../../assets/images/interstedinvestors.png';
import Investorsactive from '../../assets/images/interstedinvestors-color.png';
import invoice from '../../assets/images/invoice.png';
import invoiceactive from '../../assets/images/invoice-coloured.png';
import inbox from '../../assets/images/mail.png';
import inboxactive from '../../assets/images/mail-coloured.png';
import notification from '../../assets/images/bell.png';
import notificationactive from '../../assets/images/bell-coloured.png';
import settings from '../../assets/images/settings.png';
import community from '../../assets/images/communityicon.png';
import communityactive from '../../assets/images/communityactive.png';
import DashboardRoutes from './DashboardRoutes';
import blankImage from '../../assets/images/default.png';

const Sidebar = (props: any) => {
  const currentUser = useSelector((state: any) => state.user.currentUser);

  const { match } = props;
  return (
    <section className="d-flex flex-wrap">
      <div
        style={{
          backgroundColor: 'white',
          width: '220px',
          borderRadius: '10px',
          maxHeight: '790px',
        }}
        className="wrapper mt-5 pt-5 shadow mr-5 mb-5 d-none d-lg-block d-xl-block flex-column align-items-center"
      >
        <div
          className="border mb-5 ml-5 rounded-circle"
          style={{
            height: '80px',
            width: '80px',
          }}
        >
          {currentUser && currentUser.photoUrl !== null ? (
            <img
              src={currentUser.photoUrl || blankImage}
              // style={{ height: '80px', width: '80px' }}
              className="rounded-circle w-100"
              alt="user"
              data-holder-rendered="true"
            ></img>
          ) : (
            <div
              className="avaterImageDiv   bg-primary"
              style={{
                height: '80px',
                width: '80px',
              }}
            >
              <h1 className="text-center text-white align-middle pt-3">
                {currentUser.firstname.substr(0, 1).toUpperCase()}
              </h1>
            </div>
          )}
        </div>

        <ul
          style={{
            listStyle: 'none',
            color: '#000',
            fontFamily: 'metropolis-SemiBold',
            fontSize: '18px',
            letterSpacing: '0.03px',
            lineHeight: '15px',
          }}
        >
          <Link
            to={`${match.url}/dashboard`}
            style={{ textDecoration: 'none', color: '#000' }}
          >
            <li
              className={
                props.history.location.pathname === `${match.url}/dashboard`
                  ? 'text-primary mb-5 pt-3 pb-3 mr-2'
                  : 'mb-5'
              }
              style={
                props.history.location.pathname === `${match.url}/dashboard`
                  ? {
                      background: '#F4F4F4',
                      height: '45px',
                      borderRadius: '5px',
                    }
                  : {}
              }
            >
              <img
                src={
                  props.history.location.pathname === `${match.url}/dashboard`
                    ? home
                    : homeactive
                }
                alt=""
                className="mr-3 mt-n2 pl-2"
              />
              Dashboard
            </li>
          </Link>
          <Link
            to={`${match.url}/startup`}
            style={{ textDecoration: 'none', color: '#000' }}
          >
            <li
              className={
                props.history.location.pathname === `${match.url}/startup`
                  ? 'text-primary mb-5 pt-3 pb-3 mr-2'
                  : 'mb-5'
              }
              style={
                props.history.location.pathname === `${match.url}/startup`
                  ? {
                      background: '#F4F4F4',
                      height: '45px',
                      borderRadius: '5px',
                    }
                  : {}
              }
            >
              <img
                src={
                  props.history.location.pathname === `${match.url}/startup`
                    ? logoactive
                    : logo
                }
                alt=""
                className="mr-3 mt-n2 pl-2"
              />
              Your Startups
            </li>
          </Link>
          <Link
            to={`${match.url}/investors`}
            style={{ textDecoration: 'none', color: '#000' }}
          >
            <li
              className={
                props.history.location.pathname === `${match.url}/investors`
                  ? 'text-primary mb-5 pt-3 pb-3 mr-2'
                  : 'mb-5'
              }
              style={
                props.history.location.pathname === `${match.url}/investors`
                  ? {
                      background: '#F4F4F4',
                      height: '45px',
                      borderRadius: '5px',
                    }
                  : {}
              }
            >
              <div className="d-flex">
                <div>
                  <img
                    src={
                      props.history.location.pathname ===
                      `${match.url}/investors`
                        ? Investorsactive
                        : Investors
                    }
                    alt=""
                    className="mr-3 mt-n2 pl-2"
                  />
                </div>
                <div className="mt-n2">Interested Investors</div>
              </div>
            </li>
          </Link>
          <Link
            to={`${match.url}/subscriptions`}
            style={{ textDecoration: 'none', color: '#000' }}
          >
            <li
              className={
                props.history.location.pathname.includes('/subscriptions')
                  ? 'text-primary mb-5 pt-3 pb-3 mr-2'
                  : 'mb-5'
              }
              style={
                props.history.location.pathname.includes('/subscriptions')
                  ? {
                      background: '#F4F4F4',
                      height: '45px',
                      borderRadius: '5px',
                    }
                  : {}
              }
            >
              <img
                src={
                  props.history.location.pathname.includes('/subscriptions')
                    ? invoiceactive
                    : invoice
                }
                alt=""
                className="mr-3 mt-n2 pl-2"
              />
              Invoice
            </li>
          </Link>
          <Link
            to={`${match.url}/messages/inbox`}
            style={{ textDecoration: 'none', color: '#000' }}
          >
            <li
              className={
                props.history.location.pathname ===
                `${match.url}/messages/inbox`
                  ? 'text-primary mb-5 pt-3 pb-3 mr-2'
                  : 'mb-5'
              }
              style={
                props.history.location.pathname ===
                `${match.url}/messages/inbox`
                  ? {
                      background: '#F4F4F4',
                      height: '45px',
                      borderRadius: '5px',
                    }
                  : {}
              }
            >
              <img
                src={
                  props.history.location.pathname ===
                  `${match.url}/messages/inbox`
                    ? inboxactive
                    : inbox
                }
                alt=""
                className="mr-3 mt-n2 pl-2"
              />
              Inbox
            </li>
          </Link>
          <Link
            to={`${match.url}/notification`}
            style={{ textDecoration: 'none', color: '#000' }}
          >
            <li
              className={
                props.history.location.pathname === `${match.url}/notification`
                  ? 'text-primary mb-5 pt-3 pb-3 mr-2'
                  : 'mb-5'
              }
              style={
                props.history.location.pathname === `${match.url}/notification`
                  ? {
                      background: '#F4F4F4',
                      height: '45px',
                      borderRadius: '5px',
                    }
                  : {}
              }
            >
              <img
                src={
                  props.history.location.pathname ===
                  `${match.url}/notification`
                    ? notificationactive
                    : notification
                }
                alt=""
                className="mr-3 mt-n2 pl-2"
              />
              Notification
            </li>
          </Link>
          <Link
            to={`${match.url}/community`}
            style={{ textDecoration: 'none', color: '#000' }}
          >
            <li
              className={
                props.history.location.pathname === `${match.url}/community`
                  ? 'text-primary mb-5 pt-3 pb-3 mr-2'
                  : 'mb-5'
              }
              style={
                props.history.location.pathname === `${match.url}/community`
                  ? {
                      background: '#F4F4F4',
                      height: '45px',
                      borderRadius: '5px',
                    }
                  : {}
              }
            >
              <img
                src={
                  props.history.location.pathname === `${match.url}/community`
                    ? communityactive
                    : community
                }
                alt=""
                className="mr-3 mt-n2 pl-2"
              />
              Community
            </li>
          </Link>
          <Link
            to={`${match.url}/settings`}
            style={{ textDecoration: 'none', color: '#000' }}
          >
            <li
              className={
                props.history.location.pathname === `${match.url}/settings`
                  ? 'text-primary mb-5 pt-3 pb-3 mr-2'
                  : 'mb-5'
              }
              style={
                props.history.location.pathname === `${match.url}/settings`
                  ? {
                      background: '#F4F4F4',
                      height: '45px',
                      borderRadius: '5px',
                    }
                  : {}
              }
            >
              <img src={settings} alt="" className="mr-3 mt-n2 pl-2" />
              Settings
            </li>
          </Link>
          <Link to="/" style={{ textDecoration: 'none', color: '#000' }}>
            <li className="ml-5 pb-5 font-weight-bold">Log Out</li>
          </Link>
        </ul>
      </div>
      <div className="w-lg-80 w-xl-80 w-md-70 w-xs-100 w-sm-100 ">
        <DashboardRoutes match={match} />
      </div>
    </section>
  );
};

export default Sidebar;
