import React from 'react';
import { Startupprops } from '../../common/interfaces/startup';
import { useHistory } from 'react-router-dom';
import Truncate from 'react-truncate';
import NumberFormat from 'react-number-format';
import StartupIndustryImage from '../../common/utils/StartupIndustryImage';

const Startuplistingitems = (props: Startupprops) => {
  const { startup } = props;

  const history = useHistory();

  const imgUrl =
    startup.industry &&
    StartupIndustryImage[startup.industry.replace(/[- ]/g, '')]
      ? StartupIndustryImage[startup.industry.replace(/[- ]/g, '')].defaultImage
      : StartupIndustryImage.default.defaultImage;

  return (
    <div className="">
      <div
        className="card rounded-2 mb-5 mx-2 listing-item-shadow"
        style={{ height: '310px', maxWidth: '342px' }}
      >
        <img
          className="card-img-top cursor-pointer"
          src={imgUrl}
          alt="businness"
          onClick={() => history.push(`/startups/${startup.id}`)}
          style={{ maxHeight: '198px', maxWidth: '342px' }}
        />
        <div className="card-body mt-n2" style={{ maxWidth: '342px' }}>
          <h5 className="card-title font-weight-bold">{startup.startupName}</h5>
          <p className="card-text mt-n2">
            <Truncate lines={1} ellipsis={<span>...</span>}>
              {startup.shortDescription}
            </Truncate>
          </p>
          <div className="d-flex justify-content-around mt-n2 mb-n4">
            <div className="col-lg-4 mt-2 d-flex">
              <i style={{ fontSize: '10px' }} className="fas fa-tags ml-n1"></i>
              &nbsp;
              <p style={{ fontSize: '10px' }}>{startup.industry}</p>
            </div>
            <div className="col-lg-4 mt-2 mr-2 d-flex">
              <i
                style={{ fontSize: '10px' }}
                className="fas fa-map-marker-alt"
              ></i>
              &nbsp;
              <p style={{ fontSize: '9px' }}>
                <Truncate lines={2} ellipsis={<span>...</span>}>
                  {startup.locationState} &nbsp;{startup.locationCountry}
                </Truncate>
              </p>
            </div>
            <div className="col-lg-4 mt-2 d-flex">
              <i
                style={{ fontSize: '9px' }}
                className="fas fa-money-bill-wave"
              ></i>
              &nbsp;
              <p style={{ fontSize: '10px' }}>
                <NumberFormat
                  value={startup.investmentNeeded}
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={'₦'}
                />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Startuplistingitems;
