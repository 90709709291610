import React, { useState, useEffect } from 'react';
import userService from 'common/services/userService';
import { CircularProgress, Switch } from '@material-ui/core';
import NumberFormat from 'react-number-format';

const SubscriptionDetails = (props: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const [done, setDone] = useState(false);
  const [subscription, setSubscription] = useState<any>({});
  const [renewal, setRenewal] = useState(true);

  useEffect(() => {
    const id = props.match?.params?.id;

    if (id) {
      (async () => {
        try {
          setIsLoading(true);
          const { status, data } = await userService.getUserSubscription(id);
          if (status && data) {
            setSubscription(data);
            setRenewal(data?.renewal);
            setIsLoading(false);
            setDone(true);
          } else {
            setIsLoading(false);
          }
        } catch (error) {
          setIsLoading(false);
        }
      })();
    }
  }, []);

  const onSwitch = async (e: any) => {
    try {
      const { checked } = e.target;
      setIsLoading(true);
      const { status } = await userService.changeSubscriptionRenewal(
        subscription.subscriptionId,
        checked,
      );

      if (status === 200) {
        setRenewal(checked);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <div className="mt-md-5">
      {isLoading && !done ? (
        <div
          style={{ minHeight: '60vh' }}
          className="d-flex justify-content-center align-items-center"
        >
          <CircularProgress color="primary" size={'3rem'} />
        </div>
      ) : (
        <div className="ml-lg-5">
          <h1>Invoices and Subscriptions</h1>
          <div className="w-50 my-3">
            <hr></hr>
          </div>
          {subscription && (
            <div>
              <div className="mt-lg-5 card shadow">
                <div className="p-5">
                  <h3 className="mb-4 text-primary">
                    {subscription.listingTitle}
                  </h3>
                  <div className="row mb-3">
                    <div className="col-md-3 col-6">
                      <strong>Current Plan</strong>
                    </div>
                    <div className="col-md-6 col-6">
                      {subscription.planType &&
                        subscription.planType.split(/(?=[A-Z])/)[0]}
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-3 col-6">
                      <strong>Plan Price</strong>
                    </div>
                    <div className="col-md-6 col-6">
                      {subscription.planPrice === 0 ? (
                        'Free'
                      ) : (
                        <NumberFormat
                          displayType={'text'}
                          thousandSeparator={true}
                          prefix={'N'}
                          value={subscription.planPrice}
                        />
                      )}
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-3 col-6">
                      <strong>Start Date</strong>
                    </div>
                    <div className="col-md-6 col-6">
                      {new Date(subscription.planBeginDate).toDateString()}
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-3 col-6">
                      <strong>End Date</strong>
                    </div>
                    <div className="col-md-6 col-6">
                      {new Date(subscription.planEndDate).toDateString()}
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-3 col-6">
                      <strong>Status</strong>
                    </div>
                    <div className="col-md-6 col-6">{subscription.status}</div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-3 col-6">
                      <strong>Listing Type</strong>
                    </div>
                    <div className="col-md-6 col-6">
                      {subscription.listingType}
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-3 col-6">
                      <strong>Units Assigned</strong>
                    </div>
                    <div className="col-md-6 col-6">
                      {subscription.cycleUnits +
                        subscription.cycleConsumedUnits}
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-3 col-6">
                      <strong>Units Consumed</strong>
                    </div>
                    <div className="col-md-6 col-6">
                      {subscription.cycleConsumedUnits}
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-3 col-6">
                      <strong>Units Remaining</strong>
                    </div>
                    <div className="col-md-6 col-6">
                      {subscription.cycleUnits}
                    </div>
                  </div>
                  {!subscription.isFree && (
                    <div className="row mb-3">
                      <div className="col-md-3 col-6">
                        <strong>Auto Renew</strong>
                      </div>
                      <div className="col-md-6 col-6">
                        <Switch
                          color="primary"
                          name="renewal"
                          value={renewal}
                          checked={renewal}
                          onChange={e => onSwitch(e)}
                          disabled={isLoading}
                        />
                        {subscription.renewal ? (
                          isLoading ? (
                            <CircularProgress color="primary" size={'1rem'} />
                          ) : (
                            'ON'
                          )
                        ) : isLoading ? (
                          <CircularProgress color="primary" size={'1rem'} />
                        ) : (
                          'OFF'
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SubscriptionDetails;
