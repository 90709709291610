import React, { useState, useEffect } from 'react';
import { Link, Route, Switch } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import MessageContainer from './MessageContainer';
import { isMobile } from 'react-device-detect';

const Message = (props: any) => {
  const userInbox = useSelector((state: any) => state.user.userInbox);
  const userSent = useSelector((state: any) => state.user.userSent);
  const [toggle, setToggle] = useState('inbox');
  const dispatch = useDispatch();
  const [showBody, setShowBody] = useState('');
  const [showList, setShowList] = useState('');

  const reloadMobile = () => {
    if (isMobile) {
      setShowList('d-block');
      setShowBody('d-none');
    }
  };

  const hideShowBody = () => {
    if (isMobile) {
      setShowList('d-none');
      setShowBody('d-block');
    } else {
      setShowList('');
      setShowBody('');
    }
  };

  useEffect(() => {
    if (props.location.pathname.replace(/(.*\/)*/, '') === 'inbox')
      setToggle('inbox');
    else setToggle('sent');
    // dispatch(fetchUserInbox(pageNumber, pageSize));
    //dispatch(fetchUserSent(pageNumber, pageSize));
  }, [dispatch, props]);

  return (
    <div className="mt-5 w-100 message">
      <div className="w-75">
        <p className="font-weight-bold h1 border-bottom border-dark w-25 pb-3 mb-4">
          Messages
        </p>
      </div>
      <div className="w-100 pb-3">
        <div
          className="rounded-20-px d-flex"
          style={{
            backgroundColor: '#E1E1E1',
            width: '285px',
          }}
        >
          <Link
            to="/user/messages/inbox"
            onClick={() => {
              setToggle('inbox');
              reloadMobile();
            }}
            type="."
            className={
              toggle === 'inbox'
                ? 'pt-2 pb-2 pl-5 pr-5 m-1 border-0 activemessage'
                : 'pt-2 pb-2 pl-5 pr-5 m-1 border-0 inactivemessage rounded-20-px'
            }
          >
            Inbox
          </Link>
          <Link
            onClick={() => {
              setToggle('sent');
              reloadMobile();
            }}
            to="/user/messages/sent"
            className={
              toggle === 'sent'
                ? 'pt-2 pb-2 pl-5 pr-5 m-1 border-0 activemessage'
                : 'pt-2 pb-2 pl-5 pr-5 m-1 border-0 inactivemessage rounded-20-px'
            }
          >
            Sent
          </Link>
        </div>

        <Switch>
          <Route
            key={1}
            path={'/user/messages/inbox'}
            render={() => (
              <MessageContainer
                userMessages={userInbox}
                showBody={showBody}
                showList={showList}
                hideShowBody={hideShowBody}
                {...props}
              />
            )}
          ></Route>
          <Route
            key={2}
            path={'/user/messages/sent'}
            render={() => (
              <MessageContainer
                userMessages={userSent}
                showBody={showBody}
                showList={showList}
                hideShowBody={hideShowBody}
                {...props}
              />
            )}
          ></Route>
        </Switch>
      </div>
    </div>
  );
};

export default Message;
