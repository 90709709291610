import React, { useState, useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Post } from 'common/interfaces/Post';
import { useSelector } from 'react-redux';
import postService from 'common/services/postService';
import CommunityMobile from './communityMobile';

const CommunityPage = () => {
  const history = useHistory();
  const [allposts, setAllPosts] = useState<Array<Post>>([]);
  const [postIsLoading, setpostIsLoading] = useState<boolean>(true);
  const currentUser = useSelector((state: any) => state.user.currentUser);
  const realDate = (date: any) => {
    const newDate = new Date(date);
    const srtDate: any = Date;
    srtDate.shortMonths = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    const monthAbr = srtDate.shortMonths[newDate.getMonth()];
    const day = newDate.getDate();
    const year = newDate.getFullYear();
    const result = `${day} ${monthAbr} ${year} `;
    return result;
  };

  useEffect(() => {
    (async () => {
      const { status, data } = await postService.getUserPosts(
        currentUser.userId,
      );
      if (status === 200 && data) {
        const mappedData = setPostsColor(data.data);
        setAllPosts(mappedData);
        setpostIsLoading(false);
      }
    })();
  }, [history.location.search]);

  const setPostsColor = (data: Array<any>) => {
    return data.map(post => {
      if (post.postCategoryName === 'Technology') post.color = '#00ca93';
      else if (post.postCategoryName === 'Fashion') post.color = '#EA004D';
      else if (post.postCategoryName === 'Event') post.color = '#DD5000';
      else if (post.postCategoryName === 'Design') post.color = '#2002B3';
      else post.color = '#DD5000';
      return post;
    });
  };
  const posts = allposts;

  return (
    <>
      <div className="postlisting container d-none d-lg-block d-xl-block d-md-block">
        <div className="mt-5 mx-auto">
          <div className="w-75">
            <p className="font-weight-bold h1  pb-3 mb-4">
              Your Posts in community
            </p>
          </div>
          {postIsLoading && <div className="p-5">Loading...</div>}
          {!postIsLoading && posts?.length === 0 && (
            <div className="text-center p-5">No posts</div>
          )}
          {posts?.length > 0 && (
            <div className="row w-100 mx-auto">
              <table className="table table-responsive w-100 mx-auto border-bottom-1">
                <thead className="border-top-0">
                  <tr className="text-faded-black border-top-0">
                    <td>Topic</td>
                    <td>Category</td>
                    <td>Replies</td>
                    <td>Views</td>
                    <td className="text-center">Status</td>
                  </tr>
                </thead>
                <tbody>
                  {posts.map(post => {
                    const { color } = post;
                    return (
                      <tr className="text-sm-1" key={post?.id}>
                        <td className="w-50">
                          <p>{post?.title}</p>
                          <p className="text-faded-black mb-n1">
                            {realDate(post?.createdAt)}
                          </p>
                        </td>
                        <td>
                          <span className="d-flex">
                            <div
                              className="mt-1 mr-1 technology"
                              style={{ backgroundColor: color }}
                            ></div>
                            <p>{post?.postCategoryName}</p>
                          </span>
                        </td>
                        <td>
                          <p>{post?.commentsCount}</p>
                          <Link to={`/community/posts/${post?.id}`}>
                            <p className="mt-4 ml-2 text-link-blue font-weight-bold cursor-pointer">
                              View
                            </p>
                          </Link>
                        </td>
                        <td>
                          <p>{post?.views}</p>
                          <Link to={`/community/create-post?id=${post?.id}`}>
                            <p className="mt-4 ml-2 text-link-blue font-weight-bold cursor-pointer">
                              Edit
                            </p>
                          </Link>
                        </td>
                        <td className="px-4 text-center">
                          <p>
                            {post.isApproved ? (
                              <span className="text-success">Approved</span>
                            ) : (
                              <span className="text-warning">
                                Pending Approval
                              </span>
                            )}
                          </p>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
      <CommunityMobile posts={posts} postIsLoading={postIsLoading} />
    </>
  );
};

export default CommunityPage;
