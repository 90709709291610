import React, { useState, useEffect } from 'react';
import moment from 'moment';
import queryString from 'query-string';
import { useHistory } from 'react-router-dom';
import CommunityHeader from './CommunityHeader';
import { Post } from 'common/interfaces/Post';
import postService from 'common/services/postService';
import PostListingPageMobile from './PostListingPageMobile';

const PostListingPage = () => {
  const history = useHistory();
  const [posts, setPosts] = useState<Array<Post>>([]);
  const [category, setCategory] = useState<string>('');
  const [postIsLoading, setpostIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const { id: paramId, category } = queryString.parse(
      history.location.search,
    );
    const categoryId = Number(paramId);
    if (categoryId) {
      (async () => {
        const res: any = await postService.getPosts(categoryId);
        if (res && res.data) {
          const { data } = res.data;
          if (category) setCategory(String(category));
          setPosts(data);
        }
      })();
    } else {
      (async () => {
        const { status, data } = await postService.getPosts();
        if (status === 200 && data) {
          const mappedData = setPostsColor(data.data);
          setPosts(mappedData);
          setpostIsLoading(false);
          setCategory('');
        }
      })();
    }
  }, [history.location.search]);

  const setPostsColor = (data: Array<any>) => {
    return data.map(post => {
      if (post.postCategoryName === 'Technology') post.color = '#00ca93';
      else if (post.postCategoryName === 'Fashion') post.color = '#EA004D';
      else if (post.postCategoryName === 'Event') post.color = '#DD5000';
      else if (post.postCategoryName === 'Design') post.color = '#2002B3';
      else post.color = '#DD5000';
      return post;
    });
  };

  return (
    <>
      <div className="postlisting container d-none d-lg-block d-xl-block d-md-block">
        {console.log(posts)}
        <CommunityHeader />
        <div className="mt-5 row mx-auto">
          {category && (
            <div className="mb-3">
              <h4>{category} Posts</h4>
            </div>
          )}
          {!category && postIsLoading && (
            <div className="text-center p-5">Loading...</div>
          )}
          {!postIsLoading && posts?.length === 0 && (
            <div className="text-center p-5">No posts</div>
          )}
          {posts?.length > 0 && (
            <div className="row w-100">
              <table className="table table-hover table-responsive w-100 border-bottom-1">
                <thead className="border-top-0">
                  <tr className="text-faded-black border-top-0">
                    <td>Topic</td>
                    <td>Category</td>
                    <td>Replies</td>
                    <td>Views</td>
                    <td>Activity</td>
                  </tr>
                </thead>
                <tbody>
                  {posts.map(post => {
                    const { color } = post;
                    return (
                      <tr
                        className="text-sm-1 cursor-pointer"
                        key={post?.id}
                        onClick={() => {
                          history.push(`/community/posts/${post?.id}`);
                        }}
                      >
                        <td className="w-50">{<p>{post?.title}</p>}</td>
                        <td>
                          <span className="d-flex">
                            <div
                              className="mt-1 mr-1 technology"
                              style={{ backgroundColor: color }}
                            ></div>
                            <div>{post?.postCategoryName}</div>
                          </span>
                        </td>
                        <td>{post?.commentsCount}</td>
                        <td>{post?.views}</td>
                        <td>{moment(post?.createdAt).fromNow()}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
      <PostListingPageMobile
        category={category}
        posts={posts}
        postIsLoading={postIsLoading}
      />
    </>
  );
};

export default PostListingPage;
