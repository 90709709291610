import React, { useState } from 'react';
import CountryRegionData from 'assets/datasets/CountryRegionData';

export interface CountryInputProps {
  onChange: (value: string, clearRegion?: boolean) => void;
  className: string;
  id: string;
  value: string;
}

export const CountryInput = (props: CountryInputProps) => {
  const [country, setCountry] = useState('');
  const countries = CountryRegionData.map(x => x.countryName);
  const [filteredCountries, setFilteredCountries] = useState(['']);

  const handleChange = (e: any) => {
    var key = e.keyCode || e.charCode;
    if (key === 8 || key === 46) {
      props.onChange('', true);

      setCountry('');
    } else {
      let { value: country } = e.target;

      if (country && country.length > 0) {
        country = country.trim().toLowerCase();

        const filteredCountries: Array<string> = countries.filter(item =>
          item.toLowerCase().startsWith(country),
        );
        setFilteredCountries(filteredCountries);
        setCountry(country);
        props.onChange(e.target.value);
      }
    }
  };

  return (
    <>
      <input
        className={props.className && props.className}
        list={props.id && props.id}
        type="text"
        value={props.value}
        onChange={handleChange}
        onKeyDown={handleChange}
        placeholder="Type in country..."
      />
      <datalist id={props.id && props.id}>
        {filteredCountries.map(function(item) {
          if (
            filteredCountries.length === 1 &&
            item.length === country.length
          ) {
            return null;
          } else {
            return <option value={item} key={item} />;
          }
        })}
      </datalist>
    </>
  );
};

export interface RegionInputProps {
  onChange: (value: string) => void;
  country: string;
  className: string;
  id: string;
  value: string;
  clearRegions: boolean;
}

export const RegionInput = (props: RegionInputProps) => {
  const [searchString, setSearchString] = useState('');

  const handleChange = (e: any) => {
    var key = e.keyCode || e.charCode;
    if (key === 8 || key === 46) {
      setSearchString('');
      props.onChange('');
    } else {
      props.onChange(e.target.value);
      setSearchString(e.target.value.trim().toLowerCase());
    }
  };

  const { country } = props;
  var regions: Array<string> = [];

  if (country) {
    var countryObj: any = CountryRegionData.find(
      ({ countryName }) => countryName.toLowerCase() === country.toLowerCase(),
    );
    if (countryObj) {
      regions = countryObj.regions;
    }
  }

  if (props.clearRegions) {
    regions = [];
    setSearchString('');
  }

  if (searchString.length > 0) {
    if (regions && regions.length > 0) {
      regions = regions.filter(function(item: any) {
        return item.name.toLowerCase().startsWith(searchString);
      });
    }
  }

  return (
    <>
      <input
        className={props.className && props.className}
        list={props.id && props.id}
        type="text"
        value={props.value}
        onChange={handleChange}
        onKeyDown={handleChange}
        placeholder="Type in region..."
      />
      <datalist id={props.id && props.id}>
        {regions &&
          regions.map(function(item: any) {
            if (
              regions.length === 1 &&
              item.name.length === searchString.length
            ) {
              return null;
            } else {
              return <option value={item.name} key={item.name} />;
            }
          })}
      </datalist>
    </>
  );
};
