import * as React from 'react';
import { Link } from 'react-router-dom';
import quotation from '../../assets/images/quotation-mark.png';
const HomeTestimonials = () => {
  return (
    <section style={{ backgroundColor: '#282828', color: 'white' }}>
      <div
        className="text-center py-5"
        style={{ fontFamily: 'Metropolis-ExtraBold', fontSize: 25 }}
      >
        <p>What people are saying</p>
      </div>
      <div className="container">
        <div className="row mb-5">
          <div className="col-sm col-md-12 col-lg col-xl">
            <div className="text-center bg-white mt-5 h-90">
              <img className="p-3" src={quotation} alt="Quotation Mark" />
              <div className="py-3">
                <p
                  className="px-5"
                  style={{
                    fontFamily: 'metropolis-light',
                    color: 'black',
                    fontSize: '15px',
                  }}
                >
                  This platform has really been a big boost in making Cal
                  Academy a reality.
                </p>
                <p
                  className="px-5"
                  style={{
                    fontFamily: 'metropolis-light',
                    color: 'black',
                    fontSize: '15px',
                  }}
                >
                  It’s network of potential investors, partners and highly
                  skilled people creates a fast track for companies to grow and
                  expand.
                </p>
              </div>

              <div className="py-3">
                <hr className="bg-primary w-50" />
              </div>

              <div className="py-3" style={{ color: 'black', lineHeight: 1 }}>
                <p style={{ fontFamily: 'Metropolis-ExtraBold' }}>
                  Joshua Hermans-Efenudu
                </p>
                <p style={{ fontFamily: 'metropolis-light' }}>CAL Academy</p>
              </div>
            </div>
          </div>
          <div className="col-sm col-md-12 col-lg col-xl">
            <div className="text-center bg-white mt-5 h-90">
              <img className="p-3" src={quotation} alt="Quotation Mark" />
              <div className="py-3">
                <p
                  className="px-5"
                  style={{
                    fontFamily: 'metropolis-light',
                    color: 'black',
                    fontSize: '15px',
                  }}
                >
                  Things have significantly improved since I listed my business
                  on the platform, the experience has been great!
                </p>
              </div>
              <div style={{ marginTop: 75 }}>
                <div className="py-1">
                  <hr className="bg-primary w-50" />
                </div>

                <div className="py-3" style={{ color: 'black', lineHeight: 1 }}>
                  <p style={{ fontFamily: 'Metropolis-ExtraBold' }}>
                    Emmanuel Atiati
                  </p>
                  <p style={{ fontFamily: 'metropolis-light' }}>
                    (The Nest Abuja)
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm col-md-12 col-lg">
            <div className="text-center bg-white mt-5  h-90">
              <img className="p-3" src={quotation} alt="Quotation Mark" />
              <div className="py-3 mt-2">
                <p
                  className="px-5"
                  style={{
                    fontFamily: 'metropolis-light',
                    color: 'black',
                    fontSize: '15px',
                  }}
                >
                  This platform simplified my search for investors and has given
                  me hope in finding funding
                </p>
                <p
                  className="px-5"
                  style={{
                    fontFamily: 'metropolis-light',
                    color: 'black',
                    fontSize: '15px',
                  }}
                >
                  The customer service is exceptional and navigation very easy
                </p>
              </div>

              <div className="py-2">
                <hr className="bg-primary w-50" />
              </div>

              <div className="py-3" style={{ color: 'black', lineHeight: 1 }}>
                <p style={{ fontFamily: 'Metropolis-ExtraBold' }}>
                  Danielle Ese
                </p>
                <p style={{ fontFamily: 'metropolis-light' }}>
                  {' '}
                  (Dyegenie Enterprises)
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="text-center py-5">
        <Link
          to="/signup"
          className="btn btn-primary text-center font-size-info rounded-5-px py-2 px-5 mb-5"
          style={{ fontFamily: 'Metropolis-Medium' }}
        >
          Get Started
        </Link>
      </div>
    </section>
  );
};

export default HomeTestimonials;
