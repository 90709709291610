import baseApiUrl from '../../config';
import http from './httpService';
import { PostDto } from 'common/interfaces/PostDto';
import { CommentDto } from 'common/interfaces/CommentDto';

const getCategories = (detail: boolean = false) => {
  return http.get(`${baseApiUrl}posts/categories?detail=${detail}`);
};

const getPosts = (categoryId: number = 0) => {
  return http.get(`${baseApiUrl}posts?PostCategoryId=${categoryId}`);
};

const getUserPosts = (userId: number = 0) => {
  return http.get(`${baseApiUrl}posts?UserId=${userId}&all=true`);
};

const createPost = (dto: PostDto) => {
  return http.post(`${baseApiUrl}posts`, dto);
};

const getPostDetail = (id: number, viewed: boolean = true) => {
  return http.get(`${baseApiUrl}posts/${id}?viewed=${viewed}`);
};

const deletePost = (postId: number) => {
  return http.delete(`${baseApiUrl}posts/${postId}`);
};

const togglePostLike = async (postId: number) => {
  try {
    const { status } = await http.post(`${baseApiUrl}posts/${postId}/like`);
    if (status === 200) return true;
    else return false;
  } catch (error) {
    return false;
  }
};

const toggleCommentLike = async (commentId: number) => {
  try {
    const { status } = await http.post(
      `${baseApiUrl}posts/comments/${commentId}/like`,
    );
    if (status === 200) return true;
    else return false;
  } catch (error) {
    return false;
  }
};

const addComment = (dto: CommentDto) => {
  return http.post(`${baseApiUrl}posts/comments`, dto);
};

const deleteComment = (commentId: number) => {
  return http.delete(`${baseApiUrl}posts/comments/${commentId}`);
};

export default {
  getCategories,
  getPosts,
  getUserPosts,
  createPost,
  getPostDetail,
  deletePost,
  togglePostLike,
  toggleCommentLike,
  addComment,
  deleteComment,
};
