import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, RouteComponentProps } from 'react-router-dom';
import { UserDto } from '../../common/interfaces/User';
import authService from '../../common/services/authService';
import { CircularProgress } from '@material-ui/core';
import FormAlertMessage from '../../common/components/FormAlertMessage';
import Swal from 'sweetalert2';

export interface LoginProps extends RouteComponentProps<any> {}

const ForgotPassword = (props: LoginProps) => {
  const { register, handleSubmit, errors } = useForm<UserDto>();

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const onSubmit = async (values: UserDto) => {
    try {
      setIsLoading(true);
      const res = await authService.requestPasswordReset(values.email);

      if (res) {
        Swal.fire({
          title: 'Email Sent Successfully',
          text: `A password reset email has been sent to ${values.email}. Didn't get an email after a few minutes? Please check you spam, promotions, social or junk folder.`,
          icon: 'success',
          confirmButtonColor: '#ff6600',
          confirmButtonText: 'Proceed to login',
        }).then(() => {
          props.history.replace('/signin');
        });
        setErrorMessage('');
        setIsLoading(false);
      } else {
        Swal.fire(
          'Action failed',
          `Unable to send password reset email. Please try again`,
          'error',
        );
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);

      const { response } = err;

      if (response && response.data && response.data.title) {
        let message = response.data.title;
        setErrorMessage(message);
      }
    }
  };

  return (
    <div className="container-fluid bg-light-grey min-vh-100">
      <div className="container">
        <div className="row justify-content-center p-md-5 p-2">
          <div className="col-md-5">
            <div className="text-center">
              <h4 className="text-black font-weight-bold">
                Request password reset
              </h4>
              <div className="text-black small">
                <div className="pb-2">Reset your Compex Africa password</div>
              </div>
            </div>
            <div className="mt-5 mb-4 p-md-5 p-4 bg-form shadow rounded-10-px small">
              <form onSubmit={handleSubmit(onSubmit)} method="POST" noValidate>
                <div className="form-row">
                  <div className="form-group col-12">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      name="email"
                      ref={register({
                        required: 'Required',
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                          message: 'invalid email address',
                        },
                      })}
                      className={
                        errors.email
                          ? 'form-control is-invalid'
                          : 'form-control'
                      }
                    />
                    <div className="invalid-feedback">
                      {errors.email && 'A valid email address is required'}
                    </div>
                  </div>

                  {errorMessage && (
                    <div className="form-group col-12">
                      <FormAlertMessage message={errorMessage} />
                    </div>
                  )}

                  <div className="form-group col-12">
                    <div className="row justify-content-center py-3">
                      <div className="col-md-12">
                        <button
                          type="submit"
                          className="btn btn-primary btn-block rounded-5-px d-flex justify-content-center align-items-center"
                        >
                          <div className="bg-compex-icon min-w-rem-unit min-h-rem-unit"></div>

                          {isLoading ? (
                            <span className="px-3 m-w-90 d-block">
                              <CircularProgress color="inherit" size={'1rem'} />
                            </span>
                          ) : (
                            <span
                              className="text-white pl-3 min-w-90"
                              style={{ fontFamily: 'Roboto', fontWeight: 500 }}
                            >
                              Submit request
                            </span>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="d-flex justify-content-between text-off-black font-size-below">
              <span>
                Remember <strong>Compex Africa</strong> password?{' '}
                <Link to="/signin" className="font-weight-bolder">
                  Sign in
                </Link>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
