import React from 'react';
import queryString from 'query-string';
import authService from '../../common/services/authService';
import Swal from 'sweetalert2';

const ConfirmUserEmail = (props: any) => {
  const values = queryString.parse(props.location.search);
  const { email, token } = values;

  const res = authService.confirmUserEmail(email, token);

  if (res) {
    props.history.replace('/user/confirm-email-success');
  } else {
    Swal.fire(
      'Verification error',
      'Unable to verify email. Please try again or contact admin',
      'error',
    ).then(() => {
      props.history.replace('/');
    });
  }

  return <div></div>;
};

export default ConfirmUserEmail;
