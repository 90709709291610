import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import InvestorCard from '../../common/components/InvestorCard';
import next from '../../assets/images/next.png';
import back from '../../assets/images/back.png';
import { Investor } from '../../common/interfaces/investor';
import investorService from '../../common/services/investorService';

const InvestorsCarousel = () => {
  const [investors, setInvestors] = useState<Investor[]>([]);

  useEffect(() => {
    (async () => {
      const { status, data } = await investorService.getInvestors();
      if (status === 200 && data) setInvestors(data.data);
    })();
  }, []);

  let showLength: number = investors?.length >= 3 ? 3 : investors?.length;

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: showLength,
    slidesToScroll: showLength,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  function SampleNextArrow(props: any) {
    const { className, onClick } = props;
    return (
      <div className={className} style={{ display: 'block' }} onClick={onClick}>
        <img src={next} alt="next" />
      </div>
    );
  }

  function SamplePrevArrow(props: any) {
    const { className, onClick } = props;
    return (
      <div className={className} style={{ display: 'block' }} onClick={onClick}>
        <img src={back} alt="back" />
      </div>
    );
  }

  return investors?.length > 0 ? (
    <div className="container p-5" style={{ height: 500 }}>
      <Slider {...settings}>
        {investors.map(investor => (
          <InvestorCard
            key={investor.id}
            id={investor.id}
            whoYouAre={investor.whoYouAre}
            designation={investor.designation}
            currentLocation={investor.locations}
            interests="buying"
            background="background"
            location={investor.location}
            industries={investor.industries}
            investmentRangeFrom={investor.investmentRangeFrom}
            investmentRangeTo={investor.investmentRangeTo}
            photos={investor.photos}
          />
        ))}
      </Slider>
    </div>
  ) : null;
};
export default InvestorsCarousel;
