import http from './httpService';
import baseApiUrl from '../../config';

const markNotificationRead = (id: number) => {
  return http.post(`${baseApiUrl}notifications/${id}/read`);
};

const markAllNotificationsRead = () => {
  return http.post(`${baseApiUrl}notifications/readall`);
};

export default {
  markNotificationRead,
  markAllNotificationsRead,
};
