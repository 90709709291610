import React from 'react';
import Arrowwhite from '../../assets/images/Arrowwhite.png';
const ViewMoreBtn = () => {
  return (
    <div className="container d-flex flex-wrap align-items-center justify-content-center">
      <a
        href="https://compexafrica.com/investors"
        className="btn btn-primary font-weight-bold font-size-info rounded-10-px py-3 px-5 mt-3"
        style={{ fontFamily: 'metropolis-light', marginBottom: 20 }}
      >
        View More
        <img
          src={Arrowwhite}
          alt="Arrow"
          style={{
            height: 32,
            width: 32,
            marginLeft: 25,
            transform: 'rotate(-45deg)',
          }}
        />
      </a>
    </div>
  );
};

export default ViewMoreBtn;
