import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import Joi from 'joi-browser';
import { NavLink } from 'react-router-dom';
import Swal from 'sweetalert2';
import tempAvatar from '../../assets/images/default-avatar.png';
import auth from '../../common/services/authService';

type Props = {
  currentUser: any;
};
type State = {
  formdata: { [index: string]: any };
  errors: { [index: string]: any };
  edit: boolean;
  loading: boolean;
};

class UserSettings extends Component<Props, State> {
  state = {
    formdata: {
      firstname: '',
      lastName: '',
      phoneNumber: '',
      photoUrl: '',
    },
    errors: { firstname: '', lastName: '', phoneNumber: '' },
    edit: false,
    loading: false,
  };

  componentDidMount() {
    const { firstname, lastname, phone, photoUrl } = this.props.currentUser;
    this.setState({
      formdata: {
        firstname: firstname,
        lastName: lastname,
        phoneNumber: phone,
        photoUrl: photoUrl,
      },
    });
  }

  componentDidUpdate(prevProps: any) {
    if (this.props.currentUser !== prevProps.currentUser) {
      const { firstname, lastname, phone, photoUrl } = this.props.currentUser;
      this.setState({
        formdata: {
          firstname: firstname,
          lastName: lastname,
          phoneNumber: phone,
          photoUrl: photoUrl,
        },
      });
    }
  }

  schema = {
    firstname: Joi.string()
      .min(3)
      .required()
      .label('First Name'),
    lastName: Joi.string()
      .min(3)
      .required()
      .label('Last Name'),
    phoneNumber: Joi.string()
      .required()
      .label('Phone'),
    photoUrl: Joi.optional(),
  };

  edit = (e: any) => {
    e.preventDefault();
    this.setState({ edit: true });
  };

  cancel = (e: any) => {
    e.preventDefault();
    this.setState({ edit: false });
  };

  validate() {
    const { error } = Joi.validate(this.state.formdata, this.schema, {
      abortEarly: false,
    });
    if (!error) return null;
    const errors: { [index: string]: any } = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  }

  save = async (e: any) => {
    e.preventDefault();
    const errors = this.validate();
    this.setState({ errors: errors || {} });
    try {
      this.setState({ loading: true });

      const result = await auth.updateCurrentUser({
        ...this.state.formdata,
      });

      if (result) {
        this.setState({ loading: false, edit: false });
        window.location.reload(false);
      } else {
        this.setState({ loading: false });
        Swal.fire(
          'Error',
          'Error occured while updating your details. Please try again.',
          'error',
        );
      }
    } catch (err) {
      this.setState({ loading: false });
      Swal.fire(
        'Error',
        'Error occured while updating your details. Please try again.',
        'error',
      );
    }
  };

  onChange = (e: any) => {
    const { name, value } = e.target;
    const formdata: { [index: string]: any } = this.state.formdata;
    formdata[name] = value;
    const errors: { [index: string]: any } = this.state.errors;
    errors[name] = '';
    this.setState({ formdata, errors });
  };

  onPhotoUpload = (e: any) => {
    const file = e.target.files[0];
    if (file && file.type !== 'image/jpeg' && file.type !== 'image/png') {
      Swal.fire('Error', 'Only Jpeg and Png images are allowed', 'error');
    } else {
      if (file && file.size <= 2000000) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadstart = () => {};
        reader.onloadend = () => {
          const base64Url = reader.result;
          if (base64Url) {
            const { formdata } = this.state;
            formdata.photoUrl = base64Url.toString();
            this.setState({ formdata: formdata });
          }
        };
      } else {
        Swal.fire('Error', 'File is too large', 'error');
      }
    }
  };

  render() {
    return (
      <div>
        <div className="ml-lg-5">
          <h1>Your settings</h1>
          <div className="w-50 my-5 py-3">
            <hr></hr>
          </div>
          <form className="d-flex flex-column p-4 rounded bg-white shadow mt-5">
            <div className="mb-3">
              <label
                htmlFor="firstname"
                className="h7 font-weight-bold mr-3 w-lg-20"
              >
                First Name:
              </label>
              {this.state.edit ? (
                <>
                  <input
                    type="text"
                    placeholder="First Name"
                    value={this.state.formdata.firstname}
                    className={
                      this.state.errors['firstname']
                        ? 'form-control w-lg-50 d-inline is-invalid'
                        : 'form-control w-lg-50 d-inline'
                    }
                    name="firstname"
                    onChange={this.onChange}
                  />
                  <div className="invalid-feedback">
                    {this.state.errors['firstname']}
                  </div>
                </>
              ) : (
                <p className="h7 font-weight-bold mr-3 w-20 d-inline">
                  {this.state.formdata.firstname}
                </p>
              )}
            </div>
            <div className="mb-3">
              <label
                htmlFor="lastName"
                className="h7 font-weight-bold mr-3 w-lg-20"
              >
                Last Name:
              </label>
              {this.state.edit ? (
                <>
                  <input
                    type="text"
                    value={this.state.formdata.lastName}
                    placeholder="Last Name"
                    className={
                      this.state.errors['lastName']
                        ? 'form-control w-lg-50 d-inline is-invalid'
                        : 'form-control w-lg-50 d-inline'
                    }
                    name="lastName"
                    onChange={this.onChange}
                  />
                  <div className="invalid-feedback">
                    {this.state.errors['lastName']}
                  </div>
                </>
              ) : (
                <p className="h7 font-weight-bold mr-3 w-20 d-inline">
                  {this.state.formdata.lastName}
                </p>
              )}
            </div>
            <div className="mb-3">
              <label
                htmlFor="phoneNumber"
                className="h7 font-weight-bold mr-3 w-lg-20"
              >
                Phone Number:
              </label>
              {this.state.edit ? (
                <>
                  <input
                    type="text"
                    value={this.state.formdata.phoneNumber}
                    placeholder="Phone Number"
                    className={
                      this.state.errors['phoneNumber']
                        ? 'form-control w-lg-50 d-inline is-invalid'
                        : 'form-control w-lg-50 d-inline'
                    }
                    name="phoneNumber"
                    onChange={this.onChange}
                  />
                  <div className="invalid-feedback">
                    {this.state.errors['phoneNumber']}
                  </div>
                </>
              ) : (
                <p className="h7 font-weight-bold mr-3 w-20 d-inline">
                  {this.state.formdata.phoneNumber}
                </p>
              )}
            </div>
            <div className="mb-3">
              <label
                htmlFor="profilePhoto"
                className="h7 font-weight-bold mr-3 w-lg-20"
              >
                Profile Photo:
              </label>
              {this.state.edit ? (
                <div className="upload-btn-wrapper">
                  <button
                    type="button"
                    className="btn btn-outline-primary btn-lg"
                    name="profilePhoto"
                  >
                    Upload new picture
                  </button>
                  <input
                    type="file"
                    name="file"
                    onChange={this.onPhotoUpload}
                  />
                  <br />
                  <div className="w-50 d-inline-block">
                    {this.state.formdata.photoUrl ? (
                      <img
                        src={this.state.formdata.photoUrl}
                        alt="Default Avatar"
                        className="w-100 rounded-circle p-1 mb-4 mt-3"
                      ></img>
                    ) : (
                      <div
                        className="avaterImageDiv mb-4 mt-3 bg-primary"
                        style={{
                          height: '100px',
                          width: '100px',
                        }}
                      >
                        <h1 className="text-center text-white align-middle pt-4">
                          {this.state.formdata.firstname
                            .substr(0, 1)
                            .toUpperCase()}
                        </h1>
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <div className="w-20 d-inline-block">
                  {this.state.formdata.photoUrl ? (
                    <img
                      src={this.state.formdata.photoUrl}
                      alt="Default Avatar"
                      className="w-100 rounded-circle p-1 mb-4 mt-3"
                    ></img>
                  ) : (
                    <div
                      className="avaterImageDiv mb-4 mt-3 bg-primary"
                      style={{
                        height: '100px',
                        width: '100px',
                      }}
                    >
                      <h1 className="text-center text-white align-middle pt-4">
                        {this.state.formdata.firstname
                          .substr(0, 1)
                          .toUpperCase()}
                      </h1>
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="d-flex flex-wrap">
              {this.state.edit ? (
                <button
                  className="btn btn-outline-primary px-3 mr-3 h7 font-weight-bold"
                  onClick={this.save}
                >
                  {this.state.loading ? (
                    <CircularProgress color="inherit" size={'1rem'} />
                  ) : (
                    'Save'
                  )}
                </button>
              ) : (
                <button
                  className="btn btn-outline-primary px-3 mr-3 h7 font-weight-bold"
                  onClick={this.edit}
                >
                  Edit Details
                </button>
              )}
              {this.state.edit && (
                <button
                  className="btn btn-outline-primary px-3 mr-3 h7 font-weight-bold"
                  onClick={this.cancel}
                >
                  Cancel
                </button>
              )}
              {!this.state.edit && (
                <NavLink
                  to="/forgot-password"
                  className="btn btn-outline-primary px-3 mr-3 h7 font-weight-bold"
                >
                  Change Password
                </NavLink>
              )}
              <button
                className="btn btn-outline-primary px-3 h7 font-weight-bold"
                disabled
              >
                Delete Account
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default connect((state: any) => ({
  currentUser: state.user.currentUser,
}))(UserSettings);
