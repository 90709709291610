import React from 'react';
import StartupListingItemPage from 'pages/startup/StartupListingItemPage';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const UserStartup: React.FunctionComponent = () => {
  const userStartupId = useSelector((state: any) => state.user.userStartupId);

  return userStartupId ? (
    <StartupListingItemPage userStartupId={userStartupId} />
  ) : (
    <div className="mt-md-5 pt-3">
      <div>You have no startup</div>
      <div className="my-5">
        <Link to="/pricing">
          <button className="btn btn-primary">Register your startup</button>
        </Link>
      </div>
    </div>
  );
};

export default UserStartup;
