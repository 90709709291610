import * as React from 'react';
import { Link } from 'react-router-dom';
import VisibilitySensor from 'react-visibility-sensor';
import { Fade } from '@material-ui/core';
import investor from '../../assets/images/Investors.png';
import community from '../../assets/images/community.png';
import support from '../../assets/images/support.png';
import funding from '../../assets/images/funding.png';
import investment from '../../assets/images/investment.png';
import exclusive from '../../assets/images/exclusive.png';
const HomeFeatures = () => {
  return (
    <section className="container min-vh-90 py-5">
      <div className="row">
        <div className="col-sm col-md">
          <VisibilitySensor partialVisibility={true}>
            {({ isVisible }) => (
              <Fade in={isVisible} timeout={1000}>
                <div
                  className="text-center bg-white py-5 px-3 shadow mt-5 "
                  style={{ borderRadius: 15, height: 300 }}
                >
                  <div className=" mb-3 mx-auto">
                    <img src={investor} />
                  </div>
                  <p>
                    Access to investors
                    <br /> and high net worth individual
                    <br /> around the world
                  </p>
                </div>
              </Fade>
            )}
          </VisibilitySensor>
        </div>

        <div className="col-sm col-md">
          <VisibilitySensor partialVisibility={true}>
            {({ isVisible }) => (
              <Fade in={isVisible} timeout={1000}>
                <div
                  className="text-center bg-white py-5 px-3 shadow mt-5"
                  style={{ borderRadius: 15, height: 300 }}
                >
                  <div className=" mb-3 mx-auto">
                    <img src={community} />
                  </div>
                  <p>
                    An entreprenuerial
                    <br />
                    community that supports
                    <br /> you and your business
                  </p>
                </div>
              </Fade>
            )}
          </VisibilitySensor>
        </div>

        {/* <div className="w-100 d-block d-md-none d-lg-none d-xl-none"></div> */}
        <div className="col-sm col-md ">
          <VisibilitySensor partialVisibility={true}>
            {({ isVisible }) => (
              <Fade in={isVisible} timeout={1000}>
                <div
                  className="text-center w-lg-100  bg-white py-5 px-3  shadow mt-5"
                  style={{ borderRadius: 15, height: 300 }}
                >
                  <div className=" mb-3 mx-auto">
                    <img src={support} />
                  </div>
                  <p>
                    Hands on technical and
                    <br /> professional support
                    <br />
                    from experts
                  </p>
                </div>
              </Fade>
            )}
          </VisibilitySensor>
        </div>
      </div>

      <div className="row">
        <div className="col-sm">
          <VisibilitySensor partialVisibility={true}>
            {({ isVisible }) => (
              <Fade in={isVisible} timeout={1000}>
                <div
                  className="text-center bg-white py-5 px-3 shadow mt-5"
                  style={{ borderRadius: 15, height: 300 }}
                >
                  <div className=" mb-3 mx-auto">
                    <img src={funding} />
                  </div>
                  <p>
                    Get the right investment
                    <br />
                    to take your business
                    <br /> to the next level of growth
                  </p>
                </div>
              </Fade>
            )}
          </VisibilitySensor>
        </div>

        <div className="col-sm">
          {/* <div className="w-100 d-block d-md-none d-lg-none d-xl-none"></div> */}
          <VisibilitySensor partialVisibility={true}>
            {({ isVisible }) => (
              <Fade in={isVisible} timeout={1000}>
                <div
                  className="text-center bg-white py-5 px-3 mt-5 shadow"
                  style={{ borderRadius: 15, height: 300 }}
                >
                  <div className=" mb-3 mx-auto">
                    <img src={investment} />
                  </div>
                  <p>
                    Access to pre-screened
                    <br />
                    high growth businesses
                    <br />
                    and franchises
                  </p>
                </div>
              </Fade>
            )}
          </VisibilitySensor>
        </div>

        <div className="col-sm">
          <VisibilitySensor partialVisibility={true}>
            {({ isVisible }) => (
              <Fade in={isVisible} timeout={1000}>
                <div
                  className="text-center bg-white py-5 px-3 mt-5 shadow"
                  style={{ borderRadius: 15, height: 300 }}
                >
                  <div className=" mb-3 mx-auto">
                    <img src={exclusive} />
                  </div>
                  <p>
                    Exclusive
                    <br />
                    admittance to high-end
                    <br /> networking events
                  </p>
                </div>
              </Fade>
            )}
          </VisibilitySensor>
        </div>
      </div>
      <div className="text-center mt-3 py-5">
        <Link
          to="/signup"
          className="btn btn-thick-outline-primary font-size-info rounded-5-px py-2 px-5 mb-4 mt-3"
          style={{ fontFamily: 'Metropolis-Medium' }}
        >
          Sign up for free
        </Link>
      </div>
    </section>
  );
};

export default HomeFeatures;
