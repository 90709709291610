import React, { useState } from 'react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import authService from '../services/authService';
import { facebookAppId } from '../../config';
import { CircularProgress } from '@material-ui/core';
import { RouteComponentProps } from 'react-router-dom';

export interface FacebookLoginBtnProps extends RouteComponentProps<any> {
  displayText: string;
}

const FacebookLoginBtn = (props: FacebookLoginBtnProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const responseFacebook = async (response: any) => {
    console.log(response);

    if (!response.accessToken) return;

    try {
      setIsLoading(true);
      const res = await authService.facebookLogin(response);
      if (res) {
        setIsLoading(false);
        console.log(props);
        const state: any = props.location.state;
        const redirectPath = state ? state.from.pathname : '/user/dashboard';
        props.history.replace(redirectPath);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <FacebookLogin
      appId={facebookAppId}
      autoLoad={false}
      callback={responseFacebook}
      render={(renderProps: any) => (
        <div
          className="btn border  border-thick min-w-100"
          onClick={renderProps.onClick}
        >
          <div className="d-flex justify-content-center align-items-center">
            <div className="bg-facebook-icon min-w-rem-unit min-h-rem-unit"></div>
            {isLoading ? (
              <span className="px-3 m-w-90">
                <CircularProgress color="inherit" size={'0.9rem'} />
              </span>
            ) : (
              <span
                className="text-muted pl-3 min-w-90"
                style={{ fontFamily: 'Roboto', fontWeight: 500 }}
              >
                {props.displayText}
              </span>
            )}
          </div>
        </div>
      )}
    />
  );
};

export default FacebookLoginBtn;
